import {useEffect, useState, useRef} from 'react'
import '../styles/components/newMessage.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faHashtag, faPaperPlane, faAt, faXmark, faFileArrowUp, faUpload, faCircleCheck} from '@fortawesome/free-solid-svg-icons'
import {UserWritingMessages, seachForIdentif} from '../services/UserServices';
import {PostNewMessage} from '../services/PostServices';
import {useDispatch, useSelector} from "react-redux";
import { addPost } from '../feature/postsSlice';
import {pushNewHashtags, resetHashtags} from '../feature/navigationSlice'
import { initUserPfp } from '../feature/commentSlice';
import {setFilterTrends } from '../feature/trendSlice';
import cookies from 'js-cookie';
import Fade from 'react-reveal/Fade';


function NewMessage() {
    const [userData, setUserData] = useState([]);
    const [newMessageData, setNewMessageData] = useState("");
    const [sentPhoto, setSentPhoto] = useState("");
    //form.append('image', file, localStorage.getItem("userId") + "_poste_" + file.name);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState('');
    const inputFile = useRef(null);
    const userModification = useSelector((state) => state.profile.editQuery);

    const [identifyValue, setIdentifyValue] = useState("");
    const [identifyArr, setIdentifyArr] = useState([]);
    const [showIdentify, setShowIdentify] = useState(false);
    const [mentionsArr, setMentionsArr] = useState([]);
    const [showMentionsArr, setShowMentionsArr] = useState([]);

    const dispatch = useDispatch();

    const FetchUserData = async () => {
        const resp = await UserWritingMessages();
        setUserData(resp.getUser);
        dispatch(initUserPfp(resp.getUser.imageUrl));
        
    }

    const handlePicture = (pic)=> {
        if (pic.target.files[0].size < 4000000){
            setFileName(pic.target.files[0].name);
            setFile(pic.target.files[0]);
        }
    }

    const [delaySend, setDelaySend] = useState(false);
    const SendNewMessage = async () => {
        var APIFormMessage = null;
        if (file){
            APIFormMessage = new FormData();
            APIFormMessage.append('image', file, localStorage.getItem("userId") + "_poste_" + file.name);
            APIFormMessage.append('userId', cookies.get('userId').toString());
            APIFormMessage.append('description', newMessageData);
            APIFormMessage.append('hashtags', hashtagsData);
            APIFormMessage.append('mentions', mentionsArr);
        }else{
            APIFormMessage = {
                userId:  cookies.get('userId').toString(),
                description: newMessageData,
                hashtags: hashtagsData,
                mentions: mentionsArr
            }
        }
        if (delaySend){return 0;}
        if (newMessageData.length > 0 && newMessageData.length < 257){
            setDelaySend(true);
            await PostNewMessage(APIFormMessage).then(resp => {
                if (resp.status === 201){
                    clear();
                    document.getElementById('newMessage').value = ""; dispatch(setFilterTrends(""));
                    dispatch(addPost({
                        post: resp.data,
                        user: {
                            id: cookies.get("userId"),
                            imageUrl: userData.imageUrl,
                            nom: userData.nom,
                            prenom: userData.prenom,
                            pseudo : userData.pseudo,
                            verified: userData.verified
                        }
                    }));
                }
             });
             setDelaySend(false);
        }
    }

    useEffect(() => {  
        FetchUserData();
        document.addEventListener("click", handleOutsideClick);
        document.addEventListener("click", handleOutsideClickIdentify);
    }, [userModification]); 

    useEffect(() => {
        (async () => {
            if (identifyValue.length > 0){
                const o  = await seachForIdentif(identifyValue);
                setIdentifyArr(o.searchProfiles);
                setShowIdentify(true);
            }else{
                setShowIdentify(false);
            }
        })();
    }, [identifyValue]);

    var currentHashtag = useRef(null);
    const [hashtagString, setHashtagString] = useState("");
    const hashtagsData = useSelector((state) => state.navigation.hashtags);

    const handleOutsideClick = async (event) => {
        const hasClass =  event.target.classList.contains('bot_input');
        const isRemoveButton = event.target.classList.contains('fa-eraser');
        if (currentHashtag.current){
            if (isRemoveButton === false){
                if (hashtagsData.length < 12) {
                    if (currentHashtag.current.value.length > 1){
                        if (hasClass === false){
                                const tagResult = currentHashtag.current.value.replace(/[^A-Z0-9]/ig, "");
                                dispatch(pushNewHashtags(tagResult));
                                document.getElementById('hashtag_input').value = "";
                        }
                    }
                }
            }
        }
    }
    const handleOutsideClickIdentify = async (event) => {
        const hasClass =  event.target.classList.contains('card_');
        const isRemoveButton = event.target.classList.contains('card_child');
        if ((hasClass === false) && (isRemoveButton === false)){
            setShowIdentify(false);
        }
    }
    const clear = () => {
        setHashtagString("");
        dispatch(resetHashtags());
        setFile(); setFileName('');setNewMessageData('');
        setMentionsArr([]); setShowMentionsArr([]);
        currentHashtag.current.value = "";
        document.getElementById('newMessage').value = "";
    }
    const identify = (user) => {
        if (!mentionsArr.includes(user._id) && mentionsArr.length < 8){
            setShowMentionsArr([...showMentionsArr, user]);
            setMentionsArr([...mentionsArr, user._id]);
            setShowIdentify(false);setIdentifyValue('');
            document.getElementById('mentions').value = "";
        }
    }
    useEffect(() => {  
        setHashtagString(hashtagsData.toString().replaceAll(',' ,' '));
    }, [hashtagsData]); 

    return (
      
        <div className='new_message_div'>
            <div className="top_newmsg">
                
                    {userData?.imageUrl && <>
                        <Fade top>
                            <img src={userData.imageUrl} />
                            <p>{newMessageData.length} / 255</p>
                        </Fade>
                    </>}

                    <div className="inputs">
                        <input className='writeMs' id='newMessage' type="text" placeholder="What's happening ?" maxLength="255" onChange={e => setNewMessageData(e.target.value)}/>
                        <input ref={inputFile} type="file" name="messagePicture" accept="image/*" title='' id='uploadimg' className='msg_upload_pic' onChange={e => handlePicture(e)}/>
                        <button className='photoUpld' onClick={() => inputFile.current.click()}> <span>Photo</span>  <FontAwesomeIcon icon={faFileArrowUp}/> </button>
                        <button className='chat' onClick={SendNewMessage}>
                            {delaySend ? <>
                                <FontAwesomeIcon icon={faPaperPlane} className='sending' />
                            </> : <>
                                <FontAwesomeIcon icon={faPaperPlane} />
                            </>}
                            
                        </button>
                    </div>
                
            </div>
            <div className="bottom_newmsg">
                {userData?.imageUrl && <>
                    <Fade>

                        <div className='trend'>
                            <p><FontAwesomeIcon icon={faHashtag} /> </p>
                            <input id='hashtag_input' type="text" placeholder="Nitrochat Trends "  ref={currentHashtag} maxLength="10"/>
                        </div>

                        <div className='identify'>
                            <p><FontAwesomeIcon icon={faAt} /></p>
                            <input id='mentions' type="text" onChange={e => (setIdentifyValue(e.target.value))} placeholder="Identify someone " pattern="[A-Za-z]" maxLength="30"/>
                        </div>
                    </Fade>
                </>}


                {(showIdentify && identifyArr.length > 0) && <>
                    <div className="users_id_map">
                        {identifyArr.map((card, index) => (<>
                                <div className={mentionsArr.includes(card._id) ?  "card_ selected" : "card_" }onClick={() => (identify(card))}>
                                    <img className='card_child' src={card.imageUrl} alt="" />
                                    <div className="card_txts">
                                        <h5 className='card_child nms'> {card.prenom}  {card.nom} {card.verified && <><FontAwesomeIcon style={{"color" : "#1da1f2"}} icon={faCircleCheck} /> </>} </h5>
                                        <h5 className='card_child ps'> @{card.pseudo} </h5>
                                    </div>
                                </div>
                        </>))}
                    </div>
                </>}
            </div>

            {(hashtagsData.length > 0) &&<>
                <div className="tags_div">
                
                        <span onClick={() => (clear())} className="remove_btn" ><FontAwesomeIcon icon={faXmark} />  CLEAR <strong>{hashtagsData.length + "/6"}</strong> </span>
                        <p className='tags'>{hashtagString}</p>
                
                </div>
            </>}

            {(mentionsArr.length > 0) &&<>
                <div className="mentions_div">
                    {showMentionsArr.map((mention) => (<>
                        <Fade>
                            <div className='mention_card'>
                                <img src={mention.imageUrl} alt="" />
                                <div className="textsss">
                                    <h6>{mention.prenom} {mention.nom}</h6>
                                    <h6 className='ps_'> @{mention.pseudo}</h6>
                                </div>
                            </div>
                        </Fade>
                    </>))}
                </div>
            </>}

            {fileName.length > 1 && <> <h1>{fileName} <FontAwesomeIcon icon={faUpload} /></h1></>}
        </div>
    )
}

export default NewMessage
