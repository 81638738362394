import {useEffect, useState} from 'react'
import '../styles/components/trendsForYou.scss'
import {setNavigation,  setSpecificMessageId,setPreviousNavState} from "../feature/navigationSlice";
import {TrendsGQL} from '../services/PostServices';
import {useDispatch, useSelector} from "react-redux";
import { setGeneralTrends, setFilterTrends } from '../feature/trendSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faAnglesLeft} from '@fortawesome/free-solid-svg-icons'
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import {setProfileId} from '../feature/profileSlice';

function TrendMap(TrendType){

    const dispatch = useDispatch();
    const trendFilter = useSelector((state) => state.trends.filterTrend);

    const i = async (e) =>{
        dispatch(setFilterTrends(e));
        dispatch(setNavigation('#home'));
    }
    return (
    <>
    {TrendType.TrendType?.map((eachTrend, index) => (
        <div key={index} className={trendFilter === eachTrend.trendName ? "trendContent trendSelected" : "trendContent"} onClick={() => (i(eachTrend.trendName))}>
                <div>
                    <p>{eachTrend.trendName}</p>
                    <span>{eachTrend.trendCount} Nitrochats</span>
                </div>
                <div>
                    <p className='dots'>
                        {trendFilter === eachTrend.trendName ? (<>
                            <FontAwesomeIcon icon={faAnglesLeft}/>
                        </>) : (<>
                            <FontAwesomeIcon icon={faEllipsis}/>
                        </>)}
                    </p>
                </div>
        </div>
    ))}
    </>
    )
}

function TrendsForYou() {
    const generalTrendsData = useSelector((state) => state.trends.generalTrends);
    const countryTrendsData = useSelector((state) => state.trends.countryTrends);
    const personnalTrendsData = useSelector((state) => state.trends.personnalTrends);
    const profileIdRedux = useSelector((state) => state.profile.profileId);

    const trendValue = useSelector((state) => state.trends.filterTrend);
    const postsData = useSelector((state) => state.posts.posts);

    const navState = useSelector((state) => state.navigation.navigation);

    const dispatch = useDispatch();

    const [trendMedias, setTrendMedias] = useState([]);
    const [trendUsers, setTrendUsers] = useState([]);

    const GQLTrendsFetch = async () => { 
        const generalTrendsFetched = await TrendsGQL();
        dispatch(setGeneralTrends(generalTrendsFetched.forumTrends));
    }
    
    const SwitchToMessagePage = async (MessageId) => { dispatch(setNavigation('#specificMessage'));dispatch(setSpecificMessageId(MessageId));dispatch(setPreviousNavState("#home"));};

    useEffect(() => {
        GQLTrendsFetch();
    }, []);

    useEffect(() => {
        setTrendMedias([]);
        if(postsData?.length > 0){
            var p  = ( trendValue?.length > 0 ?  postsData?.map((eachPost, index) => {if (eachPost.post?.hashtags?.includes(trendValue)){return eachPost;}}) : 
            postsData?.map((eachPost, index) => {return eachPost})  );
            p = p.filter((c, index) => {return (c != undefined && c?.post?.imageUrl?.length > 3);});
            p = p.sort((a, b) => 0.5 - Math.random());
            p = (trendValue?.length > 0 ? p.slice(0, 6) :   p.slice(0, 3) );
            var u = p.map(post => {return post.user; });
            u = u.filter((c, index) => {return u.findIndex(a => a.id == c.id) === index;});

            setTrendUsers(u); setTrendMedias(p);
        }
    }, [postsData,trendValue, profileIdRedux]);

    useEffect(() => {
   
        if (navState !== "#home"){
            dispatch(setFilterTrends(""));
            (async () => {
                let o =  Math.floor(Math.random() * 3);
                if (o === 1){
                    await GQLTrendsFetch();
                }
            })();
        }
    }, [navState]);

    return (
        <>
        {trendMedias?.length > 0 && <>
            <div className={trendMedias?.length > 3 ? "trendSliderPrnt bigSldr" : "trendSliderPrnt"}>
                <Swiper spaceBetween={40}
                        slidesPerView={1}
                        direction={"vertical"}
                        pagination={{clickable: true,}}
                        autoplay={{delay: 6000, disableOnInteraction: false}}
                        modules={[Navigation, Pagination, Scrollbar,Autoplay]}
                >
                <SwiperSlide>
                    <div className="trendTopParnt">
                        {trendMedias?.length > 0 && <>
                            {trendValue?.length > 0 ? <>
                                <h1 className='animate__animated animate__bounceIn'> Trend Medias <span>{trendValue}</span></h1>
                            </> : <>
                                <h1 className='animate__animated animate__bounceIn'>Nitrochat <span>Community</span> Medias</h1>
                            </>}
                        </>}

                        {trendMedias?.length > 0 && <>
                            <div className="mediasTrend">
                                    {trendMedias?.map((media) => (<>
                                        <Fade>
                                            <img onClick={() => (SwitchToMessagePage(media.post._id))} src={media.post.imageUrl} alt="" />
                                        </Fade>
                                    </>))}
                            </div>    
                        </>}
                    </div>
                </SwiperSlide>

                <SwiperSlide>
                    <div className="trendTopParnt userPrntTrend">
                        {trendUsers?.length > 0 && <>
                            {trendValue?.length > 0 ? <>
                                <h1 className='animate__animated animate__bounceIn'>People Medias <span>{trendValue}</span></h1>
                            </> : <>
                                <h1 className='animate__animated animate__bounceIn'><span>Community</span> People</h1>
                            </>}
                        </>}

                        {trendUsers?.length > 0 && <>
                            <div className={trendMedias?.length > 3 ? "userTrenddRow bigRwUsr " : "userTrenddRow"}>
                                    {trendUsers?.map((user) => (<>
                                        <Fade>
                                            <div className='userTrendd' onClick={() => {dispatch(setProfileId(user.id));dispatch(setNavigation("#userProfile"));}}>
                                                <img src={user.imageUrl} alt="" />
                                                <h1>{user.prenom} {user.nom}</h1>
                                                <h2>@{user.pseudo}</h2>
                                            </div>
                                        </Fade>
                                    </>))}
                            </div>    
                        </>}
                    </div>
                </SwiperSlide>


                </Swiper>
            </div>
    </>}

        <div className={(trendValue?.length > 0  && trendMedias?.length > 0)  ? 'showMedias_ whole_trends_card' : 'whole_trends_card'}>
            <h1>Trends for you</h1>
            
            <div className="trendsScroll">
                <div className="TrendTopic">
                    <>
                    <h2>TRENDING IN NITROCHAT</h2>
                    {generalTrendsData?.length > 0 && <>
                    <TrendMap TrendType={generalTrendsData}/>
                    </> }
                    </>
                </div>

                <div className="TrendTopic">
                    <>
                        <h2>TRENDING IN YOUR COUNTRY</h2>
                        {countryTrendsData?.length > 0 && <>
                        <TrendMap TrendType={countryTrendsData}/>
                        </> }
                    </>
                </div>
    
                <div className="LastTrendTopic">
            
                    {personnalTrendsData?.length > 0 && <>
                    <>
                        <h2>YOUR FOLLOWINGS • TRENDING</h2>
                        <TrendMap TrendType={personnalTrendsData}/>
                        </>
                    </> }
                    
                
                </div>
            </div>

            {navState !== "#explore" ? <> 
                    <Link to={`/forum/explore`} >
                        <h4 onClick={() => (dispatch(setNavigation('#explore'))) }>Show More</h4>
                    </Link>
                </> : <>
                <h4> </h4>
                </>}

        </div>
        <div className="prv">
            <small>Nitrochat Terms of use - Privacy Policy- {(new Date().getFullYear())} Nitrochat Inc.</small>
        </div>

    </>)
}

export default TrendsForYou
