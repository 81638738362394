import {useEffect, useState, useLayoutEffect} from 'react'
import '../styles/pages/forum.scss';
import '../styles/components/connectFirst.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {CheckUserAuth} from '../services/UserServices';

import NitrochatMessages from '../componentsPages/NitrochatMessages';
import UserProfile from '../componentsPages/UserProfile';
import UserMessages from '../componentsPages/UserMessages';
import Notifications from '../componentsPages/Notifications';

import TrendsForYou from '../components/TrendsForYou';
import FollowSuggestion from '../components/followSuggestion';
import ProfileCard from '../components/profileCard';
import NewMessage from '../components/newMessage'
import Header from '../components/header';
import { faLaptopCode, faFeatherPointed, faLemon, faBolt,faFireFlameCurved, faLightbulb, faSeedling} from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch} from "react-redux";

import CommentModal from '../modal/commentModal';  
import EditModal from '../modal/editModal'
import SpecificMessage from '../componentsPages/SpecificMessages';
import Explore from '../componentsPages/Explore';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {updateProfileFollows, setRelationsProfiles} from '../feature/profileSlice';
import {setNavigation} from '../feature/navigationSlice';

import {getOwnFollowings} from '../services/UserServices';

import UserBookmarks from '../componentsPages/UserBookmarks';
import LiveChat from '../componentsPages/LiveChat';

import Nitro from '../componentsPages/Nitro';
import Fade from 'react-reveal/Fade';

import cookies from 'js-cookie';
import io from 'socket.io-client';

import { updateUserConnections, pushUserConnection} from '../feature/messageSlice';
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const socket = io(process.env.REACT_APP_API_SOCKET, {
    withCredentials: true,
    autoConnect: false,
    transports : ['websocket']
}); // the main namespace

function MainThread() {
    const navigationState = useSelector((state) => state.navigation);
    const filterState = useSelector((state) => state.trends.filterTrend);


    switch(navigationState.navigation){
        case '#home':
            return (
                <>
                    <NewMessage/>
                    
                    <NitrochatMessages/>
                </>
            )

        case '#liveChat':
                return (
                     <>
                            <LiveChat/>
                    </>
                    )
        case '#nitro':
            return (
                <>
                    <Nitro/>
                </>
        )
        case '#userProfile':
            return (
                <>
                    <UserProfile/>
                </>
            )
        case '#userMessages':
                return (
                <>
                    <UserMessages/>
                </>
                )
        case '#notifications':
            return (
                <>
                    <Notifications/>
                </>
                )
        case '#bookmarks':
            return (
                 <>
                        <UserBookmarks/>
                </>
                )
        case '#specificMessage':
            return (
                <>
                    <SpecificMessage/>
                </>
        )
        case '#explore':
            return (
                <>
                    <Explore/>
                </>
        )
    }
  
}

export const notify = (text) => toast.success(text, {
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
});

function Nitrochat() {
    const [userAuth, setuserAuth] = useState(false);
    const [asyncLoaded , setAsyncLoaded] = useState(false);

    const modalState =  useSelector((state) => state.comments.commentModal);
    const editModal = useSelector((state) => state.profile.editModal);

    const showAllbool = useSelector((state) => state.navigation.showMmbrsThread);
    const trendFilter = useSelector((state) => state.trends.filterTrend);

    const HandleAuthentification = async () => {
       const resp = await CheckUserAuth();
       setAsyncLoaded(true);
       if(resp.status === 200){
            setuserAuth(true);
            followingsUpdate();
       }else{
            setuserAuth(false);
       }
    }
    const followingsUpdate = async ( )=> {
        const updatedFollows = await getOwnFollowings({userId: cookies.get('userId'), jwt: cookies.get('jwt')});
        dispatch(updateProfileFollows(updatedFollows?.data?.ids?.followings_Ids));
        dispatch(setRelationsProfiles(updatedFollows?.data?.relationsData));
    };
    
    useEffect(() => {
        if(userAuth){followingsUpdate();}
    }, [trendFilter, showAllbool]);


    useEffect(() => {
        HandleAuthentification();
    }, []);

    useEffect(() => {
    }, [modalState, editModal])


    const [hide , setHide] = useState(false);
    const navigationState = useSelector((state) => state.navigation);
    const dispatch = useDispatch();
    const [randInt , setRandInt] = useState(0);
    useEffect(() => {
        if (navigationState.navigation === "#userMessages" || navigationState.navigation === "#liveChat" || navigationState.navigation === "#nitro"){
            setHide(true);
        }else{
            setHide(false);
        }

        if (navigationState.navigation === "#home"){
            setRandInt(Math.floor(Math.random() * 6));
            if(Math.floor(Math.random() * 6) === 2 || Math.floor(Math.random() * 6) === 3){
                followingsUpdate();
            }
        }
    }, [navigationState.navigation]);


    // Socket Initializaion
    useEffect(() => {
        if (asyncLoaded){
            socket.auth = {
                userId : cookies.get("userId"),
                jwt : cookies.get("jwt"),
            };
            socket.connect();
            socket.on("users", (users) => {
                dispatch(updateUserConnections(users))
            });
            socket.on("userConnected", (users) => {
                dispatch(pushUserConnection(users))
            });
        }
    }, [userAuth, asyncLoaded]);

    // Socket Persistent Users Connections
    useEffect(() => {
        if (asyncLoaded){
            socket.on("users", (users) => {
                dispatch(updateUserConnections(users));
            });
            socket.on("userConnected", (users) => {
                dispatch(pushUserConnection(users));
            });
        }
    }, []);
        

    if (asyncLoaded){
        return (

        <div className="body_home_alt">
            
                {userAuth ? (
                <>
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        draggable
                        toastStyle={{ 
                            backgroundColor: "#1da1f2",
                            borderRadius: "12px",
                            textAlign: "center",
                            border: "2px solid white",
                            fontFamily: "Barlow, sans-serif"
                        }} 
                        theme="colored"
                    />
                    <div className="header_home">
                        <Header/>
                    </div>
                    
                    {navigationState.navigation === "#liveChat" ? <>
                        <div className="liveChat_home">
                            <MainThread/>
                        </div>
                    </> :<>
                        <div className={hide === true ? "homepage message_homepage" : "homepage"}>
                            <div className= "left_side">
                                    <div className="side_content">
                                        <ProfileCard/>
                                        <FollowSuggestion/>
                                        {navigationState.navigation !== "#home" && <>
                                            <Fade bottom>
                                            <button onClick={() => (dispatch(setNavigation('#home')))} className='newNitrochatt'>New Nitrochat
                                                {(() => { 
                                                switch(randInt) {
                                                    case 0:
                                                        return <><FontAwesomeIcon style={{marginLeft: "6px"}} icon={faBolt}/></>
                                                    case 1:
                                                        return <><FontAwesomeIcon  style={{marginLeft: "6px"}} icon={faLemon}/></>
                                                    case 2:
                                                        return <><FontAwesomeIcon  style={{marginLeft: "6px"}} icon={faFeatherPointed}/></>
                                                    case 3:
                                                        return <><FontAwesomeIcon  style={{marginLeft: "6px"}} icon={faLightbulb}/></>
                                                    case 4:
                                                        return <><FontAwesomeIcon  style={{marginLeft: "6px"}} icon={faSeedling}/></>
                                                    case 5:
                                                        return <><FontAwesomeIcon  style={{marginLeft: "6px"}} icon={faFireFlameCurved}/></>
                                                }})()}
                                            </button>
                                            </Fade>
                                        </>}
                                    </div>
                            </div>
                            
                            <div className={hide === true ? "middle_page message_fix" : "middle_page"}>
                                <MainThread/>
                            </div>

                            <div className={hide === true ? "right_side hidden" : "right_side"}>
                                <div className="side_content">
                                    <TrendsForYou/>
                                </div>
                            </div>
                        </div>
                    </>}
             
                 
                    <>
                            {modalState ? (<>
                                <CommentModal/>
                            </>) : (<>
                                <></>
                            </>)}

                            {editModal ?  (<>
                                <EditModal/>
                            </>): (<></>)}
                    </>
                </>
                
                ):(
                    <>
                    <div className="connect_first_p">
                        <Fade>
                            <h3>Nitrochat <FontAwesomeIcon icon={faLaptopCode}/></h3>
                            <h1>Connect first to access <span> Nitrochat.</span></h1>
                            <h2 className='tp' style={{marginTop: "15px"}}><strong>Authentify</strong> yourself first  to <strong> discover</strong> <i>Nitrochat</i> </h2>
                            <h2 style={{marginBottom: "25px"}}> <strong>Create</strong>  yourself an Account or <strong>Log</strong>  into your existing Nitrochat account</h2>
                            <video className='vdBg' preload loop playsInline autoFocus autoPlay muted src={(require('../media/connectFirstt.mp4'))} alt="Home Template" />
                            <div className="btns">

                                <NavLink exact to="/" >
                                    <button>Go back home</button>
                                </NavLink>

                                <p>OR</p>

                                <div>
                                    <NavLink exact to="/signup" >
                                        <button>Sign Up</button>
                                    </NavLink>

                                    <NavLink exact to="/login" >
                                        <button>Sign In</button>
                                    </NavLink>
                                </div>
                            </div>
                        </Fade> 
                    </div>
                    </>
                )}
        </div>
    
        )
    }else{
        return (
            <div className="loading_logo">
                <span><FontAwesomeIcon icon={faLaptopCode} /> </span>
            </div>
        )
    }
 
}

export default Nitrochat
