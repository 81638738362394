import { createSlice, current } from "@reduxjs/toolkit";

export const profileSlice = createSlice({
    name: "profile",
    initialState: {
        profileId: "",
        profilePosts: [],
        alternativePosts: [],
        profileFollows: [],
        profileMode: 0,
        showFollows: false,
        editModal: false,
        modalData: {},
        editQuery: 0,
        profileFollows: [],
        flwngsFlwersLoaded : false,
        relationsProfiles: [],
        bookmarkedPosts: [],
        ownData: {}
    },
    reducers: {
        setProfileId: (state, {payload}) => {
            state.profileId = payload;
        },
        setProfilePost: (state, {payload}) => {
            state.profilePosts = payload;
        },
        setAltPosts : (state, {payload}) => {
            state.alternativePosts = payload
        },
        setProfileFollows: (state, {payload}) => {
            state.profileFollows = payload;
        },
        setProfileMode: (state, {payload}) => {
            state.profileMode = payload;
        },
        setFollowsBool : (state, {payload}) => {
            state.showFollows = payload;
        },
        setEditModal : (state, {payload}) => {
            state.editModal = payload
        },
        setModalData: (state, {payload}) => {
            state.modalData = payload
        },
        newUserModification: (state, {payload}) => {
            state.editQuery++;
        },
        updateProfileFollows: (state, {payload}) => {
            state.profileFollows = payload;
        },
        setFlwngsFlwersLoaded: (state, {payload}) => {
            state.flwngsFlwersLoaded = payload;
        },
        setRelationsProfiles:  (state, {payload}) => {  
            state.relationsProfiles = payload;
        },
        setBookmarkedPosts: (state, {payload}) => {
            state.bookmarkedPosts = payload;
        },
        setOwnData: (state, {payload}) => {
            state.ownData = payload;
        }
    }
})
export const {setProfileId, setProfilePost, setAltPosts, setProfileFollows, setRelationsProfiles,setOwnData,
setProfileMode, setFollowsBool, setEditModal, setModalData, newUserModification, updateProfileFollows, setFlwngsFlwersLoaded, setBookmarkedPosts} = profileSlice.actions;
export default profileSlice.reducer;
