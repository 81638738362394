import {useEffect,useState, useRef} from 'react';
import '../styles/components/commentModal.scss';
import {switchModalState} from '../feature/commentSlice'
import {pushNewComment} from '../feature/postsSlice';
import {useDispatch, useSelector} from "react-redux";
import Fade from 'react-reveal/Fade';
import { faReplyAll, faCircleXmark} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {CommentPost} from '../services/PostServices';
import cookies from 'js-cookie';
import {UserWritingMessages} from '../services/UserServices';

function CommentModal() {
    const dispatch = useDispatch();
    const CommentBox = useRef();
    const SenderPFP = useSelector(state => (state.comments.commenterPFP));
    const ModalContent = useSelector(state => (state.comments.commentModalContent));
    const [replyValue, setReplyValue] = useState("");
    const [userData, setUserData] = useState([]);

    const FetchUserData = async () => {
        const resp = await UserWritingMessages();
        setUserData(resp.getUser);
    }

    useEffect(() => {
        FetchUserData();
        document.addEventListener("click", handleOutsideClick);
    });

    useEffect(()=> {  
    }, [ModalContent]);
    
    const handleOutsideClick = (event) => {
        const hasClass =  event.target.classList.contains('comment_body');
        if (hasClass === true){
            dispatch(switchModalState(false));
        }
    }

    const commentPost = async () => {
        if (replyValue.length > 0){
            const resp = await CommentPost( ModalContent.postId, {
                commenterId: cookies.get("userId"),
                text: replyValue,
                replyTo: ""
            });
            if(resp.status === 200){
                dispatch(switchModalState(false));
                 dispatch(pushNewComment([ModalContent.postIndex, {
                    comment : resp.data,
                    user : {
                        id: cookies.get("userId"),
                        nom: userData.nom,
                        prenom: userData.prenom,
                        pseudo: userData.pseudo,
                        imageUrl: userData.imageUrl
                    }  
                }]));
            }
        }
    }
    return (
        <>
        <Fade>
            <div className='comment_body'>                
                <div className="comment_modal_card"  ref={CommentBox}>
                    <button className='close_btn' onClick={() => {dispatch(switchModalState(false))}}><FontAwesomeIcon icon={faCircleXmark}/></button>

                    <div className="cmnt_modalContent">
                        <div className="top">
                            <div>
                                <img src={ModalContent.imageUrl} alt="" />
                            </div>
                            <div className="text_cntnt">
                                <h1> {ModalContent.nom} {ModalContent.prenom} <span>@{ModalContent.pseudo}</span></h1>  
                                <h3>{ModalContent.desc}</h3>
                                <p>In response to <span>@{ModalContent.pseudo}</span></p> 
                            </div>
                  

                        </div>

                        <div className="bottom">
                            <div className='bottom_img'>
                                <img src={SenderPFP} alt="" />
                            </div>
                            <div className='input_div'>
                                <textarea placeholder='Comment the post'  maxLength="120" rows="" cols="" name="" form=""
                                onChange={e => setReplyValue(e.target.value)}
                                />
                            </div>
                        </div>
     

                        </div>

                    <div className="reply_div">
                        <p>{replyValue.length}/120</p>
                        <button onClick={() => {commentPost()}}  className='reply_btn'>REPLY <FontAwesomeIcon icon={faReplyAll}/></button>
                    </div>

                </div> 
            </div>
        </Fade>
        </>
    )
}

export default CommentModal
