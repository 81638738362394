import cookies from 'js-cookie';
const axios = require('axios');
const default_url = process.env.REACT_APP_API_REST;


var config = { 
    headers: {
        authorization: `${cookies.get("jwt")}`,
        authentification: `${cookies.get("userId")}`
    }
}

export async function Channels() {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/availableChannels/' + cookies.get("userId"), {
         userId: cookies.get("userId")
        },config);
        return response.data;
    }catch(error) {
        return [];
    }
}

export async function getChannel(ChannelId) {
    try{
        if(ChannelId.length < 1){
            return 0;
        }
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/' + ChannelId, {
         userId: cookies.get("userId")
        },config);
        return response.data;
    }catch(error) {
        return [];
    }
}


export async function newChannelMessage(ChannelId, msgObj) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/sendMessage/' + ChannelId, msgObj,config);
        return response.data;
    }catch(error) {
        return [];
    }
}

export async function createNewChannel(channelObj) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/initiate/', channelObj,config);
        return response.status;
    }catch(error) {
        return [];
    }
}


export async function getDiscover() {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.get(default_url + '/channel/discover/' ,config);
        return response.data;
    }catch(error) {
        return [];
    }
}

export async function joinChannel(channelId) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/join/' + channelId, {
            userId: cookies.get("userId")
        },config);
        return response;
    }catch(error) {
        return [];
    }
}


export async function getFriends() {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/getFriendsInvites/' + cookies.get("userId"), {},config);
        return response;
    }catch(error) {
        return [];
    }
}



export async function inviteFriends(channelId,friendsList) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/invite/' + channelId, {
            usersList: friendsList
        },config);
        return response;
    }catch(error) {
        return [];
    }
}


export async function editChannel(channelId, data) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/editChannel/' + channelId, data,config);
        return response;
    }catch(error) {
        return [];
    }
}


export async function editChannelPicture(channelId, form) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/editChannelPicture/' + channelId, form,config);
        return response;
    }catch(error) {
        return [];
    }
}



export async function editRole(channelId, roleData) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/editRole/' + channelId, roleData,config);
        return response;
    }catch(error) {
        return [];
    }
}


export async function createRole(channelId) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/createRole/' + channelId, {},config);
        return response;
    }catch(error) {
        return [];
    }
}

export async function assignRole(channelId, obj) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/assignRole/' + channelId, obj,config);
        return response;
    }catch(error) {
        return [];
    }
}


export async function banMember(channelId, memberId) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/banMember/' + channelId, memberId,config);
        return response;
    }catch(error) {
        return [];
    }
}

export async function kickMember(channelId, memberId) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/kickMember/' + channelId, memberId,config);
        return response;
    }catch(error) {
        return [];
    }
}
export async function unbanMember(channelId, memberId) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/unbanMember/' + channelId, memberId,config);
        return response;
    }catch(error) {
        return [];
    }
}

export async function pinMessage(channelId, messageId) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/pinMessage/' + channelId, messageId,config);
        return response;
    }catch(error) {
        return [];
    }
}

export async function channelGif(gif, id) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/channel/editChannelGif/' + id, gif,config);
        return response;
    }catch(error) {
        return [];
    }
}

export async function discoverHome() {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.get(default_url + '/channel/homeDiscover/',config);
        return response;
    }catch(error) {
        return [];
    }
}