import Spline from '@splinetool/react-spline';
import {useEffect, useState} from 'react'
import '../styles/pages/liveChat.scss'
import { useSelector, useDispatch} from "react-redux";
import {updateUserConnections, pushUserConnection } from '../feature/messageSlice';
import {setChannelMessages, pushChannelMessage, setActualChannelId , setChannelModal, updateChannels } from '../feature/channelSlice';
import cookies from 'js-cookie';
import {liveChatUsers, relationUserInfo, notifUserInfo} from '../services/UserServices';
import {Channels, getChannel, newChannelMessage, getDiscover, joinChannel} from '../services/ChannelServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeadphones, faCheck, faRightToBracket, faMagnifyingGlass, faLock,
    faCircleCheck, faBan, faCircle} from '@fortawesome/free-solid-svg-icons'
import {dateParser} from '../services/utils';
import Fade from 'react-reveal/Fade';
import 'react-loading-skeleton/dist/skeleton.css';

function Discover({usersData}){
    const [channelInfos, setChannelInfos] = useState([]);

    const [join32, setJoin32] = useState(0);
    const [connectedMembers, setConnectedMembers] = useState([]);
    const [joinedChannels, setJoinedChannels] = useState([]);

    const [randTemplate, setRandTemplate] = useState(1);

    const [searchBar, setSearchBar] = useState('');
    const [vids, setVids] = useState(['exploreeee.mp4','exploreee.mp4', 'exploree.mp4']);

    const dispatch = useDispatch();
    const channelUpdate = useSelector((state) => state.channel.channelCreationJoin);

    useEffect(() => {
        const i = Math.floor(Math.random() * 3);
        setRandTemplate(i);
    }, [])

    const connectedUsers = useSelector((state) => state.messages.connectedUsers);
    const update = async () => {
        const i  =  await getDiscover();
        setChannelInfos(i);
        const newArr = [];
        await Promise.all(i.map( async (channel, index) => {
            var connected = 0;
            var joined = false;
            await Promise.all(channel.usersIds.map((userData) => {
                const pushUser =  connectedUsers.find(user => user === userData.userId);
                if (pushUser){
                    connected ++;
                };
                if (userData.userId === cookies.get("userId")){
                    newArr.push(channel._id);
                }
            }));
            setConnectedMembers(current => [...current, connected]);
        }));
        setJoinedChannels(newArr);
    };

    useEffect(() => {
        update();
        
    }, [channelUpdate]);

    const joinChannelBtn = async (id) => {
        const i = await joinChannel(id);
        if (i.status === 200){
            setJoin32(join32 + 1);
            update();
            dispatch(updateChannels());
        }
    };

    return(<>
        <div className="discoverParent">
            <Fade>
                <div className="templateDiscover">
                    <video className='videotmplt' preload loop playsInline autoFocus autoPlay src={require('../media/' + vids[randTemplate] )} alt="Template" />
                    <div className="searchDiscover">
                            <h1>Find your community on Nitrochat</h1>
                            <h2>Explore the various channels on Nitrochat made by the community <br/> and maybe you will the one that got everything you need ! </h2>
                            <div className="searchdiv">
                                <input placeholder='Explore Community Channels' maxLength={32} type="text" onChange={e => (setSearchBar(e.target.value))}/>
                                <span><FontAwesomeIcon icon={faMagnifyingGlass}/></span>
                            </div>
                            <button>Explore</button>
                    </div>
                </div>
            </Fade>
            <div className="discover">
                    {channelInfos.map((info, index) => (
                        <>
                        { (info.channelName.toLowerCase().includes(searchBar.toLowerCase()) === true) && <>
                            <Fade>
                                <div className="discoverChannel">
                                    {info.generalChannel === true ? <>
                                        <video preload loop playsInline autoFocus autoPlay muted src={info?.channelBanner} alt="Channel Preview" />
                                    </> : <>
                                            <img className='chanBanner' src={info.channelBanner} alt="Channel Picture" />
                                    </>}

                                    <img className='channelPic' src={info.channelPicture} alt="" />
                                    <h1>{info.channelName} {info.generalChannel === true && <><i style={{color: "#1da1f2"}}> <FontAwesomeIcon icon={faCircleCheck}/></i></>}</h1>
                                    <p>{info.generalChannel === true ? <>General Channel</> : <> {info.private === true ? <>Private</> : <>Public</>} Community Channel  </>}</p>

                                    <h2>{info.channelDescription}</h2>
                                    {info.generalChannel === false && <>
                                        <p className='date'> <>Created {dateParser(new Date(info.createdAt), 2)}</></p>
                                    </>}
                                    <div className="row">
                                        {info.generalChannel === true ? <>
                                            <h3><span className='onln'> <FontAwesomeIcon icon={faCircle}/></span>  {connectedUsers.length} Online Members</h3>
                                            <h3> <span> <FontAwesomeIcon icon={faCircle}/></span> {usersData.length} Members</h3>
                                        </>: <>
                                            <h3><span className='onln'> <FontAwesomeIcon icon={faCircle}/></span>  {connectedMembers[index]} Online Members</h3>
                                            <h3> <span> <FontAwesomeIcon icon={faCircle}/></span> {info.usersIds.length} Members</h3>
                                        </>}
                    
                                    </div>
                                    {info.generalChannel === true ? <>
                                        <h4> Joined <FontAwesomeIcon icon={faCheck}/> </h4>
                                    </> : <>
                                        {joinedChannels.includes(info._id) ? <>
                                            <h4> Joined <FontAwesomeIcon icon={faCheck}/> </h4>
                                        </> : <>
                                            {info.bannedUsers.includes(cookies.get("userId") ) ? <>
                                                <h4 className='joinChannel'>Banned <FontAwesomeIcon icon={faBan}/></h4>
                                            </> : <>
                                                <h4 className='joinChannel' onClick={() => (joinChannelBtn(info._id))}>Join Channel  {info.private ? <><FontAwesomeIcon icon={faLock}/></> : <><FontAwesomeIcon icon={faRightToBracket}/></>}</h4>
                                            </>}
                                        </>}
                                    </>}
                                </div>
                            </Fade>
                        </>}

                    </>
                ))}
            </div>
        </div>
    </>)
}
export default Discover
