import {useEffect, useState} from 'react'
import Fade from 'react-reveal/Fade';
import '../styles/components/userFollows.scss'
import{followsUserData, FollowersAndFollowings,ownFollowings} from '../services/UserServices';
import {useDispatch,useSelector} from "react-redux";
import {setProfileFollows, setProfileMode, setFollowsBool, setFlwngsFlwersLoaded} from '../feature/profileSlice';
import cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faLaptopCode, faMedal, faCircleCheck, faCheck, faAnglesRight, faBolt} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom';
import {setProfileId} from '../feature/profileSlice';
import {setNavigation} from "../feature/navigationSlice";
import {FollowUser} from '../services/UserServices';
import {InitiateDm} from '../services/MessageServices';
import { faSpeakerDeck } from '@fortawesome/free-brands-svg-icons'

function FollowsMap(MapMode){
    const followingsFollowers = useSelector((state) => state.profile.profileFollows);
    const followingsMode = useSelector((state) => state.profile.profileMode);
    const flwgsLoaded = useSelector((state) => state.profile.flwngsFlwersLoaded);

    const [arrayToMap, setArrayToMap] = useState([]);
    const [idsToMatch, setIdsToMatch] = useState([]);
    const profileIdRedux = useSelector((state) => state.profile.profileId);

    const dispatch = useDispatch();

    const [loaded, setLoaded] = useState(false);

    const ids = async () => {
        const ownFlwngs  = await ownFollowings(cookies.get('userId'));
        setIdsToMatch(ownFlwngs.getUser.followings_Ids);
        switch(MapMode.MapMode){
            case 0:
                setArrayToMap(followingsFollowers.followers);
                break
            case 1:
                setArrayToMap(followingsFollowers.followings);
                break
            case 2: 
                const e = followingsFollowers.followers.filter(aFollow => idsToMatch.includes(aFollow.id));
                setArrayToMap(e);
                break
        } 
        setLoaded(true);
        return
    }

    const followInteraction = async (follow_id) => {
        const r = await FollowUser(follow_id);
        ids();
    }
    
    useEffect(() => { 
        setLoaded(false);
        ids();
    }, [followingsFollowers,followingsMode]);

    const SwitchToMessagePage = async (ProfileId) => {
        dispatch(setNavigation('#userProfile'));
        dispatch(setProfileId(ProfileId));
    };


    const directMessage = async () => {
        const r = await InitiateDm({
             params: cookies.get("userId"),
             userId : profileIdRedux
         })
         dispatch(setNavigation('#userMessages'));        
     }
    return (
        <>
            {loaded === false || flwgsLoaded === false ? <>
                <div className="lds-dual-ring"></div>
            </>:<>
            {arrayToMap?.length < 1 ? (<>
                <div className="noFollows">
                    {MapMode.MapMode === 0 ? (<>
                        <h1>This User has no <span>followers</span>  yet.</h1>
                    </>) : (<>
                        <h1>This User dosen't <span>follows</span> anyone yet.</h1>
                    </>)}
                    <h2>Encourage this user to interact with other people on Nitrochat !</h2>
                    <Link to={`/forum/messages/`} >
                        {profileIdRedux !== cookies.get("userId") && (<>
                            <button  onClick={() => (directMessage())}>Let him know <FontAwesomeIcon icon={faAnglesRight}/></button>
                        </>)}
                    </Link>
                </div>
            </>) : (<>
                {arrayToMap?.map((eachFollow, index) => (
                <Fade>
                <div className="follow_card">
                    <div className="lft">
                        <img src={eachFollow.imageUrl} alt="" />
                    </div>
                    <div className="rgt">
                        <div className="rgt_top">
                            <Link to={`/forum/profile/@${eachFollow.pseudo}`} >
                                <div className='names' onClick={() => (SwitchToMessagePage(eachFollow.id))}>
                                    <h1> {eachFollow.prenom} {eachFollow.nom} 
                                        {eachFollow.mediumBadge && <> <FontAwesomeIcon   style={{color :  "gold"}}  icon={faBolt} /> </>}
                                        {eachFollow.nitroBadge && <> <FontAwesomeIcon   style={{color :  "cyan"}}  icon={faSpeakerDeck} /> </>}
                                        {eachFollow.verified &&<> <FontAwesomeIcon  icon={faCircleCheck} style={{color: "#1da1f2"}}/>
                                    </>}
                                    
                                      </h1>
                                    <h2>  @{eachFollow.pseudo}</h2>
                                </div>
                            </Link>
                            <div className='e'>
                                {eachFollow.id != cookies.get('userId') &&<>
                                    {idsToMatch.includes(eachFollow.id) === false ? (<>
                                        <button className='flw' onClick={() => (followInteraction(eachFollow.id))}>Follow </button>   
                                    </>): (<>
                                        <button className='flwed' onClick={() => (followInteraction(eachFollow.id))}>Followed  <span><FontAwesomeIcon icon={faCheck}/> </span></button>   
                                    </>) }  
                                </>}
                            </div>
                        </div>
                        <div className="rgt_bot">
                            <p>{eachFollow?.biography}</p>
                        </div>
                    </div> 
                </div>
                </Fade>
            )).reverse()} 
             
            </>)} 
            </>}
        </>
    )
}
function UserFollows({User, userData}) {
    const [user, setUser] = useState({});
    const [similarSubs, setSimilarSubs] = useState(false);

    const [folLoaded,  setFolLoaded] = useState(false);

    const dispatch = useDispatch();
    const followingsFollowers = useSelector((state) => state.profile.profileFollows);
    const followingsMode = useSelector((state) => state.profile.profileMode);
    const profileIdRedux = useSelector((state) => state.profile.profileId);

    const initData  = async () => {
       const r =  await FollowersAndFollowings(User);
       //const info = await followsUserData(User.User);
       const ownFlwngs  = await ownFollowings(cookies.get('userId'));

       //setUser(info.getUser);
        dispatch(setProfileFollows(r.userFollows));
        dispatch(setFlwngsFlwersLoaded(true));
        for (var i = 0; i < followingsFollowers.followers?.length; i++) {
            if (ownFlwngs.getUser.followings_Ids.includes(followingsFollowers.followers[i].id) === true){
                return setSimilarSubs(true);
            }
        };
    }

    useEffect( () => {
        dispatch(setFlwngsFlwersLoaded(false));
        dispatch(setProfileFollows({followers: [], followings: []}));
        initData();
        dispatch(setProfileMode(0))
    }, [profileIdRedux]); 

    return (
        <div className='wholeFollows'>
            <div className="follow_header">
                <div className="header_top">
                    <div className="returnBtn">
                        <button onClick={() => (dispatch(setFollowsBool(false)))}><FontAwesomeIcon icon={faArrowLeft}/></button>
                    </div>
                    <div className="pseudos">
                        <h1>{userData.nom} {userData.prenom}</h1>
                        <h2>@{userData.pseudo} </h2>
                        <h3>{userData.postsCount} Nitrochats <FontAwesomeIcon icon={faLaptopCode}/></h3>
                    </div>
                </div>
                <div className="header_bottom">
                    {userData._id !== cookies.get("userId") && <>
                        {similarSubs === true && <>
                            <p onClick={() => (dispatch(setProfileMode(2)))} className={followingsMode === 2 && "active"}>FOLLOWERS YOU MAY KNOW</p>
                        </>}
                    </>}
                    <p onClick={ () => (dispatch(setProfileMode(0)))}className={followingsMode === 0 && "active"}>FOLLOWERS</p>
                    <p onClick={() => (dispatch(setProfileMode(1)))} className={followingsMode === 1 && "active"}>FOLLOWINGS</p>   
                </div>
            </div>

            <div className="follows_map">
               <FollowsMap MapMode={followingsMode}/>
            </div>
        </div>
    )
}

export default UserFollows
