import {useState, useEffect, useRef} from 'react'
import '../styles/components/editModal.scss'
import { useSelector, useDispatch } from "react-redux";
import { setEditModal, newUserModification} from '../feature/profileSlice';
import {editPasswrd, editUser, editUserPhoto} from '../services/UserServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport,faXmark, faPenToSquare, faImages, faKey, faImagePortrait, faDownload, faLink, faAt } from '@fortawesome/free-solid-svg-icons'
import Fade from 'react-reveal/Fade';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cookies from 'js-cookie';

function EditModal() {
    const modalData = useSelector((state) => state.profile.modalData);
    const dispatch = useDispatch();

    const MAX_FILE_SIZE = 2048 // 2MB

    const notify = (text) => toast.success(text, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [pseudoState, setPseudo] = useState('');
    const [biographyState, setBiographyState] = useState('');

    const [link, setLink] = useState('');

    const [passwrdOne, setPasswrdOne] = useState('');
    const [passwrdTwo, setPasswrdTwo] = useState('');

    const [topCaution, setTopCaution] = useState('');
    const [bottomCaution, setBottomCaution] = useState('');


    const [newPfp, setNewPfp] = useState('');
    const [newBanner, setNewBanner] = useState('');

    const pfpInput = useRef(null);
    const bannerInput = useRef(null);

    const [pfpUrl, setPfpUrl] = useState('');
    const [bannerUrl, setBannerUrl] = useState('');
    useEffect(() => {
        setFirstName(modalData.nom);
        setLastName(modalData.prenom);
        setPseudo(modalData.pseudo);
        setBiographyState(modalData.biography);
        setLink(modalData.profileLink);
    }, [modalData]);

    const edtPassword = async () => {
        if (passwrdOne.length >= 6){
            setBottomCaution('')
            if (passwrdOne === passwrdTwo){
                const modified = await editPasswrd(passwrdTwo);
                notify("Password Modified ! 🔐");
                setPasswrdOne("");setPasswrdTwo("");
                return
            }
            setBottomCaution('Password not similar');
        }else{
            setBottomCaution('At least 6 characters.');
        }
    }

    useEffect(() => {
        setBannerUrl(modalData.profileBanner);
        setPfpUrl(modalData.imageUrl);
    }, []);

    const edtUser = async () => {
        if (firstName.length >= 2 && lastName.length >= 2 && pseudoState.length >= 2 && biographyState.length >= 2){
            const e = await editUser({
                prenom:  lastName,
                nom: firstName,
                biography: biographyState,
                pseudo: pseudoState,
                link: link
            });
            if (e.status === 200){
                dispatch(newUserModification());
                notify("Profile Informations Modified ! 📝");
            }
        }
    }

    const edtPhoto = async (newPhoto, isBanner) => {
        var APIFormMessage = new FormData();
        if (isBanner===true){        
            APIFormMessage.append('isBanner', isBanner);
            APIFormMessage.append('image', newPhoto,  cookies.get('userId') + "_profile_" + newPhoto.name);
        }else{
            APIFormMessage.append('image', newPhoto,  cookies.get('userId') + "_banner_" + newPhoto.name);
        }
        if (firstName.length >= 2 && lastName.length >= 2){
            const photoUpdated =  await  editUserPhoto(APIFormMessage);
            if (photoUpdated.status === 201){
                dispatch(newUserModification());
                if (isBanner === true){
                    setBannerUrl(photoUpdated.data);
                    notify("Profile Banner Updated !");
                }else{
                    setPfpUrl(photoUpdated.data);
                    notify("Profile Picture Updated !");
                }
            }
        }
    }

    return (
        <div className='edit_body'>
                <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                toastStyle={{ 
                    backgroundColor: "#1da1f2",
                    borderRadius: "15px"
                 }} 
                theme="colored"
                />
            <div className="edit_card"> 

                <div className="one">
                    <div className='ne'>
                        <h2 className='close' onClick={() => (dispatch(setEditModal(false)))}><FontAwesomeIcon icon={faXmark}/></h2>
                        <h2><FontAwesomeIcon icon={faPenToSquare}/>  Edit your profile</h2>
                    </div>
     
                    <div>
                        <h3 onClick={() => (edtUser())}>Save Modifications <FontAwesomeIcon icon={faDownload}/></h3>
                    </div>
                </div>

                <div className="two">
                    <div className='bnr'>
                        <input  ref={bannerInput} type="file" className='upload_pic' accept="image/png, image/jpeg, image/webp, image/jpg"  onChange={e => edtPhoto(e.target.files[0], true)}/>

                        <img className='banner' src={bannerUrl} alt=""/>
                    </div>
                    <div className='prf'>
                        <div className='dd'>
                            <input ref={pfpInput} type="file" className='upload_pic' accept="image/png, image/jpeg, image/webp, image/jpg"  onChange={e => edtPhoto(e.target.files[0], false)} />

                            <img className='pfp'  src={pfpUrl} alt="" />
                            <p className='pfp_d' onClick={() => pfpInput.current.click()}> <span><FontAwesomeIcon icon={faImagePortrait}/></span> Edit <span>Profile Picture </span> </p>
                        </div>
                        <div className='ef'>
                            <p  onClick={() => bannerInput.current.click()}>
                              <span><FontAwesomeIcon icon={faFileImport}/></span>  Edit your <span> Banner </span><span><FontAwesomeIcon icon={faImages}/>
                            </span></p>
                        </div>
      
                    </div>
                </div>

                <div className="thr">
                    
                    <div className="namesArea">

                        <Fade bottom>
                        <div className="textArea fst">
                            <div className='title'>
                                <p>First Name</p>
                                <p>{lastName.length} / 24</p>
                            </div>
                            <div>
                                <input type="text"  value= {lastName}  onChange={e => setLastName(e.target.value)}  maxLength={24}/>
                            </div>

                        </div>
                        </Fade>

                        <Fade bottom>
                        <div className="textArea scd">
                            <div className='title'>
                                <p>Last Name</p>
                                <p>{firstName.length} / 24</p>
                            </div>
                            <div>
                                <input type="text"  value= {firstName}  onChange={e => setFirstName(e.target.value)}  maxLength={24}/>
                            </div>
                        </div>
                        </Fade>

                    </div>
  
         
                    <Fade bottom>
                    <div className="textArea">
                        <div className='title'>
                                <p>Username <FontAwesomeIcon icon={faAt}/></p>
                                <p>{pseudoState.length} / 28</p>
                        </div>
                        <div>
                                <input type="text"  value={pseudoState}   onChange={e => setPseudo(e.target.value)} maxLength={28}/>
                        </div>
                    </div>
                    </Fade>


                    <Fade bottom>
                    <div className="textArea">
                        <div className='title'>
                                <p>Biography</p>
                                <p>{biographyState?.length} / 128</p>
                        </div>
                        <div>
                                <input type="text"  value={ biographyState}    onChange={e => setBiographyState(e.target.value)}  maxLength={128}/>
                        </div>
                    </div>
                    </Fade>

                    <Fade bottom>
                    <div className="textArea">
                        <div className='title'>
                                <p>Website Link <FontAwesomeIcon icon={faLink}/></p>
                                <p>{link?.length} / 512</p>
                        </div>
                        <div>
                                <input type="url" value={link}    onChange={e => setLink(e.target.value)}  maxLength={512}/>
                        </div>
                    </div>
                    </Fade>
                <Fade>
                <div className="namesArea psw">
                    <h4>Modify your password</h4>
                    <h5>6 characters minimum.</h5>
                </div>

                <div className="namesArea">

                        <div className="textArea fst">
                            <div className='title'>
                                <p>New Password</p>
                                <p>{passwrdOne.length} / 24</p>
                            </div>
                            <div>
                                <input type='password'  value= {passwrdOne}  onChange={e => setPasswrdOne(e.target.value)}  maxLength={24}/>
                            </div>
                        </div>

                        <div className="textArea scd">
                            <div className='title'>
                                <p>Rewrite Password</p>
                                <p>{passwrdTwo.length} / 24</p>
                            </div>
                            <div>
                                <input  type='password'  value= {passwrdTwo}  onChange={e => setPasswrdTwo(e.target.value)}  maxLength={24}/>
                            </div>
                        </div>

                </div>
                </Fade>  

                <div className="namesArea psw">
                {bottomCaution.length > 0 && <><h4 className='bot_caut' >{bottomCaution}</h4></>}
                    <h6 onClick={() => (edtPassword())}>Save Password Modification <FontAwesomeIcon icon={faKey}/></h6>
                </div>


                </div>
            </div>
        </div>
    )
}

export default EditModal
