const axios = require('axios');
const default_url = process.env.REACT_APP_API_REST;

export async function GoogleSignIn(googleData){
    const resp =  await axios.post(default_url + '/auth/OAuthSignup',googleData).then(resp => {
        return(resp);
    }).catch(err => {
         return(err);
    });
    return(resp);
}


export async function LogInGoogle(data){
    const axiosResp =  await axios.post(default_url + '/auth/OAuthSignIn',data).then(async (resp) => {
        return(resp);
    }).catch(err => {
         return(err);
    });
    return(axiosResp);
}