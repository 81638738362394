import { createSlice } from "@reduxjs/toolkit";

export const trendSlice = createSlice({
    name: "trends",
    initialState: {
        generalTrends: [],
        countryTrends: [],
        personnalTrends: [],
        filterTrend: ""
    },
    reducers: {
        setGeneralTrends: (state, {payload}) => {
            state.countryTrends = payload.slice(3,6);
            state.generalTrends = payload.slice(0,3);
        },
        setPersonnalTrends: (state, {payload}) => {
            state.personnalTrends = payload;
        },
        setFilterTrends: (state, {payload}) => {
            if (payload?.length > 1 ){
                state.filterTrend = payload;
            }else{
                state.filterTrend = "";
            }
        }
    }
})
export const {setGeneralTrends, setPersonnalTrends, setFilterTrends} = trendSlice.actions;
export default trendSlice.reducer;
