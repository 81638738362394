import cookies from 'js-cookie';
import { GraphQLClient, graphql, request, gql } from "graphql-request";

const axios = require('axios');
//const graphQLendpoint = 'http://localhost:3000/api/graphql';
const graphQLendpoint = process.env.REACT_APP_API_GQL;
//const restApi = 'http://localhost:3000/api'
const restApi = process.env.REACT_APP_API_REST;

var config = { 
    headers: {
        authorization: `${cookies.get("jwt")}`,
        authentification: `${cookies.get("userId")}`
    }
}

export async function CheckUserAuth (){
    try{
        const resp = await axios.post(restApi + '/auth/checktoken',{
            authorization: cookies.get("jwt"),
            authentification: cookies.get("userId")
        }, config);
        return(resp);
    }catch(err){
        return (err);
    }
}

export async function VerifyEmailLink (id, token){
    try{
        const resp = await axios.post(restApi + '/auth/verify/' + id + '/ ' + token,{}, config);
        return(resp);
    }catch(err){
        return (err);
    }
}


export async function SignUpAuth(data){ //////// SIGN UP API CALL
    try{
      const resp =  await axios.post(restApi + '/auth/signup',
            data,config).then(resp => {
                return(resp.data);
            }).catch(err => {
                return(err);
            });
        return(resp);
    }catch(err){
        return (err);
    }
}



export async function SignInAuth(data){ //////// SIGN IN API CALL
    try{
      const resp =  await axios.post(restApi +'/auth/login', 
               data,config
         ).then(async resp => {
                if (resp.data.errors){
                    return(resp.data.errors);
                }else {
                    await Promise.all(cookies.set('jwt', resp.data.token, {expires: 3})); //// 72H JWT TOKEN
                    await Promise.all(cookies.set('userId',  resp.data.userId, {expires: 3})); //// 72H STORED USER ID
                    return(true);
                }
            }).catch(err => {
                console.log(err);
            });
        return(resp);
    }catch (err) {
        console.log(err);
    }
}


export async function FetchUser(){ //////// OWN USER 
    try{
      config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
      const resp =  await axios.get(restApi + '/auth/' + cookies.get('userId'), config).then(resp => {
                return(resp.data);
            }).catch(err => {
                return(err);
            });
        return(resp);
    }catch(err){
        return (err);
    }
}


export async function ProfileCardInf(){ //////// OWN USER 
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })

        const queryA = `  
        query($userId: ID!){
                        getUser(id: $userId){
                            imageUrl
                            profileBanner
                            pseudo
                            nom
                            prenom
                            verified
                            nitroBadge
                            mediumBadge
                            followings
                            followers
                            biography
                            profileLink
                        }
                    
                }
        `
        const variables = {
                userId: cookies.get('userId'),
        }

        const data = await graphQLClient.request(
            queryA,
            variables
          );
        return(data);
}



export async function UserWritingMessages(){ //////// OWN USER 
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })

        const queryA = `  
        query($userId: ID!){
                        getUser(id: $userId){
                            imageUrl
                            pseudo
                            nom
                            prenom
                            verified
                            nitroBadge
                            mediumBadge
                            pinnedConversations
                        }
                    
                }
        `
        const variables = {
                userId: cookies.get('userId'),
        }

        const data = await graphQLClient.request(
            queryA,
            variables
          );
        return(data);
}



export async function HeaderInfQuery(){ //////// OWN USER 
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })

    const queryA = `  
    query($userId: ID!){
                    getUser(id: $userId){
                        imageUrl
                        nom
                        prenom
                        blockedUsers
                        notifMutedUsers
                        nitro
                        nitroBadge
                        bookmarks
                        mediumBadge
                    }
                
            }
    `
    const variables = {
            userId: cookies.get('userId'),
    }

    const data = await graphQLClient.request(
        queryA,
        variables
      );
    return(data);
}




export async function whoFollowsYou(){
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })
    const queryA = `  
    query($userId: ID!){
                whoFollowsYou(id: $userId){
                        id
                        imageUrl 
                        pseudo
                        nom
                        prenom
                        verified
                        nitroBadge
                        mediumBadge
                    }
            }
    `
    const variables = {
            userId: cookies.get('userId'),
    }

    const data = await graphQLClient.request(
        queryA,
        variables
      );
    return(data);
}



export async function GetSpecificUserRest(GetId){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.get(restApi + '/auth/' + GetId, config).then(resp => {
                  return(resp.data);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }
}

export async function FollowUser(iD){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const followResp =  await axios.post(restApi + '/auth/follow/' + iD,{
            userId: cookies.get("userId").toString()
        }, config).then(resp => {
                  return(resp);
              }).catch(err => {
                  return(err);
              });
          return(followResp);
      }catch(err){
          return (err);
      }
}

export async function FollowersAndFollowings(UserId) {
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })
    const query = gql`  
    query($userId: ID!){
        userFollows(id : $userId){
                followers{
                    id
                    pseudo
                    imageUrl
                    nom
                    prenom
                    biography
                    verified
                    nitroBadge
                    mediumBadge
                    followers_Ids
                }
                followings{
                    id
                    pseudo
                    imageUrl
                    nom
                    prenom
                    biography
                    verified
                    nitroBadge
                    mediumBadge
                    followers_Ids
                }
            }
        }   
    `
    const variables = {
        userId: UserId,
    }

    const data = await graphQLClient.request(query, variables);
    return(data);
}



export async function followsUserData(userId){ //////// RETURN arrow PROFILE DATA 
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })

    const queryA = `  
    query($userId: ID!){
                    getUser(id: $userId){
                        id
                        pseudo
                        nom
                        prenom
                        postsCount
                        followings_Ids
                    }
                
            }
    `
    const variables = {
            userId: userId,
    }

    const data = await graphQLClient.request(
        queryA,
        variables
      );
    return(data);
}



export async function ownFollowings(userId){ //////// CONSULTING PROFILE DATA 
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })

    const queryA = `  
    query($userId: ID!){
                    getUser(id: $userId){
                        followings_Ids
                    }
                
            }
    `
    const variables = {
            userId: userId,
    }

    const data = await graphQLClient.request(
        queryA,
        variables
      );
    return(data);
}





export async function notificationCount(userId){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.get(restApi + '/auth/notificationsLen/' + userId, config ).then(resp => {
                  return(resp.data);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }
}
export async function notifications(userId){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.get(restApi + '/auth/notifications/' + userId, config).then(resp => {
                  return(resp.data);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }
}
export async function markAsSeen(userId){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi + '/auth/notifications/' + userId, {}, config).then(resp => {
                  return(resp.data);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }
}






export async function notifUserInfo(userId){ //////// CONSULTING PROFILE DATA 
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })
    const queryA = `  
    query($userId: ID!){
                    getUser(id: $userId){
                        id
                        pseudo
                        imageUrl
                        nom
                        prenom
                        nitroBadge
                        mediumBadge
                        verified
                    }
                
            }
    `
    const variables = {
            userId: userId,
    }

    const data = await graphQLClient.request(
        queryA,
        variables
      );
    return(data);
}



export async function archiveNotification(notificationId){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi + '/auth/archiveNotification/' + cookies.get("userId"), {
            notifId: notificationId
        }, config).then(resp => {
                  return(resp.data);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }
}





export async function seachFor(searchValue){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi + '/auth/research/' + searchValue, {}, config 
            ).then(resp => {
                  return(resp.data);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }
}

export async function seachForIdentif(searchValue){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi + '/auth/researchIdentification/' + searchValue, {}, config 
            ).then(resp => {
                  return(resp.data);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }
}




export async function ExploreReslv() {
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })
    const query = gql` 
    query($userId: ID!){
        explore(id: $userId){
                trends{
                    trendName
                    trendCount
                }
                profiles{
                    id
                    pseudo
                    profileBanner
                    imageUrl
                    nom
                    verified
                    prenom
                    followers_Ids
                    postsCount
                    nitroBadge
                    mediumBadge
                    biography
                }
                pinnedPost{
                    post{
                        _id
                        description
                        retweets
                        userRetweets
                        likes
                        usersLiked
                        imageUrl
                        comments{
                            _id
                            postId
                            commenterId
                            text
                            timestamp
                            replyTo
                            likes
                        }
                        hashtags
                        createdAt
                    }
                    user{
                        id
                        pseudo
                        imageUrl
                        nom
                        verified
                        prenom 
                        nitroBadge
                        mediumBadge
                    }
                }
                mediaPosts{
                    post{
                        _id
                        description
                        retweets
                        userRetweets
                        likes
                        usersLiked
                        imageUrl
                        comments{
                            _id
                            postId
                            commenterId
                            text
                            timestamp
                            replyTo
                            likes
                        }
                        hashtags
                        createdAt
                    }
                    user{
                        id
                        pseudo
                        imageUrl
                        nom
                        verified
                        nitroBadge
                        mediumBadge
                        prenom 
                    }
                }
            } 
    }   
    `
    const variables = {
        userId: cookies.get('userId'),
    }
    const data = await graphQLClient.request(query, variables);
    return(data);
}


export async function editPasswrd(pasw){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi + '/auth/editPassword/' + cookies.get("userId"), {
            password: pasw
        }, config).then(resp => {
                  return(resp.data);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }
}


export async function editUser(data){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi + '/auth/editUser/' + cookies.get("userId"), data, config).then(resp => {
                  return(resp);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }
}


export async function editUserPhoto(photo){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi + '/auth/editPhoto/' + cookies.get("userId"), photo, config).then(resp => {
                  return(resp);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }
}



export async function getOwnFollowings(data){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi + '/auth/userFollowings/' + cookies.get("userId"), data).then(resp => {
                  return(resp);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }
}




export async function relationUserInfo(userId){ //////// RETURN arrow PROFILE DATA 
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })

    const queryA = `  
    query($userId: ID!){
                    getUser(id: $userId){
                        id
                        nom
                        prenom
                        pseudo
                        imageUrl
                    }
                
            }
    `
    const variables = {
            userId: userId,
    }

    const data = await graphQLClient.request(
        queryA,
        variables
      );
    return(data);
}





export async function specificPostUser(userId){ //////// RETURN arrow PROFILE DATA 
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })

    const queryA = `  
    query($userId: ID!){
                    getUser(id: $userId){
                        id
                        pseudo
                        nom
                        prenom
                        imageUrl
                        followers
                        postsCount
                        nitroBadge
                        mediumBadge
                        verified
                    }
                
            }
    `
    const variables = {
            userId: userId,
    }

    const data = await graphQLClient.request(
        queryA,
        variables
      );
    return(data);
}



export async function liveChatUsers(){ //
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })

    const queryA = `  
    {
        getLiveChatUsers{
                    id
                    prenom
                    nom
                    pseudo
                    imageUrl
                    followings
                    createdAt
                    followers
                    profileBanner
                    verified
                    nitroBadge
                    mediumBadge
                    fadeValues
                    profileFade
        }
    }  
    `
    const data = await graphQLClient.request(
        queryA
      );
    return(data);
}


export async function liveChatUserInfo(userId){ //////// CONSULTING PROFILE DATA 
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })

    const queryA = `  
    query($userId: ID!){
                    getUser(id: $userId){
                        pseudo
                        imageUrl
                        nom
                        prenom
                        createdAt
                        channelsCount
                        nitroBadge
                        mediumBadge
                        fadeValues
                        profileFade
                    }
                
            }
    `
    const variables = {
            userId: userId,
    }

    const data = await graphQLClient.request(
        queryA,
        variables
      );
    return(data);
}


export async function maskNotif(userId){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi + '/auth/muteNotification/' + userId, {}, config).then(resp => {
                  return(resp);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }
}


export async function getNitroInfss(){ //////// OWN USER 
    try{
      config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
      const resp =  await axios.get(restApi + '/auth/nitroInfos/' + cookies.get('userId'), config).then(resp => {
                return(resp.data);
            }).catch(err => {
                return(err);
            });
        return(resp);
    }catch(err){
        return (err);
    }
}


export async function buyUnlock(unlockType){ //////// NITRO UNLOCK
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
      const resp =  await axios.post(restApi + '/auth/nitroUnlock/' + unlockType , {},config).then(resp => {
                return(resp);
            }).catch(err => {
                return(err);
            });
        return(resp);
    }catch(err){
        return (err);
    }
}


export async function editProfieColors(fadeColors, bool){ //////// FADE PROFILE EDIT 
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
      const resp =  await axios.post(restApi + '/auth/editFade/' , {newColors: fadeColors, active: bool},config).then(resp => {
                return(resp);
            }).catch(err => {
                return(err);
            });
        return(resp);
    }catch(err){
        return (err);
    }
}

export async function editChannelColors(chanId,fadeColors, bool){ //////// FADE PROFILE EDIT 
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
      const resp =  await axios.post(restApi + '/auth/editChannelFade/' + chanId, {newColors: fadeColors, active: bool},config).then(resp => {
                return(resp);
            }).catch(err => {
                return(err);
            });
        return(resp);
    }catch(err){
        return (err);
    }
}


export async function editUserGifPhoto(photoGif){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi + '/auth/editGif/' + cookies.get("userId"), photoGif, config).then(resp => {
                  return(resp);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }
}

