import {useEffect, useState} from 'react'
import '../styles/components/whoFollowYou.scss';
import {whoFollowsYou } from '../services/UserServices';
import Fade from 'react-reveal/Fade';
import { Link } from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {setNavigation, updateSuggs} from "../feature/navigationSlice";
import {setProfileId} from '../feature/profileSlice';
import { faBolt, faCircleCheck, faMedal} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {FollowUser} from '../services/UserServices';
import { faSpeakerDeck } from '@fortawesome/free-brands-svg-icons'

function FollowSuggestion() {
    const dispatch = useDispatch();
    const[showMore, setShowMore] = useState(false);
    const[followSuggestion, setFollowSuggestion] = useState([]);
    const changingProfilePage = useSelector((state) => state.profile.profileId);
    const[followedArr, setFollowedArr] = useState([]);

    const followSuggs = async () => {
        const resp = await whoFollowsYou();
        setFollowSuggestion(resp.whoFollowsYou);
        dispatch(updateSuggs(resp.whoFollowsYou));
    }
    useEffect(() => {
        followSuggs();
        setFollowedArr([]);
    }, []);
    
    useEffect(()=> {
        followSuggs();
        setFollowedArr([]);
    }, [changingProfilePage]);

    const SwitchToMessagePage = async (ProfileId) => {
        dispatch(setNavigation('#userProfile'));
        dispatch(setProfileId(ProfileId));
    };

    const followButton = async (userId,index) => {
        if (followedArr.includes(index) === false){
            const follow = await FollowUser(userId);
            setFollowedArr(current => [...current, index]);
        }
    }

    return (
    <div>
        {followSuggestion?.length > 0 && <>
            <div className="who_follows_you">
                <h2>Follows Suggestions</h2>

                <div className={showMore ? 'followsList long' : 'followsList short'}>
                    {showMore === false ? (<>
                        {followSuggestion.slice(0, 2).map((eachSuggs, index) => (
                        <>
                            <Fade>
                                    <div className="follow_sugg">
                                        <img src={eachSuggs.imageUrl} alt="User Picture"></img>
                                            <div className="flw_names"  onClick={() =>     SwitchToMessagePage(eachSuggs.id) } >
                                                <h1>{eachSuggs.prenom} {eachSuggs.nom}
                                                {eachSuggs.mediumBadge && <> <FontAwesomeIcon   style={{color :  "gold"}}  icon={faBolt} />  </>}
                                                {eachSuggs.nitroBadge && <> <FontAwesomeIcon   style={{color :  "cyan"}}  icon={faSpeakerDeck} />  </>}
                                                {eachSuggs.verified==true&&<><i style={{color: "#1da1f2"}}> <FontAwesomeIcon icon={faCircleCheck}/></i> </>}
                                                </h1>
                                                <h3>@{eachSuggs.pseudo}</h3>
                                            </div>
                                        <button className={followedArr.includes(index ) == true && "followed"} onClick={() => (followButton(eachSuggs.id, index))}>
                                            {followedArr.includes(index) == true ? <>Followed</> : <>Follow</>}
                                        </button>                                      
                                    </div>
                            </Fade>
                         </>
                    ))}
                    </>): (<>
                        {followSuggestion.map((eachSuggs, index) => (
                        <>
                            <Fade>
                                    <div className="follow_sugg" key={index}>
                                        <img src={eachSuggs.imageUrl} alt="User Picture"></img>
                                            <div className="flw_names"  onClick={() =>     SwitchToMessagePage(eachSuggs.id) }  >
                                                <h1>{eachSuggs.prenom} {eachSuggs.nom}
                                                {eachSuggs.mediumBadge && <> <FontAwesomeIcon   style={{color :  "gold"}}  icon={faBolt} />  </>}
                                                {eachSuggs.nitroBadge && <> <FontAwesomeIcon   style={{color :  "cyan"}}  icon={faSpeakerDeck} />  </>}
                                                {eachSuggs.verified==true&&<><i style={{color: "#1da1f2"}}> <FontAwesomeIcon icon={faCircleCheck}/></i> </>}  
                                                </h1>
                                                <h3>@{eachSuggs.pseudo}</h3>
                                            </div>
                                        <button className={followedArr.includes(index) == true && "followed"} onClick={() => (followButton(eachSuggs.id, index))}>
                                            {followedArr.includes(index) == true ? <>Followed</> : <>Follow</>}
                                        </button>  
                                    </div>
                            </Fade>
                         </>
                    ))}
                    </>)}
        
                </div>
                {followSuggestion.length > 2 && <>
                    <h4 onClick={() => (setShowMore(!showMore))}>Show {showMore === true ? (<>less</>) : (<>more</>)}</h4>
                </>}
            </div>  
        </>}
    </div>
    )
}

export default FollowSuggestion
