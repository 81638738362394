import {useEffect, useState} from 'react';
import {ProfileCardInf} from '../services/UserServices';
import {useDispatch, useSelector  } from "react-redux";
import '../styles/components/profileCard.scss';
import {setNavigation} from "../feature/navigationSlice";
import {setProfileId, setOwnData} from '../feature/profileSlice';
import cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faLink, faBolt} from '@fortawesome/free-solid-svg-icons'
import { faSpeakerDeck } from '@fortawesome/free-brands-svg-icons'
function ProfileCard() {
    
    const [UserInfo, setUserInfo] = useState({});
    const dispatch = useDispatch();
    const changingPage = useSelector((state) => state.navigation);
    const changingProfilePage = useSelector((state) => state.profile.profileId);
    const userModification = useSelector((state) => state.profile.editQuery);
    const cardDataRdx = useSelector((state) => state.profile.ownData);

    const InitUserInfo = async () => {
       const resp = await ProfileCardInf();
       setUserInfo(resp.getUser);
       dispatch(setOwnData(resp.getUser));
    }
    useEffect(() => {
        InitUserInfo();
    }, [userModification]);

    useEffect(() => {
       let p = Math.floor(Math.random() * 3);
       if (p === 1){
            InitUserInfo();
       }
    }, [changingPage.navigation, changingProfilePage]);

    const SwitchToMessagePage = async () => {
        dispatch(setNavigation('#userProfile'));
        dispatch(setProfileId(cookies.get('userId')));
    };
    return (
            <div className="profile_card_content">
                <Fade>

                <div className="divdiv">
                    <Fade>
                    {UserInfo?.profileBanner ? <>
                        <img src={UserInfo.profileBanner}></img>
                    </> : <>
                        <img src={cardDataRdx?.profileBanner}></img>
                    </>}
                    </Fade>
                </div>

                <div className="profile_card_top">
                    <Fade>
                    {UserInfo?.imageUrl ? <>
                        <img src={UserInfo.imageUrl}></img>
                    </> : <>
                        <img src={cardDataRdx?.imageUrl}></img>
                    </>}
                    </Fade>
                    
                    {UserInfo?.prenom ? <>
                        <h1>{UserInfo.prenom} {UserInfo.nom} {UserInfo.mediumBadge && <> <FontAwesomeIcon   style={{color :  "rgb(255, 211, 17)"}}  icon={faBolt}  />  </>}
                        {UserInfo.nitroBadge && <>  <FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}  />   </>}
                        {UserInfo.verified && <> <FontAwesomeIcon style={{color: "#1da1f2"}} icon={faCircleCheck} bounce /> </>} 
                        </h1>
                        <h2>@{UserInfo.pseudo}</h2>
                        <h3>{UserInfo.biography}</h3>
                    </> : <>
                        <h1>{cardDataRdx?.prenom} {cardDataRdx?.nom} {cardDataRdx?.mediumBadge && <> <FontAwesomeIcon   style={{color :  "rgb(255, 211, 17)"}}  icon={faBolt}  />  </>}
                        {cardDataRdx?.nitroBadge && <>  <FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}  />   </>}
                        {cardDataRdx?.verified && <> <FontAwesomeIcon style={{color: "#1da1f2"}} icon={faCircleCheck} bounce /> </>} 
                        </h1>
                        <h2>@{cardDataRdx?.pseudo}</h2>
                        <h3>{cardDataRdx?.biography}</h3>
                    </>}
    
    
                </div>

                <div className="profile_card_mid">
                    <div className='bar'>
                        <p>{UserInfo.followings}</p>
                        <h1>Followings</h1>
                    </div>
                    <div>
                        <p>{UserInfo.followers}</p>
                        <h1>Followers</h1>
                    </div>
                </div>

                <div className="profile_card_bottom">
                    <Link to={`/forum/profile/${UserInfo.pseudo}`} >
                        <h2 onClick={() => SwitchToMessagePage()} >
                            My Profile
                        </h2>
                    </Link>
                    {UserInfo?.profileLink?.length > 2 && <>
                        <h2> <a target='blank' href={UserInfo.profileLink}><FontAwesomeIcon icon={faLink} style={{marginLeft: "8px", cursor: "pointer"}}/>  </a>  </h2>
                    </>}
                </div>

                </Fade>
            </div>

    )
}

export default ProfileCard
