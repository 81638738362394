import React, {useEffect, useState} from 'react'
import '../styles/pages/home.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faGoogle, faApple, faGooglePlay, faInstagram, faTwitter, faTiktok, faLinkedin} from '@fortawesome/free-brands-svg-icons'
import { faMagnifyingGlass, faLaptopCode, faArrowRightLong, faCircleCheck, faRightToBracket} from '@fortawesome/free-solid-svg-icons'
import { NavLink } from "react-router-dom";
import Fade from 'react-reveal/Fade';
import {getAllCounts} from '../services/HomeServices';
import {discoverHome} from '../services/ChannelServices';
import {nFormatter} from "../services/utils";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


function Home() {
    const [data, setData] = useState({});
    const [chData, setChData] = useState([]);
    const [lded, setLded] = useState(false);

    const [fe, setF] = useState('');

    useEffect(() => {
        (async() => {
            const o = await getAllCounts();
            if(o.status == 200){
                setData(o.data);
            }  
            const p = await discoverHome();
            if(p.status == 200){
                setChData(p.data);
            }  

            setLded(true);
        })();
    }, []); 

    return (<>
        <div className='home_div'>
            <video className='vdBg' preload loop playsInline autoFocus autoPlay muted src={(require('../media/homeBg.mp4'))} alt="Home Template" />
            <div className="header">

                <div className="left">
                    <h1 className='ttttle'> <span><FontAwesomeIcon icon={faLaptopCode} bounce /></span> Nitrochat </h1>
                </div>
                <div className="mid">
                    <h2 className='act'>Home</h2>
                    <h2>Why Nitrochat ?</h2>
                    <h2><a target='blank' href={"https://github.com/julorapido"}> About Us</a></h2>
                    <h2><a target='blank' href={"https://github.com/julorapido"}>  GitHub  <FontAwesomeIcon icon={faGithub} /> </a>  </h2>   
                </div>
                <div className="right">
                    <NavLink exact to="/signup" style={{ textDecoration: 'none', color: "white" }} > <button className='btn-2'>Join Nitrochat</button></NavLink>
                </div>

            </div>


            <div className="content">

                <div className="top">
                        <div className="left">
                            <Fade bottom>
                            <h1>Take your Social Skills to the next level with <span>Nitrochat.</span> </h1>
                            <h2>
                                You can take apart of any <strong>Community</strong>  that you want with Nitrochat Channels, if you can't find one then <strong> create your Own ! </strong>
                                Join the Nitrochat Community and contribute to the evolution of Nitrochat.
                            </h2>
                            <div className="socials">
                                <h3 ><a target='blank' href="https://twitter.com/youradrien3003"> <FontAwesomeIcon icon={faTwitter}/>  </a></h3>
                                <h3 ><a target='blank' href="https://www.instagram.com/julorapido/"> <FontAwesomeIcon icon={faInstagram}/> </a> </h3>
                                <h3 ><a target='blank' href="https://www.tiktok.com/@roadsurf3r"><FontAwesomeIcon icon={faTiktok}/> </a></h3>
                                <h3 ><a target='blank' href="https://www.linkedin.com/in/jules-sainthorant-258657221/"><FontAwesomeIcon icon={faLinkedin}/> </a></h3>
                            </div>
                            <div className="search">
                                <div className='iner' style={{display: "flex", flexDirection: "row", paddingLeft: "7px", width:'50%'}}>
                                <p><FontAwesomeIcon style={{color: '#daeeff'}} icon={faMagnifyingGlass}/></p>
                                <input onChange={e => (setF(e.target.value))} maxLength={32} type="text" placeholder='Search Community...' />
                                </div>
                                <NavLink exact to="/signup" > <button className='btn-2'>Register <FontAwesomeIcon icon={faGoogle} /> </button></NavLink>
                            </div>

                            <div className="btns">
                                <NavLink exact to="/signup">
                                    <button className='btn-1'>Sign Up</button>
                                </NavLink>

                                <NavLink exact to="/login">
                                    <button className='btn-2'>Log In</button>
                                </NavLink>
                            </div>
                            </Fade>
                        </div>

                        <div className="spline_div">
                            <Swiper
                                spaceBetween={40}
                                slidesPerView={1}
                                direction={"vertical"}
                                pagination={{clickable: true,}}
                                autoplay={{delay: 7000, disableOnInteraction: false}}
                                modules={[Navigation, Pagination, Scrollbar,Autoplay]}
                            >
                                <SwiperSlide>
                                    <video className='templateVid' preload loop playsInline autoFocus autoPlay muted src={(require('../media/home.mp4'))} alt="Home Template" />
                                </SwiperSlide>
            
                                {chData?.length > 0 && <>
                                    <SwiperSlide>
                                        <div className="discover_home">
                                            <p className='find'>Find your <span>Community</span>  on Nitrochat</p>
                                            {chData?.length > 0 ? <>
                                                {chData?.map((c, index) => (<>
                                                    { (c.channelName.toLowerCase().includes(fe.toLowerCase())) && <>
                                                        <div className="discover_div">
                                                            {c.generalChannel ? <>
                                                                <video className='bnrrr'  preload loop playsInline autoFocus autoPlay muted src={c.channelBanner} alt="Home Template" />
                                                            </> : <>
                                                                <img className='bnrrr' src={c.channelBanner} alt="" />
                                                            </>}

                                                            <div className='rCl'>
                                                                <div className='rw'>
                                                                    <img className='pfp' src={c.channelPicture} alt="" />
                                                                    <div className="cl">
                                                                    <h1>{c.channelName}</h1>
                                                                    <h2>{c.generalChannel ? <>General Nitrochat Channel <FontAwesomeIcon style={{color: "#3fefff"}} icon={faCircleCheck}/> </> : <> {c.private ? <>Private </> : <>Public</> } Community Channel</>}</h2>
                                                                    </div>
                                                                </div>
                                                    
                                                                <h3>{c.channelDescription}</h3>
                                                                {!c.generalChannel && <>
                                                                    <h4>{c.usersIds} Members</h4>
                                                                </>}
                                                            </div>
                                                        </div>
                                                    </>}
                                                </>))}
                                            </> : <>
                                                <div className="lds-dual-ring"></div>
                                            </>}
                                        </div>
                                    </SwiperSlide>
                                </>}
                                <SwiperSlide>
                                    <div className="self_presentation">
                                            <img src="https://res.cloudinary.com/dqgvklq2y/image/upload/v1683887585/nitrochat/profiles/picture/mdmf2tvjawajicbnr6cc.jpg" alt="" />
                                            <h1>Jules Sainthorant</h1>
                                            <h2>@<span>julorapido</span></h2>
                                            <p> 19yo <strong> Software Developper </strong>, also mak <strong>  mobile games </strong> for fun. <br/> 
                                                Passionate by Computer related things <br/>
                                                9% bodyfat <strong> Gamer</strong>  <br/>
                                                Self-Taught Dev<br/>
                                                Nitrochat C.T.O & C.E.O 
                                            </p>

                                            <div className="btnss">
                                               <button className='gt'> <a target='blank' href="https://github.com/julorapido"> GitHub <FontAwesomeIcon icon={faGithub}/>  </a> </button>
                                                <button className='ggl'> <a target='blank' href="https://play.google.com/store/apps/developer?id=julorapido"> Google Play <FontAwesomeIcon icon={faGooglePlay}/>  </a> </button>
                                                <button className='apl'>Apple Store <FontAwesomeIcon icon={faApple}/>  </button>
                                            </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                </div>

                
    
            </div>

            <Fade left>
                <div className="bottom">
                    {(lded) && <>
                        <p style={{borderColor : chData?.length < 1 ? "orange" : "cyan"}}>Server Status {chData?.length < 1 ? <> <i style={{color: "orange"}} >•</i>   <span>Sleepy</span> </> : <> <i style={{color: "cyan"}}>•</i>  <span>Alive</span></> } </p>
                    </> }


                    <div>
                        <h3> {data?.users ?<>{nFormatter(data?.users * 100, 1)}</>: <>1K</> } <span>+</span> </h3>
                        <h4>Users</h4>
                    </div>

                    <div className='arw'><FontAwesomeIcon icon={faArrowRightLong}/></div>

                    <div>
                        <h3>  {data?.posts ?<>{nFormatter(data?.posts * 100, 1)} </>: <>9K</> } <span>+</span> </h3>
                        <h4>Posts</h4>
                    </div>

                    <div className='arw'><FontAwesomeIcon icon={faArrowRightLong}/></div>

                    <div>
                        <h3> {data?.channels ?<>{nFormatter(data?.channels - 1, 1)} </>: <>13</> } </h3>
                        <h4>Community Channels</h4>
                    </div>

                    <div className='arw'><FontAwesomeIcon icon={faArrowRightLong}/></div>

                    <div>
                        <h3> {data?.nitro ? <>{nFormatter(data?.nitro - 1, 1)} </>: <>41.2K</> }  <span>+</span> </h3>
                        <h4>Nitro Diffused</h4>
                    </div>
          
                </div>
            </Fade>

        </div>
    </>)
}

export default Home
