import {useEffect, useState, useRef} from 'react'
import '../styles/pages/notifications.scss'
import Fade from 'react-reveal/Fade';
import { notifications, markAsSeen, notifUserInfo, archiveNotification} from '../services/UserServices';
import cookies from 'js-cookie';
import { useSelector, useDispatch} from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faAt,faHeart, faQuoteLeft,faComment, faRetweet, faUser,faCircle, faEnvelope, faArrowUpWideShort,faArrowDownShortWide,faMagnifyingGlass,faGraduationCap, faShareFromSquare, faCircleCheck, faBolt, faHandPointDown } from '@fortawesome/free-solid-svg-icons'
import {dateParser} from '../services/utils';
import Message from '../components/Message';
import { faSpeakerDeck } from '@fortawesome/free-brands-svg-icons'
import {setProfileId} from '../feature/profileSlice';
import {setNavigation} from "../feature/navigationSlice";

function NotificationCard({usersArray,inf, posts,channels, searchValue}) {
    const[ userInfo, setUserInfo] = useState({});
    const[ postObj, setPostObj] = useState({});

    const [searchState, setSearchState] = useState("");
    useEffect(() => {
        const o = posts?.find(post => post?.post._id === inf.postId);
        if(o !== undefined){setPostObj(o);}

        const p = channels?.find(ch => ch?._id === inf.postId);
        if(p !== undefined){setPostObj(p);}

        const a = usersArray?.find(user => user?._id === inf.emitterId);
        if (a){setUserInfo(a);}
    }, []);
    const dispatch = useDispatch();


    useEffect(() => {
        setSearchState(searchValue);
    }, [searchValue]);

    const SwitchToProfilePage = async (ProfileId) => {
        dispatch(setNavigation('#userProfile'));
        dispatch(setProfileId(ProfileId));
    };
    return(
        <>
        { Object.keys(userInfo)?.length > 0 && <>
            { (userInfo?.prenom.toLowerCase().includes(searchState.toLowerCase())  ||  userInfo?.nom.toLowerCase().includes(searchState.toLowerCase())  ||  userInfo?.pseudo.toLowerCase().includes(searchState.toLowerCase())  ) && <>
            <Fade>
            <div className= {inf.seen === true ? ("notification_card") : ("notification_card unseen")}>
                <div className="top_notif">
                    <div className='parent_notif' onClick={() => (SwitchToProfilePage(userInfo?._id))}>
                        <img src={userInfo?.imageUrl} alt="" />
       
                        <div className='mid_notif'>
                            <h2>{userInfo?.prenom} {userInfo?.nom} 
                            {userInfo?.mediumBadge && <> <FontAwesomeIcon style={{color: "gold"}} icon={faBolt}/> </>}
                            {userInfo?.nitroBadge && <>  <FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}/> </>}
                            {userInfo?.verified && <>  <FontAwesomeIcon style={{color: "#1da1f2"}} icon={faCircleCheck}/> </>}
                            
                            </h2>
                            <h3>@{userInfo?.pseudo}</h3>
                     
                            <h5 style={{color : "#1da1f2", marginLeft: "3px"}}> {dateParser(new Date(inf.timestamp), 7)}
                            {!inf.seen && <>•</>}
                            </h5>


                        </div>

                    </div>
                
                    
                    {(() => {
                            switch (inf.notificationType) {
                                case 'Like':  return <> <div className="notif_icon" style={{"backgroundColor" : "#ff5757"}}> <p ><FontAwesomeIcon icon={faHeart} /> </p></div> </>
                                case 'Retweet': return <><div className="notif_icon"  style={{"backgroundColor" : "#4794ff"}}> <p  ><FontAwesomeIcon icon={faRetweet} /> </p></div></>
                                case 'Follow': return <> <div className="notif_icon"  style={{"backgroundColor" : "#06141d"}}><p ><FontAwesomeIcon icon={faUser} /> </p></div> </>
                                case 'Comment': return <> <div className="notif_icon"  style={{"backgroundColor" : "#8a27db"}}><p ><FontAwesomeIcon icon={faComment} /> </p></div></>
                                case 'Mention': return <> <div className="notif_icon"  style={{"backgroundColor" : "rgb(12, 201, 201)"}}><p ><FontAwesomeIcon icon={faAt} /> </p></div></>
                                case 'ChannelInvite': return <> <div className="notif_icon"  style={{"backgroundColor" : "rgb(40, 166, 56)"}}><p ><FontAwesomeIcon icon={faShareFromSquare} /> </p></div></>
                                case 'RoleAttribution': return <> <div className="notif_icon"  style={{"backgroundColor" : "rgb(255, 184, 53)"}}><p ><FontAwesomeIcon icon={faGraduationCap} /> </p></div></>
                                case 'RoleDestitution': return <> <div className="notif_icon"  style={{"backgroundColor" : "#84a5bf"}}><p ><FontAwesomeIcon icon={faHandPointDown} /> </p></div></>
                                
                            }
                        })()}
                </div>

                <div className="bottom_notif">
                    <h4>{(() => {
                            switch (inf.notificationType) {
                                case 'Like': return <> {!inf.seen && <><FontAwesomeIcon icon={faQuoteLeft} style={{"color": "#1da1f2"}}/></>}  <strong> {userInfo?.prenom} {userInfo?.nom} </strong>Liked your post {!inf.seen && <><FontAwesomeIcon icon={faQuoteLeft} style={{"color": "#1da1f2"}}/></>}  <br/> <small> {postObj?.post?.description && <> “{postObj?.post?.description}”</>} </small>  </>
                                case 'Retweet': return <> {!inf.seen && <><FontAwesomeIcon icon={faQuoteLeft} style={{"color": "#1da1f2"}}/></>}   <strong> {userInfo?.prenom} {userInfo?.nom}</strong> Retweeted your post  {!inf.seen && <><FontAwesomeIcon icon={faQuoteLeft} style={{"color": "#1da1f2"}}/></>}  <br/> <small> {postObj?.post?.description && <> “{postObj?.post?.description}”</>}</small> </>
                                case 'Follow': return <> {!inf.seen && <><FontAwesomeIcon icon={faQuoteLeft} style={{"color": "#1da1f2"}}/></>}  <strong>{userInfo?.prenom} {userInfo?.nom}  </strong> Followed You  {!inf.seen && <><FontAwesomeIcon icon={faQuoteLeft} style={{"color": "#1da1f2"}}/></>}  </>
                                case 'Comment': return<> {!inf.seen && <><FontAwesomeIcon icon={faQuoteLeft} style={{"color": "#1da1f2"}}/></>}  <strong>{userInfo?.prenom} {userInfo?.nom}</strong> left a Comment on your post  {!inf.seen && <><FontAwesomeIcon icon={faQuoteLeft} style={{"color": "#1da1f2"}}/></>}   <br/> <small> {postObj?.post?.description && <> “{postObj?.post?.description}”</>}</small>  </>
                                case 'Mention': return<> {!inf.seen && <><FontAwesomeIcon icon={faQuoteLeft} style={{"color": "#1da1f2"}}/></>}  <strong>{userInfo?.prenom} {userInfo?.nom}</strong> mentionned you @  {!inf.seen && <><FontAwesomeIcon icon={faQuoteLeft} style={{"color": "#1da1f2"}}/></>}   <br/> <small> {postObj?.post?.description && <> “{postObj?.post?.description}”</>}</small>  </>
                                case 'ChannelInvite': return<> {!inf.seen && <><FontAwesomeIcon icon={faQuoteLeft} style={{"color": "#1da1f2"}}/></>}  <strong>{userInfo?.prenom} {userInfo?.nom}</strong> has invited you in <strong>{postObj?.channelName}   </strong> <FontAwesomeIcon style={{marginLeft: "5px"}} icon={faEnvelope} /> {!inf.seen && <><FontAwesomeIcon icon={faQuoteLeft} style={{"color": "#1da1f2"}}/></>}   </>
                                case 'RoleAttribution': return<> {!inf.seen && <><FontAwesomeIcon icon={faQuoteLeft} style={{"color": "#1da1f2"}}/></>}  <strong>{userInfo?.prenom} {userInfo?.nom}</strong> attributed you a New Role ! <strong>{inf.postDescription.split(' ')[0]}  </strong> {!inf.seen && <><FontAwesomeIcon icon={faQuoteLeft} style={{"color": "#1da1f2"}}/></>}   </>
                                case 'RoleDestitution': return<> {!inf.seen && <><FontAwesomeIcon icon={faQuoteLeft} style={{"color": "#1da1f2"}}/></>}  <strong>{userInfo?.prenom} {userInfo?.nom}</strong> has destitued your role to <strong>Member</strong>. {!inf.seen && <><FontAwesomeIcon icon={faQuoteLeft} style={{"color": "#1da1f2"}}/></>} </>

                            }
                    })()}
                     
                    </h4>
                    {(inf.notificationType === "RoleDestitution" || inf.notificationType === "RoleAttribution" ) && <>
                        <h6 className="h7">
                            {inf.notificationType === "RoleAttribution" ? <>
                                <FontAwesomeIcon style={{color : inf.postDescription.split(' ')[1], fontSize: "11px", marginRight: '3px' }} icon={faCircle}/> {inf.postDescription.split(' ')[0]}
                            </> : <>
                                <FontAwesomeIcon style={{color : "#84a5bf", fontSize: "11px", marginRight: '3px' }} icon={faCircle}/> Member
                            </>}
                        </h6>
                     </>}


                    {(inf.notificationType === "Like" || inf.notificationType === "Retweet"  || inf.notificationType === "Comment" || inf.notificationType === "Mention") && <>
                                         
                            { Object.keys(postObj)?.length > 0 ? <>
                                <div className="messageComponent">
                                    <Message Message_={postObj} />
                                </div>
                            </> : <>
                                <h4> <small> {inf.notificationType === "Mention" ? <>{userInfo?.prenom} {userInfo?.nom} </> : <>You</>} deleted this post.</small> </h4>
                            </>}
                    </>}

                    {(inf.notificationType === "RoleDestitution" || inf.notificationType === "RoleAttribution"  || inf.notificationType === "ChannelInvite") && <>
                            <div className="channelComponent">
                                <img className='bnrChnl' src={postObj.channelBanner} alt="" />
                                <img className='pfpChnl' src={postObj.channelPicture} alt="" />
                                <h1>{postObj.channelName}</h1>
                                <h2>{postObj.private ? <>Private</> : <>Public</>} Community Channel</h2>
                            </div>
                    </>}

                </div>
            </div>
            </Fade>                 
            </>}
        </>}
    </>
    )
}
function UserNotifications() {
    const [notifMap, setNotifMap] = useState({});
    const unSeenCount = useSelector((state) => state.navigation.notifCount);
    const [newest, setSortNewest] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [userData, setUserData] = useState([]);

    const [searchState, setSearchState] = useState("");
    const [filterState, setFilterState] = useState("");
    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
    );
      
    useEffect(() => {
        getNotifications();
    }, []);

    useEffect(() => {
        (async () => {
            setLoaded(false);
            await delay(50);
            setLoaded(true);
        })();
    }, [newest]);

    const getNotifications = async () => {
        const e =  await notifications(cookies.get("userId"));
        setNotifMap(e);
        //    const newUsers=  [];
        //    await Promise.all(e.userNotifications.map(async (notif, index) => {
        //         const indx = newUsers.findIndex((usr) => usr === notif.emitterId);
        //         if (indx === -1){
        //             newUsers.push(notif.emitterId);
        //         }
        //    }));
        //    const newUsrs_data = [];
        //    await Promise.all(newUsers.map(async (id, index) => {
        //         const data = await notifUserInfo(id);
        //         if (data.getUser){newUsrs_data.push(data.getUser);}
        //    }));
        setUserData(e.usersData_);
        setLoaded(true);
        await delay(1000);
        markAsSeen(cookies.get("userId"));
    }

    return (
        <div className='notifications_map'>
            <div className="titleDiv">
                    <h1 className='titlee'>Notifications</h1>

                <Fade top >
                    <div className="searchBar_">
                            <input onChange={(e) => (setSearchState(e.target.value))} type="text" placeholder='Search...' maxLength={32} />
                            <p> <FontAwesomeIcon icon={faMagnifyingGlass} /> </p>
                    </div>
                </Fade>
            </div>
         
            <div className="options">
                <div className='opt_row'>
                   <h2 className={filterState === "" && 'activated'} onClick={() => (setFilterState(''))}>Inbox  {unSeenCount > 0  && <><span>{unSeenCount}</span></>} </h2>
                   <h2 className={filterState === "Like" && 'activated'} onClick={() => (setFilterState('Like'))}>Likes </h2>
                   <h2 className={filterState === "Comment" && 'activated'} onClick={() => (setFilterState('Comment'))}>Comments </h2>
                   <h2 className={filterState === "Follow" && 'activated'} onClick={() => (setFilterState('Follow'))}>Follows </h2>
                   <h2 className={filterState === "Mention" && 'activated'} onClick={() => (setFilterState('Mention'))}>Mentions </h2>
                </div>
                <div>
                    <h2 onClick={() => (setSortNewest(!newest))} className="sortbtn">{newest === true ? (<> <FontAwesomeIcon icon={faArrowDownShortWide} /></>) : (<> <FontAwesomeIcon icon={faArrowUpWideShort} /></>)} </h2>
                </div>
            </div>

            {notifMap?.userNotifications?.length == 0 ? <>
                <div className="noBookmarks">
                        <Fade top>
                            <img src={require('../media/notifications.jpeg')} alt="" />
                            <h2>Nothing to see here. For the moment.</h2>
                            <h3>From likes to retweets without forgetting <br/> the rest, that's here where it all happens.</h3>
                        </Fade>
                </div>
            </> : <>
            {loaded ? <>
                    {newest === true ? (<>
                        {notifMap?.userNotifications?.map((eachNotification, index) => (<>
                                {eachNotification?.notificationType?.includes(filterState)  && <>
                                
                                    <NotificationCard searchValue={searchState} usersArray={userData} inf={eachNotification} channels={notifMap?.notificationsChannels} posts={notifMap?.notificationsPosts}/>
                                </>}
                        </>)).reverse()}
                    </>): (<>
                        {notifMap?.userNotifications?.map((eachNotification, index) => (<>
                            {eachNotification?.notificationType?.includes(filterState)  && <>
                                <NotificationCard searchValue={searchState} usersArray={userData} inf={eachNotification} channels={notifMap?.notificationsChannels} posts={notifMap?.notificationsPosts}/>
                            </>}
                        </>))}
                    </>)}
                </> : <>
                    <div className="lds-dual-ring"></div>
                </>}
            </>}
        </div>
    )
}

export default UserNotifications