import {useEffect, useState, useRef, createRef} from 'react'
import '../styles/components/channelSettings.scss'
import Fade from 'react-reveal/Fade';
import {editChannel, editChannelPicture, editRole, createRole, assignRole, unbanMember} from '../services/ChannelServices';
import {updateChannels} from '../feature/channelSlice';
import {notifUserInfo} from '../services/UserServices';
import cookies from 'js-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faFileArrowUp, faCheck, faUpload, faCloudArrowUp, faUserLarge, faPencil,faUserGroup, faTriangleExclamation,faGavel,faHandsHoldingChild,
    faArrowLeft, faPlus, faPaintRoller, faCircle, faCrown, faUserAstronaut, faScrewdriverWrench, faLock, faSquareUpRight, faRightToBracket, faBan, faArrowUpFromBracket, faXmark} from '@fortawesome/free-solid-svg-icons'
import io from 'socket.io-client';
import {TwitterPicker} from 'react-color'
import { useSelector, useDispatch} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const channelSocket = io(process.env.REACT_APP_API_SOCKET_CHANNEL, {
    withCredentials: true,
    autoConnect: true,
    transports : ['websocket']
}); // the Chhannels namepsace

function BannishList({bannedUsrs, channelId}){
    const [datas, setUserDatas] = useState([]);

    useEffect(() => {
        (async () => {
            const newArr= [];
            await Promise.all(bannedUsrs.map(async (user, index) => {
                const i = await notifUserInfo(user);
                newArr.push(i.getUser);
            }));
            setUserDatas(newArr);
        })();
    }, [bannedUsrs]);

    const unBan = async (id) => {
        const e = await unbanMember(channelId, {"memberId" : id});
        if(e.status === 201){
            channelSocket.emit("channelUpdate", {
                channelId: channelId
            }); 
        }
    }
    return(<>
        {datas?.map((user, index) => (<>
            <div className="banRow">
                <div>
                    <img src={user.imageUrl} alt="" />
                    <h2>{user.prenom} {user.nom}  <span>@{user.pseudo}</span></h2>
                </div>
                <div>
                    <h2 onClick={() => (unBan(user.id))} className='unbn'>UNBAN <FontAwesomeIcon icon={faGavel}/></h2>
                </div>
            </div>
        </>))}
    </>)
}

function ChannelSettings({channelData, usersData}) {
    const pfpInput = useRef(null);
    const bannerInput = useRef(null);

    const [channelDescription, setChannelDescription] = useState('');
    const [channelName, setChannelName] = useState('');
    const [privateChannel, setPrivateChannel] = useState(false);

    const [roleCreation, setRoleCreation] = useState(false);
    const [selectedRole, setSelectedRole] = useState({});

    const [hexColor, setHexColor] = useState('');

    const ownRole = useSelector((state) => state.channel.channelRole);

    const [rolesMembersCount, setRolesMembersCount] = useState({});
    const [idList, setIdList] = useState([]);

    const [searchRole, setSearchRole] = useState('');
    const [searchMemberRole, setSearchMemberRole] = useState('');

    const dispatch = useDispatch();
    const notify = (text) => toast.success(text, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    useEffect(() => {
        channelSocket.auth = {channelId: channelData._id,
            userId: cookies.get('userId'),
            jwt: cookies.get('jwt')
        }
        channelSocket.connect();
    }, []);

    useEffect(() => {
        setChannelDescription(channelData.channelDescription);
        setChannelName(channelData.channelName);
        setPrivateChannel(channelData.private);
        switchRole(channelData?.channelRoles[0]._id);

        (async() => {
            var updatedCount = {... rolesMembersCount};
            updatedCount = {};
            await Promise.all(channelData.usersIds.map((user, index) => {
                if (updatedCount.hasOwnProperty([user.roles])){
                    updatedCount[user.roles] = updatedCount[user.roles] + 1;
                }else{
                    updatedCount[user.roles] = 1;
                }
            }));
            channelData.channelRoles.map((role, index) => {
                if (!updatedCount.hasOwnProperty([role.name])){
                    updatedCount[role.name] = 0;
                }
            })
            setRolesMembersCount(updatedCount);
        })();

        (() => {
            usersData?.map((user, index) => {
                const user_role = channelData.usersIds.find(channelUser => channelUser.userId === user.id);
                user["roles"] = user_role?.roles;
            })
        })();
    }, [channelData]);


    const [delayEdit, setDelayEdit] = useState(false);
    const edtChannel = async () => {
        if (!delayEdit){
            if (channelName.length >= 3 && channelDescription.length >= 3){
                setDelayEdit(true);
                const e = await editChannel(channelData._id ,{
                    channelName: channelName,
                    channelDescription: channelDescription,
                    channelPrivate: privateChannel,
                });
                setDelayEdit(false);
                if (e.status === 200){
                    notify("Channel Informations Updated");
                    channelSocket.emit("channelUpdate", {
                        channelId: channelData._id
                    }); 
                }
            }
        }
    }

    const edtPhoto = async (newPhoto, isBanner) => {
        var APIFormMessage = new FormData();
        if (isBanner===true){        
            APIFormMessage.append('isBanner', isBanner);
            APIFormMessage.append('image', newPhoto,  cookies.get('userId') + "_banner_" + newPhoto.name);
        }else{
            APIFormMessage.append('image', newPhoto,  cookies.get('userId') + "_picture_" + newPhoto.name);
        }
        const photoUpdated =  await  editChannelPicture(channelData._id, APIFormMessage);
        console.log(photoUpdated);
        if (photoUpdated.status === 201){
            channelSocket.emit("channelUpdate", { channelId: channelData._id }); 
            if (isBanner){
                notify("Channel Banner Updated");
            }else{
                notify("Channel Picture Updated");
            }
        }
    }

    const switchRole = (role_id) => {
        setIdList('');
        const role_ = (channelData.channelRoles.find(role => role._id === role_id  ));
        setSelectedRole(role_);
        setHexColor(role_.color);
    }

    const optionSwitch = (option) => {
        const updatedRole = {... selectedRole}
        updatedRole[option] = !selectedRole[option];
        setSelectedRole(updatedRole);
    }
    const nameSwitch = (name) => {
        const updatedRole = {... selectedRole}
        updatedRole["name"] = name;
        setSelectedRole(updatedRole);
    }
    const saveEditRole = async () => {
        selectedRole.color = hexColor;
        const roleUpdated =  await editRole(channelData._id, {roleData: selectedRole});
        if (roleUpdated.status === 201){
            channelSocket.emit("channelUpdate", {
                channelId: channelData._id
            }); 
        } 
    }
    const createRoleFunc = async () => {
        const roleUpdated =  await createRole(channelData._id);
        if (roleUpdated.status === 201){
            channelSocket.emit("channelUpdate", { channelId: channelData._id}); 
            setRoleCreation(true);
        } 
    }

    const assignNewMembers = async () => {
        const data =  {
            roleToAdd: selectedRole.name,
            userToAdds: idList
        }
        const i =  await assignRole(channelData._id, data);
         if (i.status === 201){
            channelSocket.emit("channelUpdate", { channelId: channelData._id}); 
            setRoleCreation(false);
            dispatch(updateChannels());
        } 
    }
    const addTo = async (user_id, user_role) => {
        const indx = idList.indexOf(user_id);
        setSearchRole("");
        if (indx === -1 && user_role !== "admin" && ((user_role === "member"  && selectedRole?.name === "member") !== true)){
            //if (idList.length < 7){
                setIdList([...idList, user_id]);
            //}
        }else{
            setIdList(idList.filter(prevId => prevId !== user_id));              
        }
    };

    return (
        <div className='settingsWindow'>
            <h1>Overwiew of the channel</h1>

            <Fade bottom>
                <div className="settings_top">
                    
                    <div className='clmn lftcolm'>
                        <div className="rw imgs">
                            <input ref={pfpInput}  className='pic_upload' type="file" accept="image/*" onChange={e => edtPhoto(e.target.files[0], false)} />
                            <img className='channelPic' src={channelData.channelPicture} alt="" />
                            <strong><FontAwesomeIcon icon={faFileArrowUp}/> </strong>
                            <small>DELETE</small>
                        </div>
                        <div className="rw">
                            <h2 className='w'>CHANNEL PICTURE</h2>
                            <p>We recommand at least an image of 128 x 128 size for the Channel Picture.</p>
                            {ownRole.canEditPictures ? <>
                                <button onClick={() => (pfpInput.current.click())}>Upload an image <FontAwesomeIcon icon={faCloudArrowUp}/></button>
                            </> : <>
                                <button >Upload an image <FontAwesomeIcon icon={faLock}/></button>
                            </>}
                        </div>
                      
                    </div>
        
                    <div className='clmn rght'>
                        <h2>NAME OF THE CHANNEL</h2>
                        {ownRole.canEdit ? <>
                            <input type="text" defaultValue={channelData.channelName}  maxLength={24}  onChange={e => setChannelName(e.target.value)} />
                        </> : <>
                            <input type="text" value={channelData.channelName}/>
                        </>}

                        <h2>DESCRIPTION OF THE CHANNEL</h2>
                        {ownRole.canEdit ?<>
                            <input type="text" defaultValue={channelData.channelDescription}  maxLength={256} onChange={e => setChannelDescription(e.target.value)}/>
                        </> : <>
                            <input type="text" value={channelData.channelDescription}/>
                        </>}

                        <div className="saveRow">
                            <h2>PRIVATE CHANNEL</h2>

                        

                            {ownRole.canEdit ? <>
                                <div onClick={() => (setPrivateChannel(!privateChannel))} class={privateChannel=== false ? "switch" : "switch clicked"}>
                                    <div className='rd'></div>
                                </div>

                                <button className='save' onClick={() => (edtChannel())}>Save Modifications <FontAwesomeIcon icon={faCheck}/> </button>
                            </>: <>
                                <div class={privateChannel=== false ? "switch" : "switch clicked"}>
                                    <div className='rd'></div>
                                </div>
                                <button className='save'>Save Modifications <FontAwesomeIcon icon={faLock}/> </button>
                            </>}
                           
                        </div>
                    
                    </div>
        
                </div>
            </Fade>  
            
            <Fade bottom>
                <div className="settings_mid">
                    <div className="bannerClmn">
                        <h2>CHANNEL BANNER</h2>
                        <input  ref={bannerInput} className='pic_upload' type="file" accept="image/*" onChange={e => edtPhoto(e.target.files[0], true)} />
                        <img className='channelBnr' src={channelData.channelBanner} alt="" />
                        <p className='bannerDesc'>We recommand at least an image of 728 x 364 size for the Channel Banner.</p>
                        {ownRole.canEditPictures ? <>
                            <button className='bannerBtn' onClick={() => (bannerInput.current.click())}>UPLOAD A LARGE IMAGE <FontAwesomeIcon icon={faUpload}/></button>
                        </> : <>
                            <button className='bannerBtn' >UPLOAD A LARGE IMAGE <FontAwesomeIcon icon={faLock}/></button>
                        </>}
                    </div>
                        
                </div>
            </Fade>


            <Fade bottom>
                <div className="settings_bot">

                        {roleCreation === true ? <>

                            <Fade left>
                                <div className="roleCreation">
                                    <div className="menu">
                                        <div className="return_role">
                                            <h2 onClick={() => (setRoleCreation(false))}><FontAwesomeIcon icon={faArrowLeft}/> RETURN</h2>
                                            <h2 onClick={()=> (createRoleFunc())}><FontAwesomeIcon icon={faPlus}/></h2>
                                        </div>

                                        {channelData.channelRoles.map((role, index) => (<>
                                                <h5 className={role._id === selectedRole._id && 'selectedRole'} onClick={() => (switchRole(role._id))}> <FontAwesomeIcon style={{ color: role.color }} icon={faCircle}/>  {role.name} </h5>
                                        </>))}
                                    </div>

                                    <div className="displayRole">
                                        {selectedRole?.name === "admin" && <><small className="note">Admin Role cant be Assigned or Modified.</small></>}

                                        <div className="setting">
                                            <h4>NAME OF THE ROLE</h4> 
                                            <input type="text" defaultValue={selectedRole?.name} value={selectedRole?.name} onChange={(e) => (nameSwitch(e.target.value))}  />
                                        </div>
                            
                                        <div className="setting">
                                            <h4  className='colorStg' style={{ borderColor: hexColor }}>COLOR OF THE ROLE</h4> 
                                            <div className="colorPickers">
                                                <div className="actualColor" style={{ backgroundColor: hexColor }} > <p><FontAwesomeIcon icon={faPaintRoller}/> </p> </div>
                                                <div className="defaultColor" onClick={() => (setHexColor("#84a5bf"))}> <p>default</p></div>
                                                <TwitterPicker className='issou' width='70%' colors={['#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', 
                                                '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF', '#24ffbd', '#1939bd','#ffd000', '#FF00A1', '#E476FF']} triangle={"hide"} onChange={(color) => (setHexColor(color.hex))}/>
                                            </div>
                                            <div className="hexClr">
                                                <h4># HEX COLOR</h4>
                                                <input type="text" min={1} defaultValue={hexColor} value={hexColor} maxLength={7}  onChange={(e) => (setHexColor(e.target.value))} />
                                            </div>
                                        </div>

                                        <div className="setting">
                                            <h4>GENERAL PERMISSIONS ON THE CHANNEL</h4> 

                                            <div className="perm">
                                                <div className='setting_rw'>
                                                    <p>View Channel Rooms</p>
                                                    <div onClick={() => (optionSwitch("canViewRooms"))} class={selectedRole?.canViewRooms === false ? "switch" : "switch clicked"}>
                                                            <div className='rd'></div>
                                                    </div>                                                
                                                </div>
                                                    <small>Allow members to see members that have joined Facecalls and Vocal Rooms.</small>
                                            </div>



                                            <div className="perm">
                                                <div className='setting_rw'>
                                                    <p>Edit Channel</p>
                                                    <div onClick={() => (optionSwitch("canEdit"))} class={selectedRole?.canEdit === false ? "switch" : "switch clicked"}>
                                                        <div className='rd'></div>
                                                    </div>                                                
                                                </div>
                                                    <small>Allow members to change channel Name and Description. Members will also have the right to change the channel from Public to Private.</small>
                                            </div>

                                            <div className="perm">
                                                <div className='setting_rw'>
                                                    <p>Personnalize Channel</p>
                                                    <div onClick={() => (optionSwitch("canEditPictures"))} class={selectedRole?.canEditPictures === false ? "switch" : "switch clicked"}>
                                                        <div className='rd'></div>
                                                    </div>                                                
                                                </div>
                                                    <small>Allow members to personnalize and change the Picture and Banner of this channel.</small>
                                            </div>

                                            <div className="perm">
                                                <div className='setting_rw'>
                                                    <p>Pin Messages</p>
                                                    <div onClick={() => (optionSwitch("canPin"))} class={selectedRole?.canPin === false ? "switch" : "switch clicked"}>
                                                        <div className='rd'></div>
                                                    </div>                                                
                                                </div>
                                                    <small>Allow members to add or remove pin messages from the textual chat of this channel.</small>
                                            </div>
                                        </div> 


                                        <div className="setting">
                                            <h4>MEMBERS PERMISSIONS</h4> 


                                            <div className="perm">
                                                <div className='setting_rw'>
                                                    <p>Invite Friends</p>
                                                    <div onClick={() => (optionSwitch("canInvite"))} class={selectedRole?.canInvite === false ? "switch" : "switch clicked"}>
                                                        <div className='rd'></div>
                                                    </div>                                                
                                                </div>
                                                    <small>Allow members to invite new peoples on this channel.</small>
                                            </div>


                                            <div className="perm">
                                                <div className='setting_rw'>
                                                    <p>Expulse Members</p>
                                                    <div onClick={() => (optionSwitch("canKick"))} class={selectedRole?.canKick === false ? "switch" : "switch clicked"}>
                                                        <div className='rd'></div>
                                                    </div>                                                
                                                </div>
                                                <small>Allow members to delete other members from this channel. The members kicked will be able to join the channel again.</small>
                                            </div>

                                            <div className="perm">
                                                <div className='setting_rw'>
                                                    <p>Ban Members</p>
                                                    <div onClick={() => (optionSwitch("canBan"))} class={selectedRole?.canBan === false ? "switch" : "switch clicked"}>
                                                        <div className='rd'></div>
                                                    </div>                                                
                                                </div>
                                                    <small>Allow members to definitively banish other members of this channel.</small>
                                            </div>

                                            <div className="perm">
                                                <div className='setting_rw'>
                                                    <p>Roles Administration</p>
                                                    <div onClick={() => (optionSwitch("canEditRoles"))} class={selectedRole?.canEditRoles === false ? "switch" : "switch clicked"}>
                                                        <div className='rd'></div>
                                                    </div>                                                
                                                </div>
                                                    <small> <FontAwesomeIcon  style={{ marginLeft: "5px" }}  icon={faTriangleExclamation}/>  Allow members to create new roles and modify existing roles on this channel, and allow them to also assign those roles to members on the channel.</small>
                                            </div>
                                        </div> 

                                        <div className="setting">
                                            <h4>CHANNEL ROOMS PERMISSIONS</h4> 

                                            <div className="perm">
                                                <div className='setting_rw'>
                                                    <p>Join Vocal Room</p>
                                                    <div onClick={() => (optionSwitch("canVocal"))} class={selectedRole?.canVocal === false ? "switch" : "switch clicked"}>
                                                        <div className='rd'></div>
                                                    </div>                                                
                                                </div>
                                                    <small>Allow members to join vocal room and talk with other people on this channel.</small>
                                            </div>

                                            <div className="perm">
                                                <div className='setting_rw'>
                                                    <p>Join Camera Room</p>
                                                    <div onClick={() => (optionSwitch("canFacecam"))} class={selectedRole?.canFacecam === false ? "switch" : "switch clicked"}>
                                                        <div className='rd'></div>
                                                    </div>                                                
                                                </div>
                                                    <small>Allow members to join facecall room and talk with other people on this channel.</small>
                                            </div>

                                            <div className="perm">
                                                <div className='setting_rw'>
                                                    <p>Textual Chat</p>
                                                    <div onClick={() => (optionSwitch("canTextualChat"))} class={selectedRole?.canTextualChat === false ? "switch" : "switch clicked"}>
                                                        <div className='rd'></div>
                                                    </div>                                                
                                                </div>
                                                    <small>Allow members to chat and communicate on textual room with other people on this channel.</small>
                                            </div>
                                        </div> 

                                        <button onClick={() => (saveEditRole())} className='saveChannelSettings'>SAVE ROLE MODIFICATIONS <FontAwesomeIcon  style={{ marginLeft: "5px" }}  icon={faScrewdriverWrench}/> </button>
                                            
                                        { ((selectedRole?.name === "member") || (selectedRole?.name === "admin")) && <><small className="note">Please Note that Name and Color of Default Member role cant be modified.</small></>}
                                        {selectedRole?.name !== "admin" && <>
                                        <Fade>
                                            <div className="setting add_setting">
                                                <h2 className='new_mbmr'> Role Members Attribution</h2>

                                                <div className="add_rw">
                                                    <input type="text" placeholder='Search for members' onChange={e => setSearchMemberRole(e.target.value)} />
                                                    <button onClick={() => (assignNewMembers())}>ASSIGN ROLES <FontAwesomeIcon icon={faHandsHoldingChild}/></button>
                                                </div>

                                                <p>Members can only have one role, when you assign them a new role,  their previous role will be demoted.</p>

                                                <div className="added_members">
                                                    {usersData.map((user, index) => (<>
                                                        {user.roles !== selectedRole?.name && <>
                                                            {idList.includes(user.id) === true &&  <>
                                                                <small onClick={() => (addTo(user.id, user.roles))}> {user.prenom} {user.nom}  <FontAwesomeIcon icon={faPlus}/></small>
                                                            </>}
                                                        </>}
                                                    </>))}
                                                </div>

                                                <div className="added_members deleted">
                                                    {usersData.map((user, index) => (<>
                                                        {user.roles === selectedRole?.name && <>
                                                            {idList.includes(user.id) === true &&  <>
                                                                <small onClick={() => (addTo(user.id, user.roles))}> {user.prenom} {user.nom} <FontAwesomeIcon icon={faBan}/></small>
                                                            </>}
                                                        </>}
                                                    
                                                    </>))}
                                                </div>

                                                {usersData.map((user, index) => (
                                                    <>
                                                    {idList.includes(user.id) === false &&  <>
                                                        {searchMemberRole.length > 0 ? <>
                                                            { ((user.nom.toLowerCase().includes(searchMemberRole.toLowerCase())) ||  (user.prenom.toLowerCase().includes(searchMemberRole.toLowerCase())) ) && <>
                                                            <div className="add_user_row" onClick={() => (addTo(user.id, user.roles))}>
                                                                <div>
                                                                    <img src={user.imageUrl} alt="" />
                                                                    <p> <span>{user.prenom} {user.nom}</span> @{user.pseudo}</p>
                                                                </div>
                                                                <div>
                                                                    {user.roles === selectedRole?.name ? <>
                                                                        <small> Remove <FontAwesomeIcon icon={faXmark}/> </small>
                                                                    </> : <>
                                                                        <small> Assign <FontAwesomeIcon icon={faArrowUpFromBracket}/> </small>
                                                                    </>}
                                                                </div>
                                                            </div>
                                                        </>}
                                                        </>: <>
                                                            <div className="add_user_row" onClick={() => (addTo(user.id, user.roles))}>
                                                                <div>
                                                                    <img src={user.imageUrl} alt="" />
                                                                    <p> <span>{user.prenom} {user.nom}</span> @{user.pseudo}</p>
                                                                </div>
                                                                <div>
                                                                    {user.roles === selectedRole?.name ? <>
                                                                        <small> Remove  <FontAwesomeIcon icon={faXmark}/> </small>
                                                                    </> : <>
                                                                        {user.roles === "admin" ?<>
                                                                            <small> ADMIN  <FontAwesomeIcon icon={faCrown}/> </small>
                                                                        </> : <>
                                                                            <small> Assign  <FontAwesomeIcon icon={faArrowUpFromBracket}/> </small>
                                                                        </>}
                                                                    </>}
                                                                </div>
                                                            </div>
                                                        </>}
                                                    </>}
                                                </>
                                                ))}
                                            </div>
                                        </Fade>
                                        </>}
                        
                                    </div>
                                </div>
                                
                            </Fade>

                        </> : <>

                            <Fade right>
                                <h2>CHANNEL ROLES</h2>
                                <p className='ttle'>Use the roles to regroup members of your channel and to assign them permissions.</p>

                                <div className="defaultRole" onClick={() => {switchRole(channelData.channelRoles[1]._id); setRoleCreation(true)}}>
                                    <div className='rww'>
                                        <h4><FontAwesomeIcon icon={faUserGroup}/></h4>
                                        <h2>Default Permissions <br/><span> @everyone • Apply to all new members of the channel </span></h2>
                                    </div>
                                    <div className='rww'>
                                        <p><FontAwesomeIcon icon={faRightToBracket}/></p>
                                    </div>
                                </div>

                                <div className="searchRole">
                                    <input type="text" placeholder='Reasearch some roles' onChange={e => setSearchRole(e.target.value)}  />
                                    <>
                                    {!ownRole.canEditRoles ? <>  <button> Create a role  <FontAwesomeIcon icon={faLock}/></button> </>: <>
                                        <button onClick={() => (createRoleFunc())}> Create a role <FontAwesomeIcon icon={faSquareUpRight}/>  </button>
                                    </>}  
                                    </>
                                </div>

                                <div className="role_row mx">
                                    <h2 className='h2title'>ROLES - {channelData.channelRoles.length}</h2>
                                    <h2 className='h2title one'>MEMBERS</h2>
                                    <h2 className='h2title'></h2>
                                </div>
                                {channelData.channelRoles.map((role, index) => (<>
                                    <div className="role_row">
                                        <span  style={{color: role.color }} className='roleName'><FontAwesomeIcon icon={faUserAstronaut}/> {index === 0 && <><FontAwesomeIcon icon={faCrown}/> </> }{role.name}</span>
                                        <p className='roleCount'>{rolesMembersCount[role.name]} <FontAwesomeIcon icon={faUserLarge}/></p>
                                        <p onClick={() => (switchRole(role._id))} className='eye'>{ownRole.canEditRoles ? <><FontAwesomeIcon onClick={() => (setRoleCreation(true))} icon={faPencil}/></> : <><FontAwesomeIcon icon={faLock}/></>} </p>
                                    </div>
                                </>))}
                            </Fade>
                        </>}
                
                </div>

                <div className="settings_bot">
                    <div className="ban_row">
                        <h2>CHANNEL BANISHMENTS</h2> 

                        {ownRole.canBan ? <>
                            {channelData.bannedUsers.length === 0 ? <>
                                <div className="ban_illustration">
                                    <img  src={require('../media/bannishments.png')} alt="" />
                                    <p>NO BANISHMENTS</p>
                                    <h4>No one has been banned yet... but <br/> if you ever have to bannish someone, you must <br/> not hesitate for a single second !</h4>
                                </div>
                            </> : <>
                                <p>{channelData.bannedUsers.length} ACTIVE BANISHMENTS</p>
                                <div className="ban_list">
                                    <>  
                                    <BannishList channelId={channelData._id} bannedUsrs={channelData.bannedUsers} />
                                    </>
                                </div>
                            </>}
                        </> : <>                           
                            <div className="ban_illustration">
                                    <img  src={require('../media/bannishments.png')} alt="" />
                                    <p>YOU DON'T OWN ANY RIGHTS ON BANISHMENTS</p>
                                    <h4>You don't have the rights to bannish members..<br/>but if you ever have to bannish someone, you must <br/> not hesitate for a single second !</h4>
                                </div>
                        </>}     
                    </div>
                </div>
            </Fade>

        </div>
    )
}

export default ChannelSettings
