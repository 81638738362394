import Spline from '@splinetool/react-spline';
import {useEffect, useState, useRef, createRef} from 'react'
import io from 'socket.io-client';
import '../styles/pages/liveChat.scss'
import { useSelector, useDispatch} from "react-redux";
import {updateUserConnections, pushUserConnection } from '../feature/messageSlice';
import {setChannelMessages, pushChannelMessage, setActualChannelId , setChannelModal, updateChannels, setChannelRole } from '../feature/channelSlice';
import cookies from 'js-cookie';
import {liveChatUsers, relationUserInfo, liveChatUserInfo} from '../services/UserServices';
import {setProfileId} from '../feature/profileSlice';
import {Channels, getChannel, newChannelMessage, getDiscover, joinChannel, banMember, kickMember, pinMessage} from '../services/ChannelServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faMicrophone, faAward,faRss, faCrown, faUserGroup, faCircleCheck,faLink,faPersonWalkingArrowRight, faCircle,faMedal,faSatelliteDish,faArrowUpWideShort, faArrowDownShortWide,faComments,faPhoneVolume,faCameraRetro,
    faHashtag, faCompass, faPlus, faVideo, faPaperPlane, faFolderPlus, faLaptopCode, faPaintBrush,faImage, faMicrophoneSlash, faVideoSlash,
    faFaceGrinTongueSquint, faGear, faCheck, faAngleRight, faAngleDown, faRightToBracket, faPersonRunning, faUserPlus, faArrowRight, faEnvelope, faThumbTack, faPhone, faCamera, faBan, faBolt} from '@fortawesome/free-solid-svg-icons';
import { faSpeakerDeck } from '@fortawesome/free-brands-svg-icons'
import {setNavigation} from "../feature/navigationSlice";
import {dateParser} from '../services/utils';
import Peer from "simple-peer";
import ChannelModal from '../modal/channelModal';
import Fade from 'react-reveal/Fade';
import 'react-loading-skeleton/dist/skeleton.css';
import Discover from '../components/Discover';
import ChannelFriends from '../components/ChannelFriends';
import ChannelSettings from '../components/ChannelSettings';
import EmojiPicker , {
    EmojiStyle,
    SkinTones,
    Theme,
    Categories,
    EmojiClickData,
    Emoji,
    SuggestionMode,
    SkinTonePickerLocation
  } from 'emoji-picker-react';

import connect from "../media/audio/connect.wav";
import disconnect from "../media/audio/disconnect.wav";
import mute from "../media/audio/mute.wav";
import unmute from "../media/audio/unmute.wav";
import join from "../media/audio/join.wav";
import kick from "../media/audio/kick.wav";
import ban from "../media/audio/ban.wav";



const channelSocket = io(process.env.REACT_APP_API_SOCKET_CHANNEL, {
    withCredentials: true,
    autoConnect: true,
    transports : ['websocket']
}); // the Chhannels namepsace

function UserCard({channelData,userData}){
    const [userChannelData, setUserChannelData] = useState({});
    const [role, setRole] = useState("");
    const ownRole = useSelector((state) => state.channel.channelRole);
    const dispatch = useDispatch();

    useEffect(() => {
        const e = channelData.usersIds.find(user => user.userId === userData.id);
        setUserChannelData(e);
        const rolee = channelData.channelRoles.find(role => role.name === e?.roles);
        setRole(rolee);
    }, [userData]);

    const SwitchToProfilePage = async () => {
        dispatch(setNavigation('#userProfile'));
        dispatch(setProfileId(userData.id));
    };


    const BanKick = async (is_A_Ban, id) => {
        var e = null;
        if (is_A_Ban){
            e  = await banMember(channelData._id, {"memberId" : id});
        }else{
            e  = await kickMember(channelData._id, {"memberId" : id});
        }
        if (e.status === 201){
            channelSocket.emit("channelUpdate", {
                channelId: channelData._id
            }); 
            dispatch(updateChannels());
        }
    }
    return(<>
        <Fade>
        <div  style={ {"background": userData?.profileFade ? `linear-gradient(30deg,${userData.fadeValues[0]}, ${userData.fadeValues[1]})` : `none`}}   className={userData.profileFade === true ? 'UserCardMenuParent fadeCard' : 'UserCardMenuParent'}>
            <div  className='UserCardMenu'>
                <img className='banner' src={userData.profileBanner} alt="" />
                <img  className='profile' src={userData.imageUrl} alt="" />
                <h1>{userData.prenom} {userData.nom} 
                {userData.verified && <> <i style={{color: "#1da1f2"}}><FontAwesomeIcon icon={faCircleCheck} bounce /> </i></>} 
                {userData.nitroBadge && <>  <FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}  />  </>} 
                </h1>
                <h2>@{userData.pseudo}</h2>
                <h3> <span>{userData.followers}</span> Followers   <span>{userData.followings}</span> Followings</h3>
                <h5>ROLE</h5>
                {(!channelData.generalChannel) ? <><h5> <span  style={{ borderColor: role?.color }}> <FontAwesomeIcon style={{ color: role?.color }} icon={faCircle}/> {role?.name}</span></h5> </>
                 : <> <h5><span style={{ borderColor: '#22c8ff'}} >  <FontAwesomeIcon style={{ color: '#22c8ff'}} icon={faCircle}/> Nitrochat Member</span></h5>
                </>}
                {userData.verified && <>
                    <h5 className='vrf'><span style={{ borderColor: '#1da1f2'}} >  <FontAwesomeIcon style={{ color: '#1da1f2'}} icon={faCircleCheck}/> Verified User  </span></h5>                
                </>}
                {userData.nitroBadge && <>
                    <h5 className='vrf'><span style={{ borderColor: 'cyan'}} >  <FontAwesomeIcon style={{ color: 'cyan'}} icon={faSpeakerDeck}/> Super Nitro Badge  </span></h5>                
                </>}
                {userData.mediumBadge && <>
                    <h5 className='vrf'><span style={{ borderColor: 'gold'}} >  <FontAwesomeIcon style={{ color: 'gold'}} icon={faBolt}/> Energy Badge </span></h5>                
                </>}
                { (userData.id !== cookies.get("userId") && !channelData.generalChannel)  && <>
                    <div className="permissions">
                        <small onClick={() => (BanKick(false, userData.id))} className={ownRole?.canBan && "auth"}>KICK <FontAwesomeIcon icon={faPersonWalkingArrowRight}/></small>
                        <small onClick={() => (BanKick(true, userData.id))} className={ownRole?.canKick && "auth"}>BAN <FontAwesomeIcon icon={faBan}/></small>
                    </div> 
                </>}
           
     
                <p>Member Since  <span> {channelData.generalChannel ? <>{dateParser((userData.createdAt), 8)}</> :<> {dateParser(new Date(userChannelData?.timestamp), 8)} </>}</span></p>
                <button onClick={() => (SwitchToProfilePage())}>CHECK PROFILE <FontAwesomeIcon icon={faLink}/></button>

            </div>
        </div>
        </Fade>
    </>)
}


function SimilarMembers({usersIds}){
    const [userInfos, setUserInfos] = useState([]);
    const ownFollowings = useSelector((state) => state.profile.profileFollows);

    useEffect(() => {
        (async () => {
            var pushar = [];
            await Promise.all(usersIds.map( async (userData, index) => {
                if (ownFollowings.includes(userData.userId) === true){
                    const info = await relationUserInfo(userData.userId);
                    pushar.push(info.getUser);
                }
            }));
            setUserInfos(pushar);
        })();
    }, []);
    return(<>
    {userInfos.length > 2 && <>
        <div className='similar'>
            <div className="imgs">
                {userInfos?.map((inf, index) => (
                    <>
                        {index < 5 && <>
                            <img className='relationPic' src={inf.imageUrl} alt="" />
                        </>}
                    </>))}
            </div>
            <p> {userInfos[0]?.prenom} {userInfos[0]?.nom} and {userInfos.length} more friends are Members</p>
        </div> 
        </>}
    </>)
}

function MessageUserCard({userId, timestamp, isRoom, isCall, roomsData, isLive}){
    const [userInfos, setUserInfos] = useState([]);
    const [botTypes, setBotTypes] = useState(["join", "invite", "banner", "picture", "kick", "ban"]);
    const [isBotMessage,setIsBotMessage] = useState(false);

    const [log_type, setLog_type] = useState("");

    const [mutedInfo, setMutedInfo] = useState({});

    useEffect(() => {
        (async () => {
            var userInf = []
            const words = userId.split(' ');
            setLog_type(words[0]);
            if (botTypes.includes(words[0]) === false){
                userInf = await relationUserInfo(userId);
            }else{
                userInf = await relationUserInfo(words[1]);
                setIsBotMessage(true);
            }
            setUserInfos(userInf.getUser);
        })();
    }, [userId]);

    useEffect(() => {
        (async () => {
            if (isLive  || isRoom || isCall){
                const userMuted = roomsData?.find(user => user.user_id === userId);
                var e = {... userMuted};
                setMutedInfo({...userMuted});
            }
        })();
    }, [roomsData]);

    return(<>
        {isLive === true ? <>
            <div className="liveCallUser">
                <div>
                    <img src={userInfos.imageUrl} alt="" />
                    <p>{userInfos.nom} {userInfos.prenom}</p>
                </div>
                <div>
                    {mutedInfo?.microphoneMute && <> <span className='le'><FontAwesomeIcon icon={faMicrophoneSlash}/></span></>}
                    {mutedInfo?.cameraMute && <><span ><FontAwesomeIcon icon={faVideoSlash}/></span></>}
                </div>
            </div>
        </> :<>
            {isCall === true ? <>
                <div className="callUserCard">
                    <img src={userInfos.imageUrl} alt="" />
                    <h1>{userInfos.nom} {userInfos.prenom} <br />
                        <span> @{userInfos.pseudo}
                            {mutedInfo.microphoneMute && <><FontAwesomeIcon icon={faMicrophoneSlash}/></>}
                            {mutedInfo.cameraMute && <><FontAwesomeIcon icon={faVideoSlash}/> </>}
                        </span>
                    </h1>
                </div>
            </>:<>
                {isRoom === false ? <>
                        {isBotMessage === true ? <>
                            <div className='botMsg'>
                                {(() => {
                                    switch(log_type) {
                                        case 'join':
                                            return <>
                                                <h1> <small style={{color: "#39c555"}} ><FontAwesomeIcon icon={faArrowRight}/></small>   <span>{userInfos.nom} {userInfos.prenom} </span> has joined this incredible channel. <strong> {dateParser(new Date(timestamp), 7)}</strong></h1>
                                            </>
                                        case 'invite':
                                            return <>
                                                <h1> <small  style={{color: "#1da1f2"}}><FontAwesomeIcon icon={faEnvelope}/></small>   <span>{userInfos.nom} {userInfos.prenom} </span> has been invited to the channel.  <strong>{dateParser(new Date(timestamp), 7)}</strong></h1>
                                            </>
                                        case 'picture':
                                            return <>
                                                <h1> <small  style={{color: "#abff6e"}}><FontAwesomeIcon icon={faImage}/></small>   <span>{userInfos.nom} {userInfos.prenom} </span> has modified the picture of this channel.  <strong>{dateParser(new Date(timestamp), 7)}</strong></h1>
                                            </>
                                        case 'banner':
                                            return <>
                                                <h1> <small  style={{color: "#7cfffd"}}><FontAwesomeIcon icon={faPaintBrush}/></small>   <span>{userInfos.nom} {userInfos.prenom} </span> has modified the banner of this channel.   <strong>{dateParser(new Date(timestamp), 7)}</strong></h1>
                                            </>
                                        case 'ban':
                                            return <>
                                                <h1> <small  style={{color: "mediumslateblue"}}><FontAwesomeIcon icon={faBan}/></small>   <span>{userInfos.nom} {userInfos.prenom} </span> has been banned from this channel.   <strong>{dateParser(new Date(timestamp), 7)}</strong></h1>
                                            </>
                                        case 'kick':
                                            return <>
                                                <h1> <small  style={{color: "gold"}}><FontAwesomeIcon icon={faPersonWalkingArrowRight}/></small>   <span>{userInfos.nom} {userInfos.prenom} </span> has been kicked from this channel.   <strong>{dateParser(new Date(timestamp), 7)}</strong></h1>
                                        </>
                                        }
                                })()}
                            </div>
                        </>:<>

                            <div className="msgUserInfoCard">
                                {userInfos?.imageUrl?.length > 0 && <><img src={userInfos.imageUrl} alt="" /></>}
                                <h1>{userInfos.nom} {userInfos.prenom}  <span> {dateParser(new Date(timestamp), 7)} </span></h1>
                            </div>

                        </>}
                    </> : <>
                        <div className="chatRoomUserCard">
                            <div>
                                <img src={userInfos.imageUrl} alt="" />
                                <h2>{userInfos.prenom} {userInfos.nom}</h2>
                            </div>
                            <div>
                                {mutedInfo.microphoneMute && <> <h2><FontAwesomeIcon icon={faMicrophoneSlash}/></h2></>}
                                {mutedInfo.cameraMute && <>  <h2><FontAwesomeIcon icon={faVideoSlash}/></h2> </>}
                            </div>
                    
                        </div>
                    </>}
                </>}
            </>}
    </>)
}

const StreamVideo = ({ stream, isAudio, ownStream, micMute, camMute }) => {
    var localAudio = createRef();
    var localVideo = createRef();
    // localVideo.current is null on first render
    // localVideo.current.srcObject = stream; 

  
    useEffect(() => {
        // Let's update the srcObject only after the ref has been set
        // and then every time the stream prop updates
        if(!ownStream && !isAudio){
            localAudio.current.srcObject  = stream;
        }
        localVideo.current.srcObject  = stream;
        //localAudio.current.srcObject  = stream;
    }, [stream]); 

    useEffect(() => {
            if ( (camMute || (camMute && micMute))  || (isAudio && micMute)){
                localVideo.current.srcObject  = null;
            }else{
                localVideo.current.srcObject  = stream;
            }  
    }, [camMute]); 
  
    return (<>
            {isAudio ? <>
                {!micMute && <>
                    <audio ref={localVideo} autoPlay/>
                </>}
            </>: <> 
                <div className='cameraVideo'>
                    {ownStream ? <>
                        <video muted playsInline ref={localVideo} autoPlay/>
                    </> : <>
                        <audio muted={!camMute || micMute} ref={localAudio} autoPlay/>
                        <video muted={micMute} playsInline ref={localVideo} autoPlay/> 
                    </>}
                </div>
            </>}
     </>);
};
  

function LiveChat() {

    const msgSend = useRef(null);
    const bottomRef = useRef(null);
    const [usrCardInfo, setUsrCardInfo] = useState([]);

    const dispatch = useDispatch();
    const navigationState = useSelector((state) => state.navigation);
    const connectedUsers = useSelector((state) => state.messages.connectedUsers);

    const channelMessages = useSelector((state) => state.channel.actualChannelMessages);
    const channelModal = useSelector((state) => state.channel.channelModal);
    const channelUpdate = useSelector((state) => state.channel.channelCreationJoin);

    const friendModal = useSelector((state) => state.channel.closeFriendsWindow);

    const [usersData, setUsersData] = useState([]);
    const [actualUsersData, setActualUsersData] = useState([]);

    const [menuChannels, setMenuChannels] = useState([]);

    const [previousChannel, setPreviousChannel] = useState("");

    const [memberSince, setMemberSince] = useState("");
    const [adminId, setAdminId] = useState("");

    const [gcChannel, setGcChannel] = useState([]);

    const mainChannelId = useSelector((state) => state.channel.actualChannelId);
    const ownRole = useSelector((state) => state.channel.channelRole);
    const [discover, setDiscover] = useState(false);

    const [menuSw, setMenuSw] = useState("messages");
    const [showMembers, setShowMembers] = useState(true);

    const [stream, setStream] = useState();
    const myVideo = useRef({});
    const [streams, setStreams] = useState([]);
    // const [streamsSignals, setStreamsSignals] = useState([]);
    // const [slots, setSlots] = useState([0,0,0,0 ,0,0,0,0]);
    const connectRef = useRef(null);

    const [voiceRoomArr, setVoiceRoom] = useState([]);
    const [facetimeRoomArr, setFacetimeRoom] = useState([]);

    const [splicedVoiceRoom, setSplicedVoiceRoom] = useState([]);
    const [splicedFacetimeRoom, setSplicedFacetimeRoom] = useState([]);

    const [joinCall, setJoinCall] = useState(false);
    const [emojiMenu, setEmojiMenu] = useState(false);

    const [microphoneMute, setMicrophoneMute] = useState(false);
    const [cameraMute, setCameraMute] = useState(false);

    const [generalSince, setGeneralSince] = useState("");

    const [showUserCard, setShowUserCard] = useState(false);
    const [userCardId, setUserCardId] = useState(0);
    const [userCardData, setUserCardData] = useState({});

    const [pinString, setPinString] = useState("");
    const [showPinned, setShowPinned] = useState(false);

    const [loadCall, setLoadCall] = useState(false);
    const [sortMode, setSortMode] = useState(false);
    const [filterMode, setFilterMode] = useState(false);

    const switchCard = (data, number) => {
        setUserCardData(data);
        setUserCardId(number)
    }

      // Declare a new state variable with the "useState" Hook
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 548;

  useEffect(() => {
    window.addEventListener("resize", () => setWidth(window.innerWidth));
  }, []);

    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
      }, [channelMessages]);

    const [roleUsers, setRoleUsers] = useState({});
    const [roleColors, setRoleColors] = useState({});
    const updateUsersDatas = async (id_channel, gCdata, menuReload) => {
        var gC = 0;
        if (!gCdata){
            gC = await getChannel(id_channel);
        }else{ gC = gCdata;}
        var newData = [];
        const role_colors = {};
        const role_Users = {};
        var n = [...usersData];
        if (menuReload){n = menuReload};
        if(gC.usersIds && n.length > 0){
            await Promise.all(gC.channelRoles.map((role) => {
                role_Users[role.name] = [];
                role_colors[role.name] = role.color;
            }));

            await Promise.all(gC.usersIds.map((userId) => {
                const pushUser = n.find(user => user.id === userId.userId);
                if (pushUser){newData?.push(pushUser);}
                role_Users[userId.roles]?.push(pushUser);
            }));
            setActualUsersData(newData);
            var newInsert_ = role_Users.member; delete role_Users.member; role_Users.member = newInsert_;
            setRoleUsers(role_Users);
            setRoleColors(role_colors);
        }
    };
    const setUserRole = async (channelData) => {
        const memberDate = channelData.usersIds?.find(element => element.userId === cookies.get("userId"));
        const roleData = channelData?.channelRoles?.find(role => role?.name === memberDate?.roles);
        dispatch(setChannelRole(roleData));
        if(roleData?.canPin){setPinString("pinMessage")};
    };

    const switchChannel = async (channelId, comeback, menuRload) => {
        if (blockInteraction){return 0;}
        delayInteraction(500);
        if((channelId !== mainChannelId )  || (comeback)){
            setPreviousChannel(mainChannelId);
            setDiscover(false);
            setAddFriends(false);
            setGcChannel([]);
        }
        leaveCall();
        setMenuSw('messages');
        if (channelId.length === 0){
            return 0;
        }
        if ((channelId !== mainChannelId ) || (comeback)){
            const gC = await getChannel(channelId);
            setGcChannel(gC);
            setUserRole(gC);
            if (gC.generalChannel === true){
                setActualUsersData(usersData);
                if(comeback){setActualUsersData(menuRload)};
            }else{
                const adminId = gC.usersIds?.find(element => element.roles === "admin"); setAdminId(adminId?.userId);
                const memberDate = gC.usersIds?.find(element => element.userId === cookies.get("userId")); setMemberSince(memberDate.timestamp);
                await updateUsersDatas(channelId, gC, menuRload);
            }
            dispatch(setActualChannelId(channelId));
            dispatch(setChannelMessages(gC.messages));
        }
    }

    const sendMessage = async () => {     
        if (msgSend.current.value.length > 0){
            channelSocket.emit("channelMessage", {
                content: msgSend.current.value,
                toChannel: mainChannelId,
                from: cookies.get("userId")
            });   
            document.getElementById("mesgSend").value= "";
            await newChannelMessage(mainChannelId, {
                senderId: cookies.get("userId"),
                content: msgSend.current.value,
            });   
        }
    }

    useEffect(() => {
        if (navigationState.navigation === "#liveChat"){
            channelSocket.auth = {channelId: mainChannelId, userId: cookies.get('userId'),jwt : cookies.get("jwt"),}
            channelSocket.connect();
            (async () => {
                const users = await liveChatUsers();  
                switchChannel(mainChannelId, true, users.getLiveChatUsers);
            })();
        }else{
            channelSocket.disconnect();
            (async () => {await leaveCall();})();
        }
    }, [navigationState.navigation]);

    useEffect(() => {
        channelSocket.disconnect();
        channelSocket.auth = {channelId: mainChannelId,
            userId: cookies.get('userId'),
            jwt : cookies.get("jwt")
        }
        channelSocket.connect();
    }, [mainChannelId]);

    useEffect(() => {
        channelSocket.off("channelMessage").on("channelMessage", ({content, concernedChannel,from}) => {
            if (concernedChannel === mainChannelId){
                dispatch(pushChannelMessage({
                    userId: from,
                    content : content,
                    timestamp: Date.now()
                }))
            }
         });
    }, [mainChannelId]);
    
    useEffect(() => {
        (async() => {
            const users = await liveChatUsers();  setUsersData(users.getLiveChatUsers);
            const mnuChannels = await Channels();
            setMenuChannels(mnuChannels);
            const e = await liveChatUserInfo(cookies.get("userId"));  setUsrCardInfo(e.getUser);
            setGeneralSince(Date.parse(e.getUser.createdAt));
            setAddFriends(false);
            updateUsersDatas(mainChannelId);
        })();   
    }, [channelUpdate]);

    useEffect(() => {
        channelSocket.on("channelUpdate", ({channelId}) => {
            (async() => {
                const gC = await getChannel(channelId);
                setGcChannel(gC);
                setUserRole(gC);
            })();
         });
         channelSocket.on("channelUsersUpdate", ({channelId}) => {  dispatch(updateChannels()); });
    }, []);

    function playSound(sound_){
        new Audio(sound_).play();
    }

    useEffect(() => {
        leaveCall();
        // if (navigationState.navigation === "#liveChat"){
        //     if (connectedUsers?.includes(cookies.get("userId")) === false){
        //         socket.auth = {userId : cookies.get("userId")};
        //         socket.connect();
        //     }
        // }
        // socket.on("users", (users) => {  dispatch(updateUserConnections(users)) });
        // socket.on("userConnected", (users) => {  dispatch(pushUserConnection(users)) });
    }, [navigationState.navigation]);


    useEffect(() => {
        if (mainChannelId.length > 0){
            setVoiceRoom([]);
            setFacetimeRoom([]);
            channelSocket.emit("joinChannel", {leaveChannelId : previousChannel,channelId: mainChannelId});
        }
    }, [mainChannelId]);


    useEffect(() => {
        channelSocket.off("channelCallsData").on('channelCallsData', ({channelId,vocalRoom,facetimeRoom}) => {
            setVoiceRoom([...vocalRoom]); setFacetimeRoom([...facetimeRoom]);
            const e = vocalRoom.findIndex(userRoom => userRoom.user_id === cookies.get("userId"));
            const f = facetimeRoom.findIndex(userRoom => userRoom.user_id === cookies.get("userId"));
            if (e !== -1){
                setCameraMute(vocalRoom[e].cameraMute);
                setMicrophoneMute(vocalRoom[e].microphoneMute);
            }else{
                setCameraMute(facetimeRoom[f]?.cameraMute); setMicrophoneMute(facetimeRoom[f]?.microphoneMute);
            }
            facetimeRoom.splice(f, f + 1);
            vocalRoom.splice(f, f + 1);
            setSplicedFacetimeRoom([...facetimeRoom]);setSplicedVoiceRoom([...vocalRoom]);
        });

        channelSocket.on('channelSound', (num) => {
            if (num === 1){
                playSound(connect);
            }else if (num === -1){
                playSound(disconnect);
            }else if (num === 0){
                playSound(join);
            }else if (num === 2){
                playSound(kick);
            }else if (num === 3){
                playSound(ban);
            }
        });
    }, []);

    useEffect(() => { setAddFriends(false) }, [friendModal]);
    const [blockInteraction, setBlockInteraction] = useState(false);
    const delayInteraction = async (ms) => {
        setBlockInteraction(true);  await new Promise(  resolve => setTimeout(resolve, ms)); setBlockInteraction(false); 
    };
    const callGroup = async  (id, isInitiator, cameraType) => {
        if (!blockInteraction && !joinCall){
            await leaveCall();
            delayInteraction(1200)
            setJoinCall(true);
            setLoadCall(true);
            
            const videoConstraints = {
                audio: true,
                video: true,
            };
        
            if (cameraType){
                setMenuSw("facecam");
            }else{
                videoConstraints.video = false
                setMenuSw("vocal");
            }

            var mediaStream = await(navigator.mediaDevices.getUserMedia(videoConstraints));
            setStream(mediaStream);
            myVideo.current.srcObject =  mediaStream;

            channelSocket.on('leaveCall', async ({from, signalIndex}) => {
                const i = ([... streams]);
                i.splice(signalIndex, signalIndex + 1);
                setStreams(i);
            });

            const peer = new Peer({
                initiator: isInitiator,
                trickle: false,
                stream: mediaStream
            });


            peer.on("signal", (data) => {
                channelSocket.emit('joinCall', {
                        groupToCall: id,
                        signalData: data,
                        from: cookies.get("userId"),
                        isFacetimeRoom: cameraType
                })
            });

            // PUSH A STREAM VIDEO INTAKE
            peer.on("stream", (stream) => {
                setStreams([...streams, stream]);
            });

            channelSocket.on('joinCall', async ({presentSignals, from, groupId, signal}) => {
                if (from !== cookies.get("userId")){
                    peer.signal(signal);    
                    await Promise.all(presentSignals.map((prevSignal, index) => {
                        if (prevSignal.signalerId !== from && prevSignal.signalerId !== cookies.get("userId")){
                            peer.signal(prevSignal.signalValue);    
                        }
                    }));
                }
            });
            connectRef.current = peer;
            setLoadCall(false);
        }
    };

    const leaveCall = async () => {
        await stream?.getTracks()?.forEach((track) => track?.stop());
        setJoinCall(false);
        setStreams([]); setCameraMute(false); setMicrophoneMute(false);
        channelSocket.emit('leaveCall', {});
        setLoadCall(false);
        connectRef?.current?.destroy();
    };

    const emoji = (emojiData) => {
        msgSend.current.value =  msgSend.current.value  + String.fromCodePoint("0x"+emojiData.unified);
    };

    const settingSocket = (isMicrophone) => {
        if (blockInteraction === true){return 0;}
        delayInteraction(200);
        var e = "facetimeRoom";
        if (menuSw === "vocal"){ e = "vocalRoom";}
        if (joinCall){
            var valueSetting =  !cameraMute;
            var stg = "cameraMute";
            if (isMicrophone){
                valueSetting = !microphoneMute;
                stg = "microphoneMute"
            }
            if(valueSetting === true){playSound(mute)}else{playSound(unmute)}
            channelSocket.emit('streamSetting', {channelId: mainChannelId,roomType:  e, setting: stg,settingValue: valueSetting ,from:  cookies.get("userId") });
        }
    };

    const [vocalClose, setVocalClose] = useState(false);
    const [cameraClose, setCameraClose] = useState(false);

    const [addFriends, setAddFriends] = useState(false);

    const pinMessageFunc = async (message_id) => {
        if (mainChannelId && ownRole.canPin){
            const o = await pinMessage(mainChannelId, {"messageId" : message_id});
        }
    };

    return (
        <div className='liveChat_page' onMouseLeave={() => (setShowUserCard(false))}>
            <div className={discover === true ? "subLiveChat subRow" : "subLiveChat"}>

                <div   onMouseEnter={() => (setShowUserCard(false))} className={discover === true ? "left Discovered" : "left"}>
                    <div className='channels'>
                            <h1> <FontAwesomeIcon icon={faLaptopCode}/> </h1>

                            <Fade bottom>
                            <i></i>
                            {menuChannels?.map((channel) => (<>
                                {channel.generalChannel == true && <>
                                        <div className="channel animate__bounceInUp">
                                            <img onClick={() => (switchChannel(channel._id, false))} src={channel.channelPicture} alt="Channel Picture" />
                                            <span>{channel.channelName}</span>
                                        </div>
                                </>}
                            </>))}
                            
                            <i></i>
                            {menuChannels?.map((channel) => (
                                <>
                                    {channel.generalChannel == false && <>
                                            <div className="channel animate__bounceInUp">
                                                <img  onClick={() => (switchChannel(channel._id, false))} src={channel.channelPicture} alt="Channel Picture" />
                                                <span>{channel.channelName}</span>
                                            </div>
                                </>}
                            </>))}

                                <button onClick={() => (setDiscover(true))} ><FontAwesomeIcon icon={faCompass}/> </button>

                                <button onClick={( )=> (dispatch(setChannelModal(true)))}> <FontAwesomeIcon icon={faPlus}/> </button>
                                
                                <p>{usrCardInfo.channelsCount ? usrCardInfo.channelsCount : 0} / 10</p>
                            
                            </Fade>

                    </div>

                    <div className="chatRooms">
                        <div className="rooms">
                            
                            { Object.keys(gcChannel).length > 0 && <> 
                                <div>
                                    {mainChannelId.length > 0 ? <>
                                        <h1>{gcChannel.channelName}</h1>

                                        <Fade bottom>
                                            <div className="chatRoom">
                                                <div className={menuSw === "messages" ? "title selected" : "title"}>
                                                    <span><FontAwesomeIcon icon={faHashtag}/></span>
                                                    <p>{gcChannel.channelName} General textual chat </p>
                                                </div>
                                            </div>
                                        </Fade>

                                        <Fade bottom>
                                            <div className="chatRoom">
                                                <div  className={menuSw === "vocals" ? "title selected" : "title"} onClick={() => (setVocalClose(!vocalClose))}>

                                                    <div className='lft'>
                                                        <i> {vocalClose === true ? <><FontAwesomeIcon icon={faAngleRight}/></> : <><FontAwesomeIcon icon={faAngleDown}/></>}</i> 
                                                        <span><FontAwesomeIcon icon={faMicrophone}/></span> 
                                                        <p className='roomTxt'>Vocal {gcChannel.channelName}'s Room </p>
                                                    </div>
                                                 

                                                    <div className='lft'>
                                                       <h4>0{voiceRoomArr?.length} / 08</h4>
                                                    </div>
                                                </div>

                                                { !vocalClose && <>
                                                    {voiceRoomArr?.map((user, index) => (<>
                                                        <MessageUserCard roomsData={voiceRoomArr} userId={user.user_id} timestamp={""} isRoom={true}  isCall={false}/>
                                                    </>))}
                                                </>}
                                               
                                                {((!vocalClose && ownRole?.canVocal === true )  || (gcChannel.generalChannel === true) )&& <>


                                                    {voiceRoomArr?.findIndex(roomUser => roomUser.user_id === cookies.get("userId")) === -1 && <>
                                                    
                                                        {voiceRoomArr?.length === 0 ? <>

                                                            <h3 onClick={(() => (callGroup(mainChannelId, true, false)))}> 
                                                                START VOCAL CALL <FontAwesomeIcon icon={faMicrophone}/>
                                                            </h3>

                                                        </>:<>

                                                            <h3 onClick={(() => (callGroup(mainChannelId, true, false)))}> 
                                                                JOIN VOCAL CALL <FontAwesomeIcon icon={faRightToBracket}/>
                                                            </h3>
                                                        </>}      
                                                    </>}
                                                </>}
                                      

                                            </div>
                                        </Fade>

                                        <Fade bottom>
                                            <div className="chatRoom">
                                                
                                                <div  className={menuSw === "facecam" ? "title selected" : "title"}>
                                                    <div className='lft' onClick={() => (setCameraClose(!cameraClose))}>
                                                        <i> {cameraClose === true ? <><FontAwesomeIcon icon={faAngleRight}/></> : <><FontAwesomeIcon icon={faAngleDown}/></>}</i>
                                                        <span> <FontAwesomeIcon icon={faVideo}/></span>    
                                                        <p className='roomTxt'>Camera {gcChannel.channelName}'s Room </p>
                                                    </div>
                                                    
                                                    <div className='lft'>
                                                        <h4> 0{facetimeRoomArr?.length} / 08 </h4>
                                                    </div>
                                                </div>

                                                {!cameraClose && <>
                                                    {facetimeRoomArr?.map((user, index) => (<>
                                                        <MessageUserCard  roomsData={facetimeRoomArr} userId={user.user_id} timestamp={""} isRoom={true}  isCall={false}/>
                                                    </>))}
                                                </>}
                                           
                                                {((!cameraClose  && ownRole?.canFacecam === true ) || (gcChannel.generalChannel === true) )&& <>

                                                    {facetimeRoomArr?.findIndex(roomUser => roomUser.user_id === cookies.get("userId")) === -1 && <>

                                                        <h3 onClick={(() => (callGroup(mainChannelId, true, true)))}>
                                                            {facetimeRoomArr?.length === 0 ? <>START CAMERA CALL <FontAwesomeIcon icon={faVideo}/></>:<>Join Camera Call <FontAwesomeIcon icon={faRightToBracket}/></>}
                                                        </h3>

                                                    </>}
                                                </>}


                                            </div>
                                        </Fade>

                                    </> :<>
                                    </>}

                                </div>
                            </>}
                            {loadCall && <><div class="lds-ripple"><div></div><div></div></div></>}

                            <div>
                                {joinCall === true && <>
                                    <h3 className='leave' onClick={(() => (leaveCall()))}>Leave Call <FontAwesomeIcon icon={faPersonRunning}/></h3>
                                </>}
                            </div>

                        </div>
                        <div className="userInfos">
                            <div className='infRow'>
                                {usrCardInfo?.imageUrl && <>
                                    <img src={usrCardInfo.imageUrl} alt="" />
                                    <div className="txt">
                                            <p>{usrCardInfo.nom} {usrCardInfo.prenom}</p>
                                            <p className='ps'>@{usrCardInfo.pseudo}</p>
                                    </div>
                                </>}
                            </div>
                     
                            <div className='infRow'>                                 
                            </div>              
                        </div>
                    </div>
                </div>


                {discover === true ? <>
                    <Discover usersData={usersData}/>
                </>: <>
                <div className="right">
                {mainChannelId.length > 0 ? <>
                        { Object.keys(gcChannel).length > 0 ? <>
                            <div className="B">
                            <div  className={showMembers === false ? "l fullScreen" : "l"}>
                            
                                    <Fade top>
                                        <div style={ {"background": gcChannel.fade ? `linear-gradient(15deg,${gcChannel.fadeValues[0]}, ${gcChannel.fadeValues[1]})` : `none`}}   className={gcChannel.fade ? "top fadeTop" : "top"}  onMouseDown={() => (setShowUserCard(false))}>

                                            <div className="canvasDiv">
                                                {gcChannel.generalChannel === true ? <>
                                                    <video preload loop playsInline autoFocus autoPlay muted src={gcChannel?.channelBanner} alt="" />
                                                </> : <>
                                                    <img style={ {"borderColor": gcChannel.fade ? `${gcChannel.fadeValues[1]}` : `none`}}  className='sceneBg' src={gcChannel.channelBanner} alt="" />
                                                </>}
                                            </div>

                                            <div className="descDiv">

                                                <div className='topdiv'>
                                                    <div className='rw'>
                                                        <img className='channelPictu' src={gcChannel?.channelPicture} alt="" />
                                                        <h4>Member since {gcChannel?.generalChannel === true ? <> {dateParser(new Date(generalSince), 4)} </> : <>{dateParser(new Date(memberSince), 4)} </>}</h4>
                                                    </div>
                                                    <div className='rw'>
                                                        <h4 className='add pin'><FontAwesomeIcon icon={faPhone}/></h4>
                                                        <h4 className='add pin'><FontAwesomeIcon icon={faVideo}/></h4>
                                                        {!gcChannel.generalChannel && <><h4 onClick={() => (setShowPinned(!showPinned))} className='add pin'><FontAwesomeIcon icon={faThumbTack}/></h4></>}

                                                        {ownRole?.canInvite && <>
                                                            <h4 className='add' onClick={() => (setAddFriends(!addFriends))}><FontAwesomeIcon icon={faUserPlus}/></h4>
                                                        </>}
                                                    </div>
                                                </div>

                                                <div className='subDesc'>
                                                    <div>
                                                        <h1>{gcChannel?.channelName}</h1>
                                                        <h2>{gcChannel?.generalChannel == true ? <>General Nitrochat</> : <>{gcChannel.private === true ? <>Private </> : <>Public</>}   </>} Community Channel  
                                                        </h2>
                                                    </div>
                                                    <div>
                                                        {gcChannel.generalChannel === false && <><SimilarMembers usersIds={gcChannel.usersIds}/></>}
                                                    </div>
                                                </div>

                                                <div className="subMenu">
                                                    <div className='rww rwleft'>
                                                        <h3  onClick={() => (setMenuSw("messages"))} className={menuSw === "messages" && 'active'}>{width < breakpoint ? <><FontAwesomeIcon icon={faComments}/></> : <>Messages</> }</h3>
                                                        <h3 onClick={() => (setMenuSw("facecam"))} className={menuSw === "facecam" && 'active'}>{width < breakpoint ? <><FontAwesomeIcon icon={faCameraRetro}/></> : <>Facecall</> }</h3>
                                                        <h3 onClick={() => (setMenuSw("vocal"))} className={menuSw === "vocal" && 'active'}>{width < breakpoint ? <><FontAwesomeIcon icon={faPhoneVolume}/></> : <>Vocal Room</> }</h3>
                                                        { (ownRole?.canEditPictures || ownRole?.canEdit  ||  ownRole?.canEditRoles )  && gcChannel.generalChannel === false && <>
                                                            <h3 onClick={() => (setMenuSw("settings"))} className={menuSw === "settings" && 'active'}><FontAwesomeIcon icon={faGear}/></h3>
                                                        </>}
                                                    </div>
                                                    <div className='rww'>
                                                        <i className='e' onClick={() => (settingSocket(true))}>{microphoneMute? <><FontAwesomeIcon icon={faMicrophoneSlash}/></>: <><FontAwesomeIcon icon={faMicrophone}/></>} </i>
                                                        <i className='e' onClick={() => (settingSocket(false))}>{cameraMute ? <> <FontAwesomeIcon icon={faVideoSlash}/></>: <> <FontAwesomeIcon icon={faVideo}/></>}</i>
                                                        <i onClick={() => (setShowMembers(!showMembers))}><FontAwesomeIcon icon={faUserGroup}/></i>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </Fade>

                                    <div className="mid" onMouseDown={() => (setShowUserCard(false))}>
                                        {(() => {
                                            switch(menuSw) {
                                                case 'messages':
                                                    return <>
                                                                
                                                                {showPinned ? <>
                                                                <Fade>
                                                                    <div className="messagesContent">
                                                                        <i><FontAwesomeIcon icon={faThumbTack}/></i>
                                                                        <h2>{gcChannel.channelName} {gcChannel.pinnedMessages.length} Pinned Messages.</h2>
                                                                        {mainChannelId?.length > 0 && <>
                                                                            {channelMessages?.map((chanMess, index) => (<>
                                                                                {gcChannel?.pinnedMessages.includes(chanMess._id) && <>
                                                                                    <div className={ channelMessages[index - 1]?.userId !== chanMess.userId ? "channelMessgCard spaced" : "channelMessgCard"}> 
                                                                                        {channelMessages[index - 1]?.userId !== chanMess.userId && <>
                                                                                            <MessageUserCard userId={chanMess.userId} timestamp={chanMess.timestamp} isRoom={false} isCall={false} />
                                                                                        </>}
                                                                                        {(/\s/.test(chanMess.userId)) === false && <>
                                                                                            <p onClick={() => (pinMessageFunc(chanMess._id))} className={ channelMessages[index - 1]?.userId !== chanMess.userId && "topMsg "}>{chanMess.content}</p>
                                                                                        </>}        
                                                                                    </div> 
                                                                                </>}
                                                                            </>))}
                                                                        </>}
                                                                    </div>
                                                                </Fade>
                                                            </> : <>
                                                                <div className="messagesContent">
                                                                    <Fade>
                                                                    <i><FontAwesomeIcon icon={faHashtag}/></i>
                                                                    <h2>Welcome in #{gcChannel.channelName}</h2>
                                                                    <h3>That's the beginning of the <span> # {gcChannel.channelName} channel. </span></h3>
                                                                    {mainChannelId?.length > 0 && <>
                                                                        {channelMessages?.map((chanMess, index) => (<>
                                                                            <div className={ channelMessages[index - 1]?.userId !== chanMess.userId ? "channelMessgCard spaced" : "channelMessgCard"}> 
                                                                                    {channelMessages[index - 1]?.userId !== chanMess.userId && <>
                                                                                        <MessageUserCard userId={chanMess.userId} timestamp={chanMess.timestamp} isRoom={false} isCall={false} />
                                                                                    </>}
                                                                                    {(/\s/.test(chanMess.userId)) === false && <>
                                                                                        <p onClick={() => (pinMessageFunc(chanMess._id))} className={ channelMessages[index - 1]?.userId !== chanMess.userId ? "topMsg " + pinString : pinString}>{chanMess.content}</p>
                                                                                    </>}        
                                                                            </div>
                                                                        </>))}
                                                                    </>}
                                                                    </Fade>
                                                                </div>
                                                            </>}
                                                    </>
                                                case 'facecam':
                                                    return <>
                                                    {facetimeRoomArr?.length > 0 ? <>
                                                           <div className="roomGrid">
                                                                {joinCall === true ? <>
                                                                    <Fade>
                                                                        <div className="roomSlot">
                                                                            <StreamVideo stream={stream} isAudio={false} ownStream={true} micMute={microphoneMute} camMute={cameraMute}/>
                                                                            <MessageUserCard roomsData={facetimeRoomArr} userId={cookies.get("userId")} isLive={true}/>
                                                                        </div>
                                                                    </Fade>
                                                                    
                                                                    {streams.map((aStream, index) => (<>
                                                                        <Fade>
                                                                            <div className="roomSlot">
                                                                                <StreamVideo stream={streams[index]} isAudio={false} micMute={splicedFacetimeRoom[index].microphoneMute} camMute={splicedFacetimeRoom[index].cameraMute}/>  
                                                                                <MessageUserCard roomsData={facetimeRoomArr} userId={splicedFacetimeRoom[index].user_id} isLive={true}/>
                                                                            </div>
                                                                        </Fade>
                                                                    </>))}
                                                                    
                                                                </> : <>
                                                                    {facetimeRoomArr.map((user, index) => (<>
                                                                        <div className="roomSlot">
                                                                            <MessageUserCard  roomsData={facetimeRoomArr} userId={user.user_id} timestamp={""} isRoom={true} isCall={true} />
                                                                        </div>
                                                                    </>))}
                                                                </>}
                                                        </div>
                                                    </>:<>
                                                        <div className="waitingForCall cam">
                                                            <Fade>
                                                                <video preload loop playsInline autoFocus autoPlay  src={require('../media/facecall.mp4')} alt="" />
                                                                <h1>No one joined Vocal Room yet.  <br/>
                                                                    Maybe if you start this call someone will show up ? Who knows...
                                                                </h1>
                                                                <button onClick={(() => (callGroup(mainChannelId, true, true)))}>INITIATE FACECALL <FontAwesomeIcon icon={faVideo}/></button>
                                                            </Fade>
                                                        </div>
                                                    </>}
                                                 
                                                    </>
                                                case 'vocal':
                                                    return <>
                                                    {voiceRoomArr?.length > 0 ? <>
                                                        <div className="roomGrid">
                                                            {voiceRoomArr.map((user, index) => (<>
                                                                <div className="roomSlot">
                                                                    <StreamVideo stream={streams[index]} isAudio={true} micMute={splicedVoiceRoom[index]?.microphoneMute}/> 
                                                                    <MessageUserCard  roomsData={voiceRoomArr} userId={user.user_id} isCall={true}/>
                                                                </div>
                                                            </>))}
                                                        </div>
                                                    </> : <>
                                                        <div className="waitingForCall voc">
                                                            <Fade>
                                                                <video preload loop playsInline autoFocus autoPlay  src={require('../media/package.mp4')} alt="" />
                                                                <h1>No one joined Facecall yet. <br/>
                                                                Maybe if you start this call someone will talk to you? Maybe not..
                                                                </h1>
                                                                <button onClick={(() => (callGroup(mainChannelId, true, false)))}>INITIATE VOCAL CALL <FontAwesomeIcon icon={faPhone}/></button>
                                                            </Fade>
                                                        </div>
                                                    </>}
                                                  
                                                    </>
                                                case 'settings':
                                                    return <>
                                                        <ChannelSettings channelData={gcChannel}  usersData={actualUsersData}/>
                                                    </>
                                            }
                                        })()}
                                        <div ref={bottomRef}/>
                                    </div>

                                    {menuSw === "messages" && <>
                                        <div className="bot"  onMouseEnter={() => (setShowUserCard(false))}>
                                            <Fade bottom>
                                                <div className="bottomBar">
                                                    <button><FontAwesomeIcon icon={faFolderPlus}/></button>
                                                    <input id="mesgSend" type="text"onKeyDown={e => {if (e.key === 'Enter') {sendMessage();}}}  placeholder={'Message in ' + gcChannel.channelName + "..."} ref={msgSend}  maxLength={256}/>
                                                    <button className='emoji' onClick={() => (setEmojiMenu(!emojiMenu))}><FontAwesomeIcon icon={faFaceGrinTongueSquint}/></button>
                                                    <button className='send' onClick={() => (sendMessage())}><FontAwesomeIcon icon={faPaperPlane}/></button>
                                                </div>
                                            </Fade>
                                        </div>
                                    </>}
                            
                                {addFriends && <>
                                    <ChannelFriends bannedUsers={gcChannel.bannedUsers} channelUsers={gcChannel.usersIds} channelId={gcChannel._id}/>
                                </>}
                                
                                {emojiMenu && <>
                                    <Fade>
                                        <div className="emojiPicker">
                                            <EmojiPicker onEmojiClick={emoji}  theme='dark' height={375} width={325} />
                                        </div>
                                    </Fade>
                                </>}

                                {showUserCard && <><UserCard onMouseEnter={() => (setShowUserCard(true))} onMouseOver={() => (setShowUserCard(true))}  channelData={gcChannel} userData={userCardData}/></>}
                    
                            </div>

                           {showMembers === true && <>
                            <div className="r" onMouseDown={() => (setShowUserCard(false))} >
                                    <h1 className='title'>{gcChannel.channelName}</h1>
                                    
                                    <div className="subTitle">
                                        <div>
                                            <h5>{gcChannel?.private ? <>Private</> : <>Public</>} Community</h5>
                                            <h5> Members {!gcChannel?.generalChannel ? <>{actualUsersData?.length}</> : <>{usersData?.length}</>} <FontAwesomeIcon icon={faUserGroup}/></h5>
                                        </div>
                                        <div>
                                            <button onClick={( )=> (setFilterMode(!filterMode))}>{filterMode ? <><FontAwesomeIcon icon={faArrowUpWideShort}/></> : <><FontAwesomeIcon icon={faArrowDownShortWide}/></> }</button>
                                        </div>
                                    </div>

                                    {!gcChannel?.generalChannel && <>
                                        <div className="sort">
                                            <span onClick={() => (setSortMode(!sortMode))}>Sort Mode</span>
                                            <i className={sortMode && "bluebord"}>{sortMode ? <><FontAwesomeIcon icon={faSatelliteDish}/></> : <><FontAwesomeIcon icon={faAward}/></>}</i>
                                        </div>
                                    </>} 
                                  
                                    
                                    {(sortMode  || gcChannel.generalChannel ) ? <>
                                        <Fade>
                                            <h1>ONLINE — {connectedUsers.length} <FontAwesomeIcon style={{color: "#1da1f2"}} icon={faRss}/></h1>
                                            {actualUsersData?.map((user, index) => (<>
                                                {connectedUsers.includes(user.id) === true && <>
                                                    <div onTouchStart={() => (setShowUserCard(true))}   onMouseEnter={()=> (switchCard(user, user.id))} onMouseDown={() => (setShowUserCard(true))}    onMouseOver={() => (setShowUserCard(true))}  className={connectedUsers.includes(user.id) === true ? 'connected userCard' : 'userCard'}>
                                                        <img src={user.imageUrl} alt=""  />
                                                        <div className='names'>
                                                            <h2>{user.prenom} {user.nom}   {user.mediumBadge && <>  <FontAwesomeIcon style={{color: "gold"}} icon={faBolt}  />  </>} {user.nitroBadge && <>  <FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}  />  </>}   {user.verified && <><FontAwesomeIcon style={{color: "#1da1f2"}} icon={faCircleCheck}/></>}  {adminId === user.id && <i><FontAwesomeIcon icon={faCrown}/></i> } </h2>
                                                            <h3>@{user.pseudo}</h3>
                                                        </div>

                                                    </div>
                                                </>}

                                            </>)).reverse()}
                                
                                            <h1>OFFLINE — {actualUsersData.length - connectedUsers.length}</h1>
                                            
                                            {filterMode? <>
                                                {actualUsersData?.map((user, index) => (<>
                                                    {connectedUsers.includes(user.id) === false && <>
                                                        <div onTouchStart={() => (setShowUserCard(true))}   onMouseEnter={()=> (switchCard(user, user.id))} onMouseDown={() => (setShowUserCard(true))}   onMouseOver={() => (setShowUserCard(true))} className={connectedUsers.includes(user.id) === true ? 'connected userCard' : 'userCard'}>
                                                            <img src={user.imageUrl} alt="" />
                                                            <div className='names'>
                                                                <h2>{user.prenom} {user.nom}   {user.mediumBadge && <>  <FontAwesomeIcon style={{color: "gold"}} icon={faBolt}  />  </>}  {user.nitroBadge && <>  <FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}  />  </>}  {user.verified && <><FontAwesomeIcon style={{color: "#1da1f2"}} icon={faCircleCheck}/></>}   { (adminId === user.id && gcChannel.generalChannel === false) &&   <i><FontAwesomeIcon icon={faCrown}/></i> }  </h2>
                                                                <h3>@{user.pseudo}</h3>
                                                            </div>

                                                        </div>
                                                    </>}
                                                </>))}
                                            </> : <>
                                                {actualUsersData?.map((user, index) => (<>
                                                    {connectedUsers.includes(user.id) === false && <>
                                                        <div onTouchStart={() => (setShowUserCard(true))}   onMouseEnter={()=> (switchCard(user, user.id))} onMouseDown={() => (setShowUserCard(true))}   onMouseOver={() => (setShowUserCard(true))} className={connectedUsers.includes(user.id) === true ? 'connected userCard' : 'userCard'}>
                                                            <img src={user.imageUrl} alt="" />
                                                            <div className='names'>
                                                                <h2>{user.prenom} {user.nom}   {user.mediumBadge && <>  <FontAwesomeIcon style={{color: "gold"}} icon={faBolt}  />  </>}  {user.nitroBadge && <>  <FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}  />  </>}  {user.verified && <><FontAwesomeIcon style={{color: "#1da1f2"}} icon={faCircleCheck}/></>}   { (adminId === user.id && gcChannel.generalChannel === false) &&   <i><FontAwesomeIcon icon={faCrown}/></i> }  </h2>
                                                                <h3>@{user.pseudo}</h3>
                                                            </div>

                                                        </div>
                                                    </>}
                                                </>)).reverse()}
                                            </>}
                             
                                        </Fade>
                                    </> : <>
                                    {filterMode ? <>
                                        {Object.entries(roleUsers).map(([key, value]) => (<>
                                            {value.length > 0 && <>
                                                <h1 className='roleh1'style={{borderColor: roleColors[key]}} >{key} {value.length} <FontAwesomeIcon style={{color: roleColors[key], fontSize: "11px", paddingBottom: '1px'}} icon={faCircle}/></h1>
                                                {value.map((user, index) => (<>
                                                        <div onTouchStart={() => (setShowUserCard(true))}   onMouseEnter={()=> (switchCard(user, user.id))} onMouseDown={() => (setShowUserCard(true))}  onMouseOver={() => (setShowUserCard(true))} className={connectedUsers.includes(user.id) === true ? 'connected userCard' : 'userCard'}>
                                                            <img src={user.imageUrl} alt="" />
                                                            <div className='names'>
                                                                <h2>{user.prenom} {user.nom}  {user.mediumBadge && <>  <FontAwesomeIcon style={{color: "gold"}} icon={faBolt}  />  </>}  {user.nitroBadge && <>  <FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}  /> </>}  {user.verified && <><FontAwesomeIcon style={{color: "#1da1f2"}} icon={faCircleCheck}/></>}  { (adminId === user.id && gcChannel.generalChannel === false) &&   <i><FontAwesomeIcon icon={faCrown}/></i> }  </h2>
                                                                <h3>@{user.pseudo}</h3>
                                                            </div>
                                                        </div>
                                                </>))}
                                            </>}
                                        </>)).reverse()}
                                    </> : <>
                                        {Object.entries(roleUsers).map(([key, value]) => (<>
                                            {value?.length > 0 && <>
                                                <h1 className='roleh1'style={{borderColor: roleColors[key]}} >{key} {value?.length} <FontAwesomeIcon style={{color: roleColors[key], fontSize: "11px", paddingBottom: '1px'}} icon={faCircle}/></h1>
                                                {value?.map((user, index) => (<>
                                                        <div onTouchStart={() => (setShowUserCard(true))}  onMouseEnter={()=> (switchCard(user, user.id))}  onMouseOver={() => (setShowUserCard(true))} className={connectedUsers.includes(user.id) === true ? 'connected userCard' : 'userCard'}>
                                                            <img src={user.imageUrl} alt="" />
                                                            <div className='names'>
                                                                <h2>{user.prenom} {user.nom}  {user.mediumBadge && <>  <FontAwesomeIcon style={{color: "gold"}} icon={faBolt}  />  </>}   {user.nitroBadge && <>  <FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}  />  </>}   {user.verified && <><FontAwesomeIcon style={{color: "#1da1f2"}} icon={faCircleCheck}/></>}   { (adminId === user.id && gcChannel.generalChannel === false) &&  <> <i><FontAwesomeIcon icon={faCrown}/></i> </>}  </h2>
                                                                <h3>@{user.pseudo}</h3>
                                                               
                                                            </div>
                                                        </div>
                                                </>))}
                                            </>}
                                        </>))}
                                    </>}
                                </>}
                            </div>
                           </>} 
                
                        </div> </> : <>
                            <div className="right">
                                <div className="lds-dual-ring"></div>
                            </div>
                        </>}
                        </> : <>
                                <div className="illustration">
                                    <div>
                                        <h4>Nitrochat Channels  <FontAwesomeIcon icon={faLaptopCode}/></h4>
                                        <h1>Discover channels on Nitrochat.</h1>
                                        <h2>Channels are places where you and your friends can easily <span>communicate</span>  and talk together. Personnalize your <span>channels</span>  and attribute <span>roles</span>  ! <br/> 
                                        
                                        <br/> And even more, with the rooms in your channel you are able to <span>call</span>  your friends and get into <span>camera</span> calls !</h2>
                                        <h3 onClick={() => (setDiscover(true))} >Discover channels  <FontAwesomeIcon icon={faCompass}/>  </h3>
                                    </div>
                                        <img src={require('../media/discover2.jpeg')} alt="" />
                                    <div>

                                    </div>
                                </div>
                            </>}  
                    </div>
                </>}

            </div>

            <>
                {channelModal === true && <>
                        <ChannelModal channelCount={usrCardInfo?.channelsCount}  username={usrCardInfo}/>
                </>}
            </>
        </div>
      
    )
}

export default LiveChat
