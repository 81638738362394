import {useState, useEffect, useRef} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Fade from 'react-reveal/Fade';
import cookies from 'js-cookie';
import '../styles/pages/nitro.scss';
import {faFireFlameCurved, faLaptopCode,faCircleCheck,faDroplet,faHeart,faLock,faFileImport,faAward,faLightbulb,faUpload,faMessage,faBoxOpen,
    faCheck, faBolt, faArrowTrendUp, faArrowTrendDown, faPaintRoller, faUserGroup, faCaretDown, faCircleArrowLeft, faGift} from '@fortawesome/free-solid-svg-icons';
import {getNitroInfss, buyUnlock, editProfieColors, editChannelColors, editUserGifPhoto} from '../services/UserServices';
import {channelGif} from '../services/ChannelServices';
import { ColorPicker, useColor, toColor } from "react-color-palette";
import "react-color-palette/lib/css/styles.css";
import {dateParser} from '../services/utils';
import { faSpeakerDeck } from '@fortawesome/free-brands-svg-icons'
import { ToastContainer, toast } from 'react-toastify';
import {newUserModification} from '../feature/profileSlice';
import {useDispatch } from "react-redux";
import {nFormatter} from "../services/utils";
import {setNavigation} from "../feature/navigationSlice";

function NitroShop() {
    const [userData, setUserData] = useState({});
    const dispatch = useDispatch();

    const [primaryPicker, setPrimaryPicker] = useState(false);
    const [secondaryPicker, setSecondaryPicker] = useState(false); 
    const [chanPicker, setChanPicker] = useState(false);
    const [secChanPicker, setSecChanPicker] = useState(false);

    const [loaded, setLoaded] = useState(false);

    const [color, setColor] = useState("#7b00ff");
    const [sec_color, setSecColor] = useState("#2ef97a");

    const [primaryColor,setPrimaryColor] = useColor("hex", "#7b00ff");
    const [secondaryColor, setSecondaryColor] = useColor("hex", "#2ef97a");

    const [selectedChannel, setSelectedChannel] = useState({});

    const [channColor, setChannColor] = useState("#ffea00");
    const [secChann_color, setSecChannColor] = useState("#2e48f9");

    const [primaryChanColor,setPrimaryChanColor] = useColor("hex", "#ffea00");
    const [secondaryChanColor, setSecondaryChanColor] = useColor("hex", "#2e48f9");

    const [channelMembers, setChannelMembers] = useState(0);
    const [messageCount, setMessageCount] = useState(0);
    const [unlocks, setUnlocks] = useState(0);

    const [boom, setBoom] = useState(0);
    const [rdb, setRdb] = useState(0);

    const bottomRef = useRef(null);

    const notify = (text) => toast.success(text, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });
    useEffect(() => {
        let p = Math.floor(Math.random() * 3);
        if(p === 1 || p === 2){setRdb(1);}
    }, []);

    useEffect(() => {
        (async( ) => {
           const o  = await getNitroInfss(); setUserData(o);
           if (o.userChannels.length > 0){ setSelectedChannel(o.userChannels[0]);} 
           let c = 0;
           for (const [key, value] of Object.entries(o.nitroUnlocks)) {if(value && key !== "_id"){
             c++;
           }}
           setUnlocks(c);
           if(o?.fadeValues[0]?.length > 1){setPrimaryColor(toColor("hex",  o.fadeValues[0] )); setSecondaryColor(toColor("hex",  o.fadeValues[1])); }
            if (o.userChannels.length === 0){return 0;}
            if (o.userChannels.length === 1){ setChannelMembers(o.userChannels[0].usersIds);setMessageCount(o.userChannels[0].length);return 0; }
            let x = 0; let p = 0;
            o?.userChannels?.map(a => {x += a.usersIds});
            o?.userChannels?.map(a => {p += a.length});
            setChannelMembers(x);setMessageCount(p);
        })();
    }, [boom]);

    useEffect(() => {
       if (Object.keys(userData).length > 1){
            setLoaded(true);
       }
    }, [userData]);

    useEffect(() => {
        setColor(primaryColor.hex); setSecColor(secondaryColor.hex);
    }, [primaryColor, secondaryColor]);

    useEffect(() => {
        setChannColor(primaryChanColor.hex); setSecChannColor(secondaryChanColor.hex);
    }, [primaryChanColor, secondaryChanColor]);

    const swChan = (id) => {
        const p = userData.userChannels.findIndex(e => e._id === id);
        setSelectedChannel(userData.userChannels[p]);
    }

    useEffect(() => {
        if (Object.keys(selectedChannel).length > 1 && selectedChannel?.fadeValues[0]?.length > 2){
            //setChannColor(selectedChannel?.fadeValues[0]); setSecChannColor(selectedChannel?.fadeValues[1]);
            setPrimaryChanColor(toColor("hex",  selectedChannel?.fadeValues[0])); 
            setSecondaryChanColor(toColor("hex",  selectedChannel?.fadeValues[1])); 
        }else{
            setPrimaryChanColor(toColor("hex", "#ffea00")); 
            setSecondaryChanColor(toColor("hex",  "#2e48f9")); 
        }
    }, [selectedChannel]);

    const buy = async (unlock, price) => {
        if (userData.nitro >= price && !userData.nitroUnlocks[unlock]){
            const i = await buyUnlock(unlock);
            if (i.status === 201){ setBoom(boom + 1); notify("New Nitrochat Unlock !");
            }
        }
    };
    const editPrfColors = async () => {
        if(userData.nitroUnlocks.gradientProfile){
            const i = await editProfieColors([primaryColor.hex, secondaryColor.hex], userData.profileFade);
            if(i.status === 201){ notify("Profile Colors Modified.");}
        }
    };
    const editChanColors = async () => {
        if(userData.nitroUnlocks.channelsThemes || !selectedChannel){
            const i = await editChannelColors(selectedChannel._id, [primaryChanColor.hex, secondaryChanColor.hex], selectedChannel.fade);
            if(i.status === 201){ 
                const p = userData.userChannels.findIndex(c => c._id === i.data.id);
                userData.userChannels[p].fadeValues = i.data.colors;
                notify("Channel Colors Modified.");
            }
        }
    };
    const fadeSwitch = (isChannel) => {
        if (isChannel){
            let indx = userData.userChannels.findIndex(e => e._id === selectedChannel._id);
            let c = {...selectedChannel}; c.fade = !c.fade;
            setSelectedChannel(c);  
            let chnls = [...userData.userChannels];chnls[indx].fade = !chnls[indx].fade;
            let vl = {...userData}; vl.userChannels = chnls;setUserData(vl);
        }else{
            let n = {...userData};
            n.profileFade = !n.profileFade;
            setUserData(n);
        }
    }
    const [chanDropover,setChanDropover] = useState(false);


    const userPfpRef = useRef(null);
    const bannerPfpRef = useRef(null);

    const channPfpRef = useRef(null);
    const channBannerRef = useRef(null);

    const [gifEdit, setGifEdit] = useState(false);
    const [chanGifEdit, setChanGifEdit] = useState(false);

    const edtUserGif = async (newGif, isChannel,isBanner) => {
        var APIFormMessage = new FormData();
        if (gifEdit || chanGifEdit || ( isChannel && !userData?.nitroUnlocks?.animatedChannels)){return 0;}
        if (isBanner ){        
            if (!userData?.nitroUnlocks?.animatedBanner){return 0;}
            APIFormMessage.append('isBanner', isBanner);
            APIFormMessage.append('image', newGif,  cookies.get('userId') + "_profile_" + newGif.name);
        }else{
            if (!userData?.nitroUnlocks?.animatedPfp){return 0;}
            APIFormMessage.append('image', newGif,  cookies.get('userId') + "_banner_" + newGif.name);
        }
        if (newGif ){
            if (!isChannel){
                setGifEdit(true);
                const i  = await editUserGifPhoto(APIFormMessage);
                if (i.status === 201){ dispatch(newUserModification());let data = {...userData}; data[isBanner ? "profileBanner" : "imageUrl"] = i.data; setUserData(data); notify("New Animated Profile Personalization !"); }
                setGifEdit(false);
            }else{
                setChanGifEdit(true);
                var chanId_ = selectedChannel?._id;
                const i  = await channelGif(APIFormMessage, chanId_);
                if (i.status === 201){
                    let chns = userData.userChannels.find(chan => chan._id === chanId_);
                    chns[isBanner ? "channelBanner" : "channelPicture"] = i.data;
                    setSelectedChannel(chns);
                    notify("New Animated " + selectedChannel.channelName + " Updated !"); 
                }
                setChanGifEdit(false);
            }
        }
    }

    return (<>
    <Fade>
        <div className='parr'>
        
        <div className='nitroShop'>
        {loaded ? <>
            <div className="center_nitro">
                    <div className="center_top">            
                        <Fade top>
                            <div className="lef">
                                <div>
                                    <h1>Nitroshop</h1>
                                    <h2>The marketplace to spend your Nitro.</h2>
                                </div>
              
                                <h3>The Nitro is the ressource you get by <strong>interacting</strong>, being friendly and taking part of the <strong>Evolution</strong>  of Nitrochat ! <br/> <br/>
                                    The most <strong>invested</strong> users will be <strong> rewarded with Nitro </strong> and will be able to <strong>spend </strong>  them in the <strong>Nitroshop</strong>  to show themselves off !
                                </h3>

                                <div className="spendFlex">
                                        <div className="spendRow br">
                                            <div className="rowL">
                                                <h5>Nitroshop</h5>
                                                <p>{unlocks} / 9  </p>
                                                <h5>Nitroshop Unlocks</h5>
                                            </div>
                                            <div className="rowR">
                                                <p className='icon'><FontAwesomeIcon  icon={faGift}/></p>
                                            </div>
                                        </div>

                                        <div className="spendRow br">
                                            <div className="rowL">
                                                <h5>Channel Members</h5>
                                                <p>{channelMembers} </p>
                                                <h5>{messageCount} Total Messages</h5>
                                            </div>
                                            <div className="rowR">
                                                <p className='icon'><FontAwesomeIcon  icon={faUserGroup}/></p>
                                            </div>
                                        </div>


                                        <div className="spendRow">
                                            <div className="rowL">
                                                <h5>Followers</h5>
                                                <p>{userData.followers} </p>
                                                <h5>{userData.postsCount} Nitrochats</h5>
                                            </div>
                                            <div className="rowR">
                                                <p className='icon'><FontAwesomeIcon  icon={faHeart}/></p>
                                            </div>
                                        </div>

                                    </div>         

                                    <h4 onClick={() => (bottomRef.current?.scrollIntoView({behavior: 'smooth'}))}>Spend your Nitro</h4>   
                            </div>
                        </Fade> 

                        <Fade right>
                            <div className="rig">
                                    <video loop autoPlay muted src={require('../media/shop/bubbles.mp4')} alt="" />
                            </div>
                        </Fade> 
                    </div>
                    
                    <Fade>
                    <div className="usrRow">
                        <div className="row">
                            <div className="ll">
                                <h1 className='nms'>{userData.prenom} {userData.nom} 
                                {userData.mediumBadge && <> < FontAwesomeIcon style={{color: "gold"}} icon={faBolt}/> </>}
                                {userData.nitroBadge && <> < FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}/> </>}
                                {userData.verified && <> < FontAwesomeIcon style={{color: "#1da1f2"}} icon={faCircleCheck}/> </>}
                                
                                </h1>
                                <h3>{userData?.channelsCreated?.length} Nitrochat Channels <FontAwesomeIcon icon={faLaptopCode}/></h3>
                            </div>
                            <div className="rr">
                                <img src={userData.imageUrl} alt="" />
                            </div>
                        </div>
            
                        <div className="row cntr">
                            <div className="ll">
                                <p>Nitro</p>
                                <h1 style={{color : "#00eaff"}} >{userData.nitro}</h1>
                                <h3>{Math.abs(userData.averageNitro)} % {userData.averageNitro > 0 ? <>more</>  : <>less</> }  than average {userData.averageNitro > 0 ? <FontAwesomeIcon icon={faArrowTrendUp}/>  : <FontAwesomeIcon icon={faArrowTrendDown}/> }</h3>
                            </div>
                            <div className="rr">
                                <h2 style={{color : "#00eaff"}}><FontAwesomeIcon icon={faFireFlameCurved}/></h2>
                            </div>
                        </div>

                        <div className="row">
                            <div className="ll"> 
                                <p>Nitrochat Interactions</p>
                                <h1 className='nrg'>{userData.interactions}</h1>
                                <h3>{Math.abs(userData.averageRelations)}%  {userData.averageRelations > 0 ? <>over</>  : <>below</> } average user {userData.averageRelations > 0 ? <FontAwesomeIcon icon={faArrowTrendUp}/>  : <FontAwesomeIcon icon={faArrowTrendDown}/> }</h3>
                            </div>
                            <div className="rr">
                                <h2 className='nrg'><FontAwesomeIcon icon={faLightbulb}/></h2>
                            </div>
                        </div>
                        
                    </div>
                    </Fade>

                <div className="card_map">

    
                        <div className="card_parnt">
                            <div className="_card">
                                <div>
                                    <img className='maxChar' src={require('../media/shop/charMax.png')} alt="" />
                                </div>
                                <h3>Longer Messages</h3>
                                <h4>Say everything you have to say in only one message by passing from 255 to 1000 characters per message.</h4>
                            </div>

                            <div className="price" onClick={() => (buy("longerMessages",10000))}>
                                <p> {userData?.nitroUnlocks?.longerMessages  ? <><FontAwesomeIcon  icon={faCheck}/></>: <>10 000 <span><FontAwesomeIcon icon={faFireFlameCurved}/></span> </>}</p>
                            </div>
                        </div>
       

                        <div className="card_parnt">
                            <div className="_card">
                                <div>
                                    <video  playsInline width={146} height={111} pre  load='auto' muted autoPlay loop src={require('../media/shop/postVideos.mp4')} alt="" />
                                </div>
                                <h3>Post Videos </h3>
                                <h4>Bring life to your posts on Nitrochat by being able to post Videos</h4>

       
                            </div>
                            
                            <div className="price" onClick={() => (buy("postVideos",20000))}>
                                    <p>{userData?.nitroUnlocks?.postVideos ? <><FontAwesomeIcon  icon={faCheck}/></>: <>20 000 <span><FontAwesomeIcon icon={faFireFlameCurved}/></span></>} </p>
                            </div>
                        </div>
             

                        <div className="card_parnt">
                            <div className="_card">
                                <div>
                                    <img style={{maxHeight: "108px"}} src={require('../media/shop/mediumBadge.gif')} alt="" />
                                </div>
                                <h3>Energy Badge</h3>
                                <h4>Add your touch of style with custom personnalized colors to show on your Profile !</h4>
                            
                            </div>

                            <div className="price" onClick={() => (buy("mediumBadge",30000))}>
                                <p> {userData?.nitroUnlocks?.mediumBadge  ? <><FontAwesomeIcon  icon={faCheck}/></> :  <> 30 000 <span><FontAwesomeIcon icon={faFireFlameCurved}/></span> </>}</p>
                            </div>
                        </div>
        
                        <div className="card_parnt">
                            <div className="_card">
                                <div>
                                    <img src={require('../media/shop/profileTheme.png')} alt="" />
                                </div>
                                <h3>Personnalized Profiles</h3>
                                <h4>Add your touch of style with custom personnalized colors to show on your Profile !</h4>
                            
                            </div>

                            <div className="price" onClick={() => (buy("gradientProfile",45000))}>
                                <p> {userData?.nitroUnlocks?.gradientProfile  ? <><FontAwesomeIcon  icon={faCheck}/></> :  <> 45 000 <span><FontAwesomeIcon icon={faFireFlameCurved}/></span> </>}</p>
                            </div>
                        </div>

                        <div className="card_parnt">
                            <div className="_card">
                                <div>
                                    <img src={require('../media/shop/channelTheme.png')} alt="" />
                                </div>
                                <h3>Channels Color Themes</h3>
                                <h4>Bring your own touch to Nitrochat with unique Color fades themes for your channels.</h4>
                            </div>

                            <div className="price" onClick={() => (buy("channelsThemes",50000))}>
                                    <p>  {userData?.nitroUnlocks?.channelsThemes  ? <><FontAwesomeIcon  icon={faCheck}/></> :  <> 50 000 <span><FontAwesomeIcon icon={faFireFlameCurved}/></span> </>}</p>
                            </div>
                        </div>
       

                        <div className="card_parnt">
                            <div className="_card">
                                <div>
                                    <video playsInline  width={146} height={111} pre  load='auto' muted autoPlay loop src={require('../media/shop/animatedProfile.mp4')} alt="" />
                                </div>
                                <h3>Animated Avatar</h3>
                                <h4>Give Life to your profile with an animated avatar as a profile picture on Nitrochat {"(gif)"}</h4>

       
                            </div>
                            
                            <div className="price" onClick={() => (buy("animatedPfp",65000))}>
                                    <p> {userData?.nitroUnlocks?.animatedPfp  ? <><FontAwesomeIcon  icon={faCheck}/></> :  <> 65 000 <span><FontAwesomeIcon icon={faFireFlameCurved}/></span> </>}</p>
                            </div>
                        </div>

                        <div className="card_parnt">
                            <div className="_card">
                                <div>
                                    <div className="animBanerDiv">
                                        <img className='banner' src={require(rdb === 0 ? '../media/shop/animtedBanner.gif' : '../media/shop/animtedBanner2.gif')} alt="" />
                                        <img className='pfp' src={userData.imageUrl} alt="" />
                                        <p>{userData.prenom} {userData.nom} <span>@{userData.pseudo}</span></p>
                                    </div>
                                </div>
                                <h3>Animated Banner</h3>
                                <h4>Make your profile more magnificient with an animated banner {"(gifs)"}</h4>

                            </div>

                            <div className="price" onClick={() => (buy("animatedBanner",80000))}>
                                    <p>{userData?.nitroUnlocks?.animatedBanner  ? <><FontAwesomeIcon  icon={faCheck}/></> :  <>  80 000 <span><FontAwesomeIcon icon={faFireFlameCurved}/></span> </>}</p>
                            </div>
                        </div>

        

                        <div className="card_parnt">
                            <div className="_card">
                                <div>
                                    <video className='animChan' playsInline width={146} height={111} pre load='auto' muted autoPlay loop src={require('../media/shop/animatedChannels.mp4')} alt="" />
                                </div>
                                <h3>Animated Channel Personalizations</h3>
                                <h4>Make your Nitrochat channels more alives than ever with Animated Channel Picture and Banners !</h4>

                           
                            </div>

                            <div className="price" onClick={() => (buy("animatedChannels",100000))}>
                                    <p>{userData?.nitroUnlocks?.animatedChannels  ? <><FontAwesomeIcon  icon={faCheck}/></> :  <>  100 000  <span><FontAwesomeIcon icon={faFireFlameCurved}/></span> </>}</p>
                            </div>
                        </div>

                        <div ref={bottomRef} className="card_parnt">
                            <div className="_card">
                                <div>
                                    <video playsInline   width={146} height={111} pre load='auto' muted autoPlay loop src={require('../media/shop/nitroBadge.mp4')} alt="" />
                                </div>
                                <h3>Super Nitro Badge</h3>
                                <h4>Flex your style all over the place on Nitrochat with the Super Nitro Badge</h4>

                           
                            </div>

                            <div className="price" onClick={() => (buy("nitroBadge",125000))}>
                                    <p>{userData?.nitroUnlocks?.nitroBadge  ? <><FontAwesomeIcon  icon={faCheck}/></> :  <>  125 000  <span><FontAwesomeIcon icon={faFireFlameCurved}/></span> </>}</p>
                            </div>
                        </div>
                </div>


                <div className="tryNitro" onMouseLeave={() => {setPrimaryPicker(false); setSecondaryPicker(false); }}>
                        <div className="try_l">
                            <h1>Try Nitro Personalizations !</h1>
                            <h2>Take a look at how your profile could look like with Nitro Unlocks.</h2>

                            <div className="option">
                                <p>Profile Themes</p>
                                {userData?.nitroUnlocks?.channelsThemes && <>
                                    <div style={{display: "flex", flexDirection: "row", marginBottom: "15px"}}>
                                        <h1 style={{fontSize: "13px",fontWeight: '500',marginRight: '8px', color: "aliceblue", marginTop: 'auto', marginBottom: 'auto'}}> Personnalized Theme</h1>
                                        <div class={userData?.profileFade === false ? "switch" : "switch clicked"} onClick={() => (fadeSwitch(false))}>
                                            <div className='rd'></div>
                                        </div>
                                    </div>
                
                                </>}  

                                <div className="colors">

                                    <div onClick={(( )=>{ setPrimaryPicker(true); setSecondaryPicker(false) })}  className="primary" style={{backgroundColor: color}}>
                                        <h4><FontAwesomeIcon icon={faPaintRoller}/></h4>
                                        {primaryPicker &&  <> <Fade right>
                                            <ColorPicker width={310} height={160} color={primaryColor}  onChange={setPrimaryColor} hideHSV dark />
                                        </Fade></>}
                                    </div>

                                    <div onClick={(( )=>{ setSecondaryPicker(true); setPrimaryPicker(false) })} className="secondary" style={{backgroundColor: sec_color}}>
                                        <h4><FontAwesomeIcon icon={faPaintRoller}/></h4>
                                        {secondaryPicker &&  <> <Fade right>
                                            <ColorPicker width={310} height={160} color={secondaryColor}  onChange={setSecondaryColor} hideHSV dark />
                                        </Fade></>}                                 
                                    </div>

                                </div>  
              
                            </div>

                            <div className="option">
                                <p>Animated Avatar</p>
                                <button  onClick={() => {if(userData?.nitroUnlocks?.animatedPfp){(userPfpRef.current.click())} }} > Add an Animated Avatar {userData?.nitroUnlocks?.animatedPfp ? <> <FontAwesomeIcon icon={faUpload}/> </> : <> <FontAwesomeIcon icon={faLock}/> </>}</button>
                                <input ref={userPfpRef} className='inpt' type="file" name="my-image" accept="image/gif" onChange={e => edtUserGif(e.target.files[0], false,false)}  />
                            </div>

                            <div className="option">
                                <p>Animated Banner</p>
                                <button  onClick={() => {if(userData?.nitroUnlocks?.animatedBanner){(bannerPfpRef.current.click())} }} >Add an Animated Banner  {userData?.nitroUnlocks?.animatedBanner ? <> <FontAwesomeIcon icon={faFileImport}/> </> : <> <FontAwesomeIcon icon={faLock}/> </>}</button>
                                <input  ref={bannerPfpRef} className='inpt' type="file" name="my-image" accept="image/gif"  onChange={e => edtUserGif(e.target.files[0],false, true)}  />
                            </div>
    

                            <div className="option">
                                <p>Nitro Unlocks also includes</p>
                                <h6>- New Channel Themes   <FontAwesomeIcon icon={faDroplet}/>  </h6>
                                <h6>- Exclusive Super Nitro Medal Badge  <FontAwesomeIcon icon={faAward}/>  </h6>
                            </div>

                            <button className='sve' onClick={() => (editPrfColors())} style={{backgroundColor: "rgb(0, 149, 255)"}} >Save Colors Modifications  {userData?.nitroUnlocks?.gradientProfile ? <><FontAwesomeIcon icon={faPaintRoller}/> </> : <><FontAwesomeIcon icon={faLock}/> </> }</button>
                            {gifEdit && <>  <div className="lds-dual-ring"></div> </>}
                        </div>

                        <div className="try_r">
                            <h5 style={{color: 'white', fontSize: "13px"}}>NITRO PREVIEW <FontAwesomeIcon className='chk'  icon={faFireFlameCurved}/></h5>

                            <div className="previewCardParent" style={{
                                    "background": `linear-gradient(20deg,${color}, ${sec_color})`}}>
                                <div className="previewCard">
                                        <img className='banr' src={userData.profileBanner} alt="" />
                                        <img className='prfl' src={userData.imageUrl} alt="" />

                                        <div className="icons" style={{borderColor: sec_color}}>  
                                            <FontAwesomeIcon   style={{color :  "cyan"}}  icon={faSpeakerDeck}/>
                                            <FontAwesomeIcon  style={{color: "gold"}} icon={faBolt}/>
                                            <FontAwesomeIcon  icon={faLaptopCode}/>
                                            <FontAwesomeIcon  style={{color : "#1da1f2"}} icon={faCircleCheck} />

                                        </div>

                                        <div className="descCard">
                                                <h1>{userData.prenom} {userData.nom} <span>@{userData.pseudo}</span></h1>
                                                <h2>MY PROFILE PERSONALIZATION </h2>
                                                <p className='bio'>{userData.biography}</p>
                                                <div className="descChild">
                                                    <img src={require('../media/shop/pen.png')} alt="" />
                                                    <p> <span>  Nitrochat User Profile  </span><br/> Last Modified : {dateParser(new Date(userData.updatedAt), 5)}</p>
                                                </div>
                                        </div>
                                </div>
                            </div>
                        </div>

                </div>


                <div className="tryNitro_channels" onMouseLeave={() => {setChanPicker(false); setSecChanPicker(false); }}>
                        <div className="top">
                            <h1>Nitrochat Channel Themes</h1>
                            <h2>Show the real person you are by creating themes for each channel you have on Nitrochat.</h2>

                            <h3>Select a channel :</h3>
                            <div className="drop" onClick={() => (setChanDropover(!chanDropover))}>
                                <div>
                                    <img src={selectedChannel?.channelPicture?.length > 0 ? selectedChannel.channelPicture : userData.imageUrl}alt="" />
                                    <h5>{selectedChannel?.channelName?.length > 0 ? <>{selectedChannel.channelName} </> : <>Preview</>} </h5>
                                </div>
                                <p className={chanDropover && 'turned'}><FontAwesomeIcon icon={faCaretDown}/> </p>
                            </div>

                                {chanDropover && userData?.userChannels?.length > 0 ? <>
                                    {userData.userChannels.map((chan, index) => (<>
                                    <Fade>
                                        <div className="drop mnu" onClick={( )=> (swChan(chan._id))}>
                                            <div>
                                                <img src={chan.channelPicture} alt="" />
                                                <h5>{chan.channelName} </h5>
                                            </div>
                                            <p>{selectedChannel._id === chan._id && <><FontAwesomeIcon icon={faCircleArrowLeft}/></> }</p>
                                        </div>
                                    </Fade>
                                    </>))}

                                </>:<>
                                {chanDropover && <>
                                    <Fade>
                                        <h4  onClick={() => (dispatch(setNavigation("#liveChat")))}className='newbtnc' style={{borderRadius: "10px",marginLeft:"auto",marginRight: "auto",border: "2px solid",backgroundColor: "#1da1f2",padding: "8px 17px 8px 17px",width: "max-content",fontSize: "16px",fontWeight: '700',textAlign: 'center', color: "white"}}> Create New Channel</h4>
                                    </Fade>
                                    </>}
                                </>}

                        </div>
                        
                        {userData?.nitroUnlocks?.channelsThemes && <>
                            <div style={{display: "flex", flexDirection: "row", marginTop: "10px"}}>
                                <h1 className='pse' style={{fontSize: "14px",marginRight: '8px', color: "aliceblue"}}> {selectedChannel.channelName} Customized Theme</h1>
                                <div class={selectedChannel?.fade === false ? "switch" : "switch clicked"}  onClick={() => (fadeSwitch(true))}>
                                    <div className='rd'></div>
                                </div>
                            </div>
        
                        </>}
           

                        <div className="customize">
                            <div className="colors">

                                 <div className='hrw'>
                                    <div className="colorOption">
                                        <h1>Primary</h1>
                                        <div onClick={(( )=>{ setChanPicker(true); setSecChanPicker(false) })}  className="primary" style={{backgroundColor: channColor}}>
                                            <span><FontAwesomeIcon icon={faPaintRoller}/></span>
                                            {chanPicker &&  <> <Fade right>
                                                <ColorPicker width={310} height={160} color={primaryChanColor}  onChange={setPrimaryChanColor} hideHSV dark />
                                            </Fade></>}
                                        </div>
                                    </div>

                                    <div className="colorOption">
                                        <h1>Secondary</h1>
                                        <div onClick={(( )=>{ setSecChanPicker(true); setChanPicker(false) })} className="secondary" style={{backgroundColor: secChann_color}}>
                                            <span><FontAwesomeIcon icon={faPaintRoller}/></span>
                                            {secChanPicker &&  <> <Fade right>
                                                <ColorPicker width={310} height={160} color={secondaryChanColor}  onChange={setSecondaryChanColor} hideHSV dark />
                                            </Fade></>}                                 
                                        </div>
                                    </div>
                                </div>

                                <div className='hrw'>
                                    <div className="colorOption">
                                        <h1>Animated Channel Picture </h1>
                                        <button onClick={() => { if (userData?.nitroUnlocks?.animatedChannels){channPfpRef.current.click() }}} > Animated Channel Picture {userData?.nitroUnlocks?.animatedChannels ? <> <FontAwesomeIcon icon={faUpload}/> </> : <> <FontAwesomeIcon icon={faLock}/> </>} </button>
                                        <input ref={channPfpRef} className='inpt' type="file" name="my-image" accept="image/gif" onChange={e => edtUserGif(e.target.files[0], true,false)}  />
                                    </div>

                                    <div className="colorOption">
                                        <h1>Animated Channel Banner</h1>
                                        <button onClick={() => { if (userData?.nitroUnlocks?.animatedChannels){channBannerRef.current.click() }}} > Animated Channel Banner {userData?.nitroUnlocks?.animatedChannels ? <> <FontAwesomeIcon icon={faFileImport}/> </> : <> <FontAwesomeIcon icon={faLock}/> </>} </button>
                                        <input ref={channBannerRef} className='inpt' type="file" name="my-image" accept="image/gif" onChange={e => edtUserGif(e.target.files[0], true,true)}  />

                                    </div>
                                </div>

                            </div>  
                        </div>

                        <div className="previewPrnt"  style={{  "background": `linear-gradient(15deg,${channColor}, ${secChann_color})`}}>
                            <div className="preview">
                                <Fade>
                                <img className='bnr' src={selectedChannel?.channelBanner?.length > 0 ?  selectedChannel.channelBanner : 'https://res.cloudinary.com/dqgvklq2y/image/upload/v1682282054/nitrochat/channels/template2.png'} alt="" />  
                                <img className='pp' src={selectedChannel?.channelPicture?.length > 0  ? selectedChannel.channelPicture : "https://res.cloudinary.com/dqgvklq2y/image/upload/v1682282033/nitrochat/channels/defaultChannel.png" } alt="" />  
                                </Fade>
                                <div className="prvRow">
                                    <div>
                                        <h1>{selectedChannel?.channelName?.length > 0 ? selectedChannel.channelName : "Preview Channel Name"}</h1>
                                        <h2>{selectedChannel.private ? <>Public </> : <>Private </>} Community Channel</h2>     
                                    </div>
                                    <div style={{display: "flex", flexDirection: "row"}}>
                                        <h3><FontAwesomeIcon icon={faMessage}/>  {selectedChannel?.length ? <>{selectedChannel.length}</> : <>10 000</>}  <br/>Messages</h3>
                                         <h3><FontAwesomeIcon icon={faUserGroup}/>  {selectedChannel?.usersIds ? <>{selectedChannel.usersIds}</> : <>10 000</>}  <br/> Members</h3>
                                    </div>
                                </div>
          
                            </div>
                        </div>

               
                        {chanGifEdit && <>  <div className="lds-dual-ring"></div> </>}
                        <button onClick={() => (editChanColors())} style={{backgroundColor: "rgb(0, 149, 255)"}} >Save {selectedChannel?.channelName?.length > 0 ? <>{selectedChannel.channelName}</> : <>Preview Channel</>} Colors
                         {userData?.nitroUnlocks?.channelsThemes ? <> <FontAwesomeIcon icon={faPaintRoller}/> </> : <> <FontAwesomeIcon icon={faLock}/> </>} 
                        </button>
                </div>
            </div>
            </> : <>
            <div className="lds-dual-ring"></div>
            </>}
        </div>
    </div>
    </Fade>
    </>)
}

export default NitroShop
