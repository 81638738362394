import cookies from 'js-cookie';
const axios = require('axios');
const default_url = process.env.REACT_APP_API_REST;


var config = { 
    headers: {
        authorization: `${cookies.get("jwt")}`,
        authentification: `${cookies.get("userId")}`
    }
}

export async function InitiateDm(Data) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/message/initiate/' + Data.params, {
            userId: Data.userId
        }, config);
        return response;
    }catch(error) {
        return [];
    }
}

export async function userDms(Data) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.get(default_url + '/message/' + Data, config);
        return response.data;
    }catch(error) {
        return [];
    }
}



export async function specificDM(Data) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.get(default_url + '/message/dm/' + Data, config);
        return response.data;
    }catch(error) {
        return [];
    }
}





export async function sendDm(id,Data) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/message/dm/' + id, Data, config);
        return response;
    }catch(error) {
        return [];
    }
}


export async function getUnseenMsgs() {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.get(default_url + '/message/unseenMsgs/' + cookies.get('userId'), config);
        return response.data;
    }catch(error) {
        return [];
    }
}

export async function reportUser(userId) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/auth/report/' + userId,{}, config);
        return response;
    }catch(error) {
        return [];
    }
}

export async function blockUser(userId) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/auth/block/' + userId,{}, config);
        return response;
    }catch(error) {
        return [];
    }
}

export async function pinConversation(convId) {
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const response = await axios.post(default_url + '/message/pin/' + convId,{}, config);
        return response;
    }catch(error) {
        return [];
    }
}









