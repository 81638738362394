import { GoogleLogin } from 'react-google-login';
import React, {useState, useEffect} from 'react'
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "../styles/pages/signup.scss"
import {faLaptopCode, faBolt } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import cookies from 'js-cookie';
import Fade from 'react-reveal/Fade';
import {SignUpAuth} from '../services/UserServices';
import {GoogleSignIn} from '../services/OAuthServices';
import Spline from '@splinetool/react-spline';
import { gapi } from 'gapi-script';
import { useNavigate } from "react-router-dom";
import {notify} from '../pages/Login';


function Signup() {
    const [emailResp, setEmailResp] = useState('');
    const [passwordResp, setPasswordResp] = useState('');

    const navigate = useNavigate();

    const [formEmail, setEmail] = useState('');
    const [formPassword, setPassword] = useState('');
    const [formFirstName, setFirstName] = useState('');
    const [formLastName, setLastName] = useState('');

    const G_API_KEY = process.env.REACT_APP_GOOGLE;

    const data = {
        email : formEmail,
        password : formPassword,
        prenom: formFirstName,
        nom: formLastName
    }

    useEffect(() => {
        cookies.remove("jwt");
        cookies.remove("userId");
     }, []);
     
    const handleClick = async () => {
 
        if ( formPassword.length < 6){
            setPasswordResp("Please enter at least 6 characters minimum.")
        }else{
            setPasswordResp("");
            var errLog = await SignUpAuth(data);
            if (!errLog.email && !errLog.password){
                notify(false);
                navigate("/login");
            }else{
                if (errLog.errors.email){
                    setEmailResp(errLog.errors.email);
                }
            }
        }
     
    }

    const handleEmail = event => {
        if (/\S+@\S+\.\S+/.test(event.target.value) === true){
            setEmailResp(' ');
            setEmail(event.target.value);
        }else{
            setEmailResp('Please insert correct E-mail adress');
        }
    }

    useEffect(() => {
     
        (async () => {

            const initClient = () => {
                gapi.client.init({
                clientId: G_API_KEY,
                scope: ''
              });
           };
           gapi.load('client:auth2', initClient);
        })();
     
     }, []);
     

    const onSuccess = async (res) => {
        const SignUpLog = await GoogleSignIn({authToken: res.tokenId});
        if (SignUpLog.status === 200){
            notify(true);
            navigate("/login");
        }else  if (SignUpLog.status === 202){
            setEmailResp('Google Account already registered. Please Log In.');
        }

    }
    const onFailure = (err) => {console.log('failed:', err);};

    return (
        <>
            <div className="body_alt">

            <div className='parent'>

                    <div className="left">

                        <div className="titles">
                            <Fade top>
                            <h6>
                                <span><FontAwesomeIcon icon={faLaptopCode} /> </span> Nitrochat
                            </h6>
                            </Fade>
                            <h1 className='insc_title'>Register</h1>
                            <h2>Welcome, New User</h2>

                        </div>
                        
                        <div className="form">
                        <Fade bottom>

                   

                            <div className="form_text">
                                <h3>
                                    Your E-mail
                                </h3>
                                <input type="text" placeholder="example@gmail.com"  onChange={handleEmail}/>
                                <h2 className='errorHandler'>{emailResp}</h2>
                            </div>
                            <div className="form_text">
                                <h3>Last Name
                                </h3>
                                <input type="text" placeholder="Nom"  onChange={e => setFirstName(e.target.value)}/>
                            </div>

                            <div className="form_text">
                                <h3>First Name
                                </h3>
                                <input type="text" placeholder="Prénom"  onChange={e => setLastName(e.target.value)}/>
                            </div>

                            <div className="form_text">
                                <h3>Password
                                </h3>
                                <input type="password" placeholder="at least 6 characters"   onChange={e => setPassword(e.target.value)}/>
                                <h2 className='errorHandler'>{passwordResp}</h2>
                            </div>
                                                                
                            <button onClick={handleClick}>Create your account  <FontAwesomeIcon icon={faBolt} /></button>

                            <h4>
                                Already have an account ?           
                                <span> <NavLink exact to="/login" className="linktopage" > Log In</NavLink></span>
                            <br/> 
                            </h4>

                            <div className="google_btn">
                                <h1> OR</h1>
                                <GoogleLogin
                                    clientId={G_API_KEY}
                                    buttonText="Log In with Google"
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                    cookiePolicy={'single_host_origin'}
                                    render={renderProps => (
                                        <button onClick={renderProps.onClick} disabled={renderProps.disabled}>Register with Google <FontAwesomeIcon icon={faGoogle} />
                                        </button>
                                      )}
                                />
                            </div>
                  
                            
                            <h5>{(new Date().getFullYear())} @ Nitrochat All Rights Reserved</h5>
                            </Fade>
                        </div>

                    </div>


                <div className="right">
                    <video preload loop playsInline autoFocus autoPlay muted src={(require('../media/signupNitro.mp4'))} alt="Home Template" />
                </div>
        
            
            </div>
                            
            </div>
    </>
    )
}

export default Signup
