import {useEffect, useState} from 'react';
import '../styles/components/header.scss';
import { faLaptopCode, faCoins, faMagnifyingGlass,faFireFlameCurved,faFireFlameSimple,faEnvelope,faCircleUser,faHashtag, faBell, faBook,faCaretLeft, faPowerOff, faLandmark, faBuildingColumns} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {HeaderInfQuery, notificationCount, seachFor} from '../services/UserServices';
import {getUnseenMsgs} from '../services/MessageServices';
import {useDispatch, useSelector} from "react-redux";
import{setFilterTrends} from '../feature/trendSlice'
import {setNavigation, setNotifications, updateBlockedUsers, updateMaskedNotifs} from "../feature/navigationSlice";
import { Link } from 'react-router-dom';
import cookies from 'js-cookie';
import {switchModalState} from '../feature/commentSlice';
import {setProfileId, setBookmarkedPosts} from '../feature/profileSlice';
import Fade from 'react-reveal/Fade';
import {nFormatter} from "../services/utils";

function Header() {
    const dispatch = useDispatch();
    const navigationState = useSelector((state) => state.navigation);
    const filterState = useSelector((state) => state.trends.filterTrend);
    const userModification = useSelector((state) => state.profile.editQuery);

    const [data, setData] = useState({});
    const [activeNav, setActiveNav] = useState('#home');
    const [unseenMessages, setUnseenMessages] = useState(0);
    const [notifLen, setNotifLen] = useState({});
    function switchNavigation(data) {
        setActiveNav(data);
    } 

    const QueryFunc = async () => {
        const StoredData = await HeaderInfQuery();
        dispatch(updateBlockedUsers(StoredData.getUser.blockedUsers));
        dispatch(updateMaskedNotifs(StoredData.getUser.notifMutedUsers));
        dispatch(setBookmarkedPosts(StoredData.getUser.bookmarks));
        setData(StoredData.getUser);
    }  
    useEffect(() => {
        dispatch(setNavigation(activeNav));
        dispatch(switchModalState(false));
        QueryFunc();
        document.addEventListener("click", handleOutsideClick);
    }, [activeNav])

    useEffect(() => {
        QueryFunc();
    }, [userModification]);
    
    const notifCount = async () => {
        const e=  await notificationCount(cookies.get("userId")); setNotifLen(e);
        dispatch(setNotifications(e));
        const i = await getUnseenMsgs();
        setUnseenMessages(i);
    }

    useEffect(() => {
        notifCount();
    }, []);

    useEffect(() => {
        setActiveNav( navigationState.navigation);
        let p = Math.floor(Math.random() * 3);
        if (p === 1){
            notifCount();
        }
    }, [navigationState.navigation]);

    
    const SwitchToMessagePage = async () => {
        dispatch(setNavigation('#userProfile'));
        dispatch(setProfileId(cookies.get('userId')));
    };
    const [seachValue, setSearchValue] = useState("");
    const [fetchedSearch, setFetchedSearch] = useState({});
    const [isSearching, setIsSearching] = useState(false);

    useEffect(() => {
        setIsSearching(true);
        (async () => {
            if (seachValue.length > 1){
                const refreshedSearch = await seachFor(seachValue.toLowerCase().replace(/[^0-9a-z-]/g,""));
                setFetchedSearch(refreshedSearch);
                setIsSearching(false);
            }
        })();
    }, [seachValue]);

    const SwitchToProfilePage = async (ProfileId) => {
        dispatch(setNavigation('#userProfile'));
        dispatch(setProfileId(ProfileId));
        setSearchValue("");
    };
    const handleOutsideClick = (event) => {
        const hasClass =  event.target.classList.contains('searchResponses');
        if (hasClass === false){
            setSearchValue("");
        }
    }
    return (
        <div className='header_bg'>
            <div className="left_part">
                <span>
                <FontAwesomeIcon icon={faLaptopCode} /> 
                </span>
                <small  onClick={() => switchNavigation('#home')}>
                    Nitrochat
                </small>
                <div className="headerColumn">

                    <div className="searchBar">
                        <input type="text" maxLength={45} placeholder="Explore on Nitrochat"
                        onChange={e => setSearchValue(e.target.value) }
                        />
                        <FontAwesomeIcon icon={faMagnifyingGlass} className='searchIcon' /> 
                    </div>
                   

                    {seachValue.length > 0 && <>
                        <div className="searchResponses_stg">
                            <h1><FontAwesomeIcon icon={faMagnifyingGlass} />  Searching for <strong>{seachValue}</strong></h1>

                            {isSearching === true ? <>
                                <div className="lds-dual-ring"></div>
                            </>: <>
                                {(fetchedSearch?.searchProfiles?.length > 0 || fetchedSearch?.searchTrends?.length > 0) ? <>
                                    {fetchedSearch?.searchTrends.map((trend) => (
                                        <div className="trendSearched" onClick={() =>  {dispatch(setFilterTrends(trend));  dispatch(setNavigation('#home')); setSearchValue("")  }}>
                                            <Fade>
                                            <p>{trend}</p>
                                            </Fade>
                                        </div>
                                    ))} 

                                    {fetchedSearch?.searchProfiles.map((profile) => (
                                        <Link to={`/forum/profile/@${profile.pseudo}`} >
                                            <Fade bottom>
                                            <div className="profileSearched"  onClick={() => {SwitchToProfilePage(profile._id); setSearchValue("") }}>
                                                <div>
                                                    <img src={profile.imageUrl} alt="" />
                                                </div>
                                                <div>
                                                    <h2>{profile.prenom} {profile.nom} </h2>
                                                    <h2 className='gry'>@{profile.pseudo}</h2>
                                                </div>
                                            </div>
                                            </Fade>
                                        </Link>
                                    ))} 
                                    
                                    </> : (<>

                                    <h4>No results match <small>your search </small> criteria.</h4>
                                    <Link to={`/forum/explore`} >
                                        <h5 onClick={() => (dispatch(setNavigation('#explore')))}>Explore on Nitrochat  <FontAwesomeIcon icon={faHashtag} /></h5>
                                    </Link>
                                </>)}
                          
                            </>}
       
                        </div>
                    </>}

                </div>

            </div>
            <div className="right_part">

                <div className='right_child'>
                    <Link to={`/forum/`} >
                        <div className={activeNav === '#home' ? 'selected' : ''} onClick={() => switchNavigation('#home')} >
                            <button >{activeNav === '#home' ? <FontAwesomeIcon icon={faBuildingColumns} /> : <FontAwesomeIcon icon={faLandmark} />}</button>
                            {activeNav === '#home' && <><span>Home</span></>}
                        </div>

                    </Link>

                    <Link to={`/forum/nitro/`} >
                        <div className={activeNav === '#nitro' ? 'selected' : ''} onClick={() => switchNavigation('#nitro')}>
                            <button ><FontAwesomeIcon icon={faCoins} /> </button>
                            {activeNav === '#nitro' && <><span>Nitroshop</span></>}
                        </div>
                    </Link>

                    <Link to={`/forum/channels/`} >
                        <div className={activeNav === '#liveChat' ? 'selected' : ''} onClick={() => switchNavigation('#liveChat')}>
                            <button ><FontAwesomeIcon icon={faLaptopCode} /> </button>
                            {activeNav === '#liveChat' && <><span> Channels</span></>}
                        </div>
                    </Link>

                    <Link to={`/forum/profile/`} >
                        <div className={activeNav === '#userProfile' ? 'selected' : ''} onClick={() => SwitchToMessagePage()} >
                            <button ><FontAwesomeIcon icon={faCircleUser} /></button>
                            {activeNav === '#userProfile' && <><span>Profile</span></>} 
                        </div>

                    </Link>
                    <Link to={`/forum/explore/`} >
                        <div className={activeNav === '#explore' ? 'selected' : ''} onClick={() => switchNavigation('#explore')} >
                            <button ><FontAwesomeIcon icon={faHashtag} /></button>
                            {activeNav === '#explore' && <><span>Explore</span></>} 
                        </div>

                    </Link>
                    <Link to={`/forum/messages/`} >
                        <div className={activeNav === '#userMessages' ? 'selected notiff' : 'notiff'} onClick={() => switchNavigation('#userMessages')}>
                            <button><FontAwesomeIcon icon={faEnvelope} /></button>
                            {activeNav === '#userMessages' && <><span>Messages</span></>}
                            {unseenMessages > 0 &&<>
                                <div className="notifCounts">
                                    <small>{unseenMessages}</small>
                                </div>
                            </>}
                        </div>

                    </Link>
                        
                    <Link to={`/forum/notifications/`} >
                        <div className={activeNav === '#notifications' ? 'selected notiff' : 'notiff'} onClick={() => switchNavigation('#notifications')}>
                            <button className='' ><FontAwesomeIcon icon={faBell} /></button>
                            {activeNav === '#notifications' && <><span>Notifications</span></>} 
                            {notifLen.len > 0 &&<>
                                <div className="notifCounts">
                                    <small>{notifLen.len}</small>
                                </div>
                            </>}
                        
                        </div>
            
                    </Link>

                    <Link to={`/forum/bookmarks/`} >
                        <div className={activeNav === '#bookmarks' ? 'selected' : ''} onClick={() => switchNavigation('#bookmarks')}>
                            <button ><FontAwesomeIcon icon={faBook} /> </button>
                            {activeNav === '#bookmarks' && <><span> Bookmarks</span></>}
                        </div>

                    </Link>

                </div>
                
                {activeNav !== '#nitro' && <>
                    <div className='nitroinfs'>
                            <Fade>
                                <h3 className='ntro_title'>NITRO {data.nitro && <> {nFormatter(data.nitro, 1)} </>}  </h3>
                                <h2 className='ntro'> <FontAwesomeIcon icon={faFireFlameCurved} /></h2>  
                            </Fade>
                    </div>
                </>}

                <Link to={`/login/`} >
                    <div className="disconnect">
                        {data?.imageUrl?.length > 0 && <>
                            <img src={data.imageUrl} alt="" />
                        </>}
                        <p>{data.prenom} {data.nom} </p>
                        <p className='eb'><FontAwesomeIcon icon={faCaretLeft} /></p>
                        
                        <button>DISCONNECT <FontAwesomeIcon icon={faPowerOff} /></button>
                    </div>  
                </Link>

            </div>
        </div>
    )
}
export default Header
