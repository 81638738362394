const axios = require('axios');
const default_url = process.env.REACT_APP_API_REST;


export async function getAllCounts() {
    try{
        const response = await axios.get(default_url + '/auth/counts');
        return response;
    }catch(error) {
        return [];
    }
}
