export const dateParser = (num, mode) => {
    let options = {
      hour: "2-digit",
        minute: "2-digit",
 //    second: "2-digit",
        weekday: "long",
        year: "numeric",
        month: "short",
        day: "numeric",
    };

    let options_2 = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
    }
    const options3 = {day: 'numeric', month: 'long', hour: "2-digit"};

    const options4 = {day: 'numeric', month: 'long', year: "numeric"};

    const options5 = {day: 'numeric', month: 'short', year: "numeric", hour: "2-digit", minute: "2-digit"};

    const options6 = { year: "numeric",weekday: "long",day: 'numeric', month: 'long', hour: "2-digit", minute: "2-digit"};

    const liveChat = { year: "numeric", day:  "2-digit", month:  "2-digit", hour: "2-digit", minute: "2-digit"};

    const liveChatProfile = { year: "numeric", day:  "2-digit", month:  "long", hour: "2-digit", minute: "2-digit"};

    const options9 = {day: 'numeric', month: 'short', hour: "2-digit", minute: "2-digit"};

    let timestamp = Date.parse(num);
    if (mode == 1){////////////// COMMENT DATE 
        let date = new Date(timestamp).toLocaleDateString("en-US", options);
        return date.toString();

    }else if (mode == 2){////////// PROFILE DATE 
        let date = new Date(timestamp).toLocaleDateString("en-US", options_2);
        return date.toString().replace(',' ,'');

    }else if (mode == 3){///// MESSAGE DATE
        let date = new Date(timestamp).toLocaleDateString("en-US", options3);
        return date.toString().replace(',' ,'');

    }else if (mode == 4){///// DM profile
        let date = new Date(timestamp).toLocaleDateString("en-US", options4);
        return date.toString().replace(',' ,'');
    }
    else if (mode == 5){///// DM DATE
        let date = new Date(timestamp).toLocaleDateString("en-US", options5);
        return date.toString().replace(',' ,'');
    }
    else if (mode == 6){///// SPECIFIC MESSAGE
        let date = new Date(timestamp).toLocaleDateString("en-US", options6);
        return date.toString().replaceAll(',' ,'');
    }
    else if (mode == 7){///// SPECIFIC MESSAGE
        let date = new Date(timestamp).toLocaleDateString("en-US", liveChat);
        return date.toString().replaceAll(',' ,'');
    }
    else if (mode == 8){///// SPECIFIC MESSAGE
        let date = new Date(timestamp).toLocaleDateString("en-US", liveChatProfile);
        return date.toString().replaceAll(',' ,'');
    }
    else if (mode == 9){///// SPECIFIC MESSAGE
        let date = new Date(timestamp).toLocaleDateString("en-US", options9);
        return date.toString().replaceAll(',' ,'');
    }
}

export const nFormatter = (num, digits)  => {
    var si = [
      { value: 1, symbol: "" },
      { value: 1E3, symbol: "K" },
      { value: 1E6, symbol: "M" },
      { value: 1E9, symbol: "G" },
      { value: 1E12, symbol: "T" },
      { value: 1E15, symbol: "P" },
      { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    // for negative value is work
    for (i = si.length - 1; i > 0; i--) {
      if (Math.abs(num) >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}