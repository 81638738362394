import React from 'react';
import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Nitrochat from './pages/Nitrochat';
import PageNotFound from './pages/PageNotFound';
import { BrowserRouter, Route,Routes, createBrowserRouter } from "react-router-dom";

function App() {
  return (
  <BrowserRouter>
    <Routes>
    <Route path="*" element={<PageNotFound/>}/>
      <Route path="/home" element={<Home/>}/>
      <Route path="/" element={<Home/>}/>
      <Route path="/login/*" element={<Login/>}/>
      <Route path="/signup" element={<Signup/>}/>
      <Route path="/forum/*" element={<Nitrochat/>}/>
    
    </Routes>
  </BrowserRouter>
  )
}

export default App
