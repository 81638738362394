import { createSlice } from "@reduxjs/toolkit";
import cookies from 'js-cookie';

export const postsSlice = createSlice({
    name: "posts",
    initialState: {
        posts: [],
    },
    reducers: {
        setPostsData: (state, {payload}) => {
            if(state.posts){
                state.posts = payload;
            }
        },
        addPost: (state, {payload}) => {
            state.posts.push(payload);
        },
        setPostComments: (state, {payload}) => {
            state.posts[payload[0]].post.comments = payload[1];
        },
        deleteComment: (state, {payload}) => {
            state.posts[payload[0]].post.comments.splice([payload[1]], 1 );
        },
        pushNewComment: (state, {payload}) => {
            state.posts[payload[0]].post.comments.push(payload[1]);
        },
        likeComment: (state, {payload}) => {
            if (payload[1]){
                state.posts[payload[2]].post.comments[payload[0]].comment.likes.push(cookies.get("userId"));
            }else{
                const index = state.posts[payload[2]].post.comments[payload[0]].comment.likes.findIndex(likeObj => {
                    return likeObj === cookies.get("userId")
                  });
                state.posts[payload[2]].post.comments[payload[0]].comment.likes.splice(index, index + 1);
            }
        },
        pushTrendPosts: (state, {payload}) => {
            try{
                payload?.forEach(i => { 
                    if (i !== 0 && i?.post && i){
                        if (state.posts){
                            state.posts.push(i);
                        }
                    }
                });
            }catch(err){
                return 0;
            }
     
        }
    }
})
export const {setPostsData, addPost,setPostComments,deleteComment, pushNewComment, likeComment, pushTrendPosts} = postsSlice.actions;
export default postsSlice.reducer;
