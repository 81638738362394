import {useEffect, useState} from 'react'
import '../styles/pages/userBookmarks.scss'
import {useDispatch, useSelector} from "react-redux";
import cookies from 'js-cookie';
import {getBookmarkedPosts} from '../services/PostServices';
import Message from '../components/Message';
import Fade from 'react-reveal/Fade';

function UserBookmarks() {
    const [userPosts, setUserPosts] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const bookPosts = useSelector((state) => state.profile.bookmarkedPosts);

    useEffect(() => {
        (async () => {
            const r = await getBookmarkedPosts();
            setUserPosts(r.getUserBookmarks);
            setLoaded(true);
        })();
    }, [bookPosts]); 
    return (
        <div className='Bookmarks_Page'>
            {loaded ? <>
                <h1 className='tttle'>Bookmarks</h1>
                {userPosts?.length > 0 && <> <h2 className='titlesnc'>{userPosts.length} Posts <span>Bookmarked</span> </h2></>}
                {userPosts?.length > 0 && <>
                    {userPosts?.map((eachPost, index) => (
                        <>
                            <div className='bookmark'>
                                <Message key={index} Message_={eachPost} />
                            </div>                       
                        </>
                    )).reverse()}     
                </>}
  

                <Fade top>
                        {userPosts.length == 0 && <>
                            <div className="noBookmarks">
                                <img src={require('../media/bookmarks.png')} alt="" />
                                <h2>Save Nitrochats For Later !</h2>
                                <h3>Don't let good nitrochats fly away ! <br/> Add them to bookmarks so that you can retreive them in the future.</h3>
                            </div>
                
                        </>}
                </Fade>
            </> : <>
                <div className="lds-dual-ring"></div>
            
            </>}

        </div>
    )
}

export default UserBookmarks
