import {useEffect, useState, useRef, createRef} from 'react'
import '../styles/components/channelFriends.scss'
import {getFriends, inviteFriends} from '../services/ChannelServices';
import Fade from 'react-reveal/Fade';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCheck, faEnvelope, faXmark, faBan} from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch} from "react-redux";
import {updateChannels, closeFrndWindow} from '../feature/channelSlice';
import {setNavigation} from "../feature/navigationSlice";

function ChannelFriends({bannedUsers, channelUsers, channelId}) {

    const dispatch = useDispatch();
    const [usersData, setUsersData] = useState([]);
    const [alrList, setAlrList] = useState([]);
    const [idList, setIdList] = useState([]);
    const [searchBar, setSearchBar] = useState("");
    const [int32, setInt32] = useState(0);

    useEffect(() => {    
        (async () => {
            const r = await getFriends();
            setUsersData(r.data);
            const e = [];

            await Promise.all(channelUsers?.map(async (user,index) => {
                e.push(user.userId);
            }));
            setAlrList(e);

            var i = 0;
            
            await Promise.all( r.data.map(async (friend, index) => {
                if (e.includes(friend._id) === true){
                    i ++;
                }
            }));
            setInt32(i);
        })();
    }, []);

    const addTo = async (id) => {
        const indx = idList.indexOf(id);
        if (indx === -1){
            if (idList.length < 6){
                setIdList([...idList, id]);
            }
        }else{
            setIdList(idList.filter(prevId => prevId !== id));              
        }
    };

    const invite = async () => {
        const inv = await inviteFriends(channelId, idList);
        dispatch(updateChannels());
    };
    return (
        <Fade top>
            <div className='addFriends_window'>
                <div className='toppp'>
                    <div>
                        <h1>Select some friends</h1>
                        <p>You can still add {6 - idList.length} other friends.</p>
                    </div>
                    <div>
                        <button onClick={() => (dispatch(closeFrndWindow()))} className='close'>CLOSE <FontAwesomeIcon icon={faXmark}/></button>
                    </div>
                </div>
       
                <input type="text" placeholder='Enter the name of your friend.' onChange={e => setSearchBar(e.target.value)}/>
                
                <div className="tags">
                    {usersData?.map((aUser, index) => (<>
                        {idList.includes(aUser._id) === true &&  <>
                            <p className='tag'  onClick={() => (addTo(aUser._id))}><FontAwesomeIcon icon={faXmark}/> {aUser.nom} {aUser.nom}</p>
                        </>}
                    </>))}
                </div>
           
                <div className="usersScroll">
                    {usersData?.length > 0 ? <>
                        {int32 === usersData.length ? <>
                            <h1>You have no one to invite.</h1>
                            <p>Maybe all of your friends are already in this channel ?</p>
                            <button  onClick={() => (dispatch(setNavigation('#explore')))} className='moreInvite'>Go find some other friends</button>
                        </> : <>
                            {usersData?.map((aUser, index) => (<>
                                {!bannedUsers.includes(aUser._id) && <>        
                                    {searchBar.length > 0 ? <>
                                        {aUser.nom.toLowerCase().includes(searchBar.toLowerCase()) === true ||  aUser.prenom.toLowerCase().includes(searchBar.toLowerCase()) === true || aUser.pseudo.toLowerCase().includes(searchBar.toLowerCase()) === true ? <>
                                            {alrList.includes(aUser._id) === false &&  <>
                                                <div className="userRow" onClick={() => (addTo(aUser._id))}>
                                                    <div className="lef">
                                                        <img src={aUser.imageUrl} alt="" />
                                                        <h3>{aUser.prenom} {aUser.nom}</h3>
                                                        <h4>@{aUser.pseudo}</h4>
                                                    </div>

                                                    <div className={idList?.includes(aUser._id) === true ? "case checked" : "case"}>
                                                        { idList?.includes(aUser._id) === true && <> <h1><FontAwesomeIcon icon={faCheck}/></h1></> }
                                                    </div>
                                                </div>
                                            </>} 
                                        </> : <>
                                        {index === 2 && <> </>}
                                        </>} 
                                    </>: <>

                                    {alrList.includes(aUser._id) === false &&  <>
                                            <div className="userRow" onClick={() => (addTo(aUser._id))}>
                                                <div className="lef">
                                                    <img src={aUser.imageUrl} alt="" />
                                                    <h3>{aUser.prenom} {aUser.nom}</h3>
                                                    <h4>@{aUser.pseudo}</h4>
                                                </div>

                                                <div className={idList?.includes(aUser._id) === true ? "case checked" : "case"}>
                                                    { idList?.includes(aUser._id) === true && <> <h1><FontAwesomeIcon icon={faCheck}/></h1></> }
                                                </div>
                                            </div>
                                        </>} 
                                    </>}
                                </>}
                            </>))}

                            {usersData?.map((aUser, index) => (<>
                                {bannedUsers.includes(aUser._id) && <>        
                                    <div className="userRow banned">
                                        <div className="lef">
                                            <img src={aUser.imageUrl} alt="" />
                                            <h3>{aUser.prenom} {aUser.nom}</h3>
                                            <h4>@{aUser.pseudo}</h4>
                                        </div>

                                        <div>
                                            <h5>BANNED <FontAwesomeIcon icon={faBan}/></h5>
                                        </div>
                                    </div>
                                </>}
                            </>))}
                        </>}
                    </> : <>
                        <h1>Get friends first.</h1>
                        <p>You can find some really cool people on Nitrochat !</p>
                    </>}
          
                </div>
           
                <button onClick={() => (invite())}>INVITE {idList.length} NEW FRIENDS <FontAwesomeIcon icon={faEnvelope}/></button>
            </div>
        </Fade>
    )
}

export default ChannelFriends
