import {useState, useEffect} from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHashtag, faCheck, faEnvelope, faCircleUser , faCircleCheck, faBolt} from '@fortawesome/free-solid-svg-icons'
import '../styles/pages/explore.scss';
import {ExploreReslv} from '../services/UserServices';
import {setExploreMode, setNavigation} from "../feature/navigationSlice";
import {useDispatch, useSelector} from "react-redux";
import{setFilterTrends} from '../feature/trendSlice'
import Fade from 'react-reveal/Fade';
import {setProfileId} from '../feature/profileSlice';
import cookies from 'js-cookie';
import Message from '../components/Message';
import {InitiateDm, specificDM} from '../services/MessageServices';
import {updateMainMessage} from '../feature/messageSlice';

function Explore() {
    const [exploreData, setExploreData] = useState({});
    const [dataLoaded, setDataLoaded] = useState(false);
    const exploreTrends = useSelector((state) => state.navigation.exploreShowTrends);
    const personnalTrends = useSelector((state) => state.trends.personnalTrends);
    const countryTrend = useSelector((state) => state.trends.countryTrends);
    const suggs = useSelector((state) => state.navigation.suggsFollow);

    const dispatch = useDispatch();
    const [optionChoosed, setOptionChoosed] = useState(false);
    const [pageJump, setPageJump] = useState(false);
    const [profiles, setProfiles] = useState([]);
    const [trends, setTrends] = useState([]);

    const directMessage = async (id) => {
        const r = await InitiateDm({
             params: cookies.get("userId"),
             userId : id
         })
         const e = await specificDM(r.data);
         dispatch(updateMainMessage(e));
         dispatch(setNavigation('#userMessages'));   
     
     }

    useEffect(() => {
        (async () => {
            const explr = await ExploreReslv();
            setExploreData(explr.explore);
            const shuffled = explr.explore.profiles.sort((a, b) => 0.5 - Math.random());
            const a = shuffled.slice(5, 10).map((profile, index) => {
                if (!suggs.includes(profile.id) && profile.id !== cookies.get('userId')){return profile}
            });
            let rd = Math.floor(Math.random() * 34);
            const b = explr.explore.trends.slice(rd, rd + 6).map((tr, index) => { return tr });
            setTrends(b); setProfiles(a); setDataLoaded(true);
        })();
    }, [])

    const switchToProfile = async (profileId) => {
        dispatch(setNavigation('#userProfile')); dispatch(setProfileId(profileId));
    };

    const switchTrend = async (trend) => {
        dispatch(setNavigation('#home')); dispatch(setFilterTrends(trend));
    };

    return (
        <div className='explorePage'>
            {dataLoaded === true ? (<>
                <h1 className='mainTitle'>Explore on Nitrochat</h1> 

                {pageJump ? <>      
                    <div className="dataMapping">
                        {exploreTrends === true ? (<>
                            {exploreData?.trends.map((trend, index) => (
                            <Fade>
                                <div className="aMap" onClick={() => (switchTrend(trend.trendName))}>
                                    { personnalTrends.findIndex((fltrdTrend) => fltrdTrend.trendName == trend.trendName ) !== -1 ? <>
                                        <h5>Trending in your followings </h5>
                                    </> : <>
                                        {countryTrend.findIndex((countryTr) => countryTr.trendName == trend.trendName ) !== -1 && 
                                        <> <h5>Trending in your country </h5> </>}
                                    </>}
                                    <h1>{trend.trendName}</h1>
                                    <h2>{trend.trendCount} Nitrochats</h2>
                                </div>
                            </Fade>
                            ))}
                        </>): (<>
                            {exploreData?.profiles.map((profile, index) => (<>
                            <Fade>
                                <div className='prfileCard' onClick={() => (switchToProfile(profile.id))} > 
                                    <div className='row'>
                                        <img src={profile.imageUrl} alt="" />
                                        <div className='names'>
                                            <h3>{profile.prenom} {profile.nom} {profile.verified==true&&<><i style={{color: "#1da1f2"}}> <FontAwesomeIcon icon={faCircleCheck}/></i> </>}</h3>
                                            <h4>@{profile.pseudo}</h4>
                                            <p>{profile.biography}</p>
                                        </div>
                                    </div>

                                    {profile.id !== cookies.get("userId") && <>
                                        <button className={profile.followers_Ids.includes(cookies.get("userId")) == true ? 'followed' : 'notfollowed'  }>
                                            {profile.followers_Ids.includes(cookies.get("userId")) == true ? <>
                                                Followed  <FontAwesomeIcon icon={faCheck} />
                                            </>: <>
                                                Follow
                                            </>  }
                                        </button>
                                    </>}
                                </div>
                            </Fade>
                            </>))}
                        </>)}
                    </div>
       
                </> : <>
                    <div className="users_suggz">
                        <h6 className='ttle let'>Discover new users   </h6>
                        {profiles?.map((z, index) => ( <>
                            <Fade>
                            <div className="flex_usr">
                                <div className="flex_le">
                                    <img className='usr_pfp' src={z?.imageUrl} alt="" />
                                    <img className='usr_bnr' src={z?.profileBanner} alt="" />
                                    <div className="txts">
                                        <h1>{z.prenom} {z.nom}</h1>
                                        <h2>@{z.pseudo}</h2>
                                    </div>
                                </div>
                                <div className="flex_ri">
                                    <button onClick={() => (directMessage(z.id))} className='dm'><FontAwesomeIcon icon={faEnvelope}/></button>
                                    <button onClick={() => (switchToProfile(z.id))}> <FontAwesomeIcon icon={faCircleUser}/> </button>
                                </div>
                            </div>
                            </Fade>
                        </>))}
                        <p className='more' onClick={() => {setPageJump(true)}}>Show More</p>
                    </div>

                    <div className="dataMapping homemap">
                        <h6 className='ttle' style={{'margin-left': '18px'}}>Discover Nitrochat Trends  </h6>
                        <div className="mappp">
                            {trends.map((trend, index) => (
                            <Fade>
                                <div className="aMap" onClick={() => (switchTrend(trend.trendName))}>
                                    { personnalTrends.findIndex((fltrdTrend) => fltrdTrend.trendName == trend.trendName ) !== -1 ? <>
                                        <h5>Trending in your followings </h5>
                                    </> : <>
                                        {countryTrend.findIndex((countryTr) => countryTr.trendName == trend.trendName ) !== -1 && 
                                        <> <h5>Trending in your country </h5> </>}
                                    </>}
                                    <h1>{trend.trendName}</h1>
                                    <h2>{trend.trendCount} Nitrochats</h2>
                                </div>
                            </Fade>
                            ))}
                        </div>   
                        <p className='more botmore' onClick={() => {setPageJump(true)}}>Show More</p>
                    </div>


                    <div className="pinned">
                        <h1 className='pinedTitle'>Most Interacted Post of the Month</h1>
                        <Message Message_={exploreData.pinnedPost} />
                    </div>

                    <div className="messages_threads">
                        {exploreData.mediaPosts.map((post) => (
                            <> <Message Message_={post} /> </>
                        ))}
                    </div>
                 
                
                </>}

            </>) : (<>
                <div className='loading'>
                    <div className="lds-dual-ring"></div>
                </div>    
            </>)}
        </div>
    )
}

export default Explore


