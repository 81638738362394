import { faL } from "@fortawesome/free-solid-svg-icons";
import { createSlice, current } from "@reduxjs/toolkit";
import store from "../app/store";

export const navigationSlice = createSlice({
    name: "navigation",
    initialState: {
        navigation: ["home"],
        hashtags: [],
        notifCount : 0,
        specificMessageId: "",
        previousNavigationState : "",
        exploreShowTrends: false,
        blockedUsers : [],
        maskedNotifsIds : [],
        suggsFollow : [],
        showMmbrsThread : false
    },
    reducers: {
        setNavigation: (state, {payload}) => {
            state.navigation = payload;
        },
        pushNewHashtags: (state,{payload} ) => {
            if (state.hashtags.length < 6){
              if (current(state.hashtags).includes(("#" + payload)) == false){
                state.hashtags.push("#" + payload);
              }
            }
        },
        resetHashtags: (state, {payload}) => {
            state.hashtags = []
        },
        setNotifications: (state, {payload}) => {
            state.notifCount = payload.len
        },
        setSpecificMessageId: (state, {payload}) => {
            state.specificMessageId = payload
        },
        setPreviousNavState: (state, {payload}) => {
            state.previousNavigationState = payload
        },
        setExploreMode: (state, {payload}) => {
            state.exploreShowTrends = payload
        },
        updateBlockedUsers: (state, {payload}) => {
            state.blockedUsers = payload;
        },
        updateMaskedNotifs: (state, {payload}) => {
            state.maskedNotifsIds = payload
        },
        updateSuggs: (state, {payload}) => {
            state.suggsFollow = payload;
        },
        showAll: (state, {payload}) => {
            state.showMmbrsThread = payload;
        }
    }
})
export const {setNavigation, pushNewHashtags, resetHashtags, updateBlockedUsers,updateMaskedNotifs,updateSuggs,
    setNotifications, setSpecificMessageId, setPreviousNavState, setExploreMode, showAll} = navigationSlice.actions;
export default navigationSlice.reducer;
