import {useEffect, useState} from 'react';
import '../styles/components/message.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRetweet,faCommentDots, faTrashCan,faAngleDown,faHeart, faFontAwesome, faBookmark, faThumbsUp, faCircleCheck, faMedal, faBolt } from '@fortawesome/free-solid-svg-icons'
import Fade from 'react-reveal/Fade';
import {dateParser} from '../services/utils';
import cookies from 'js-cookie';
import {DeleteMessage, LikeMessage, RetweetMessage,
     LoadComment_, RemoveComment, LikeComment, ReportPost, BookmarkPost} from '../services/PostServices';
import { Link } from 'react-router-dom';
import {setFilterTrends } from '../feature/trendSlice';
import {setNavigation, setSpecificMessageId, setPreviousNavState} from "../feature/navigationSlice";
import {deleteComment,likeComment} from "../feature/postsSlice";
import {setProfileId, setBookmarkedPosts} from '../feature/profileSlice';
import {useDispatch,useSelector} from "react-redux";
import {switchModalState, setModalContent} from '../feature/commentSlice'
import {notify} from '../pages/Nitrochat';
import MessageRelation from '../components/messageRelation';
import 'animate.css';
import { faSpeakerDeck } from '@fortawesome/free-brands-svg-icons'

function Message(MessageObj) {
    const [profileCard, setProfileCard] = useState({});
    const [messageInfo, setMessageInfo] = useState({});
    const [topComm, setTopComm] = useState({});

    const bookPosts = useSelector((state) => state.profile.bookmarkedPosts);

    const dispatch = useDispatch();

    const [userOwnMsg, setUserOwnMsg] = useState(false);
    const [messageDeleted, setMessageDelete] = useState(false);

    const [messageLiked, setMessageLiked] = useState(false);
    const [messageRetweeted, setMessageRetweeted] = useState(false);
    
    const [likeInt, setLikeInt] = useState(0);
    const [retweetInt, setRetweetInt] = useState(0);

    const [postIndex, setPostIndex] = useState(0);
    const [postImage, setPostImage] = useState(false);

    const postsData = useSelector((state) => state.posts.posts);
    const date = dateParser(messageInfo.createdAt, 3);
    //let obj = Object.values(MessageObj)[0];

    useEffect(() => {
        setProfileCard(Object.values(Object.values(MessageObj)[0])[1]);
        setTopComm(Object.values(Object.values(MessageObj)[0])[2]);
        if (cookies.get("userId") === (Object.values(Object.values(MessageObj)[0])[1]?.id)){
            setUserOwnMsg(true);
        }
    }, []);

    const indexPostRedux = async () => {
       const ind = await postsData.findIndex(postObject => {
            return postObject.post._id === messageInfo._id
          });
        setPostIndex(ind);
    }
    
    useEffect(() => {
        setMessageInfo(Object.values(Object.values(MessageObj)[0])[0]);
        if (Object.keys(messageInfo).length > 1){
            UpdateIntercationsStates(messageInfo.usersLiked, setMessageLiked);
            UpdateIntercationsStates(messageInfo.userRetweets, setMessageRetweeted);
            setRetweetInt(messageInfo.retweets);
            setLikeInt(messageInfo.likes);
            indexPostRedux();
        }
    },[messageInfo]);

    const handleDeletion = async () => {
        setMessageDelete(true);
        DeleteMessage(messageInfo._id);
    }

    const UpdateIntercationsStates = async (Mode, StateMode) => {
        const indexOfUser = await Mode?.indexOf(cookies.get('userId'));
        if (indexOfUser === -1){
            StateMode(false);
        }else{
            StateMode(true);
        }
    }
    
    const MessageInteraction = async (stateInt ,setStateInt, queryFunc, alreadyBool, setAlreadyBool) => {
        if (!blockInteraction){
            delayInteraction(300);
            await queryFunc(messageInfo._id, cookies.get('userId')); 
            if (alreadyBool){
                setStateInt(stateInt - 1);
            }else{
                setStateInt(stateInt + 1);
            }
            setAlreadyBool(!alreadyBool);
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////////////
    const commentsData =(useSelector((state) => state?.posts?.posts)?.filter(eachPost => eachPost?.post?._id === messageInfo._id));
    const [commentsLength, setCommentsLength] = useState(0);
    const [commentsLoaded, setCommentsLoaded] = useState(false);
    const [showComments, setShowComments] = useState(false);

    useEffect(() => {
        if (Object.values(commentsData)[0] != null){
           setCommentsLength( (Object.values(commentsData)[0] ).post.comments.length);
        }
    }, [commentsData]);

    const LoadComments = async () => {
        if (commentsLoaded == false){
            const index = await  postsData.findIndex(postObject => {
                return postObject.post._id === messageInfo._id
              });
            await LoadComment_(index, messageInfo._id);
            setCommentsLoaded(true);
            
            return;
        }
        setShowComments(!showComments);
    }
    
    const deleteCommentRedux = async (commentKey, CommentId) =>{
        await RemoveComment(CommentId, messageInfo._id);

        const index = await  postsData.findIndex(postObject => {
            return postObject.post._id === messageInfo._id
          });

        dispatch(deleteComment([ index,commentKey]));
    };


    const likeCommentRedux = async (CommentId, CommentIndex) =>{
        const likeResp = await LikeComment({
            userId: cookies.get("userId"),
            commentId: CommentId
        }, messageInfo._id);
        const index = await  postsData.findIndex(postObject => {
            return postObject.post._id === messageInfo._id
        });
        if(likeResp === 200){
            dispatch(likeComment([CommentIndex, true, index]));
        }else{
            dispatch(likeComment([CommentIndex, false, index]));
        }
    
    };

    const SwitchToProfilePage = async (ProfileId) => {
        dispatch(setNavigation('#userProfile'));
        dispatch(setProfileId(ProfileId));
    };

    const SwitchToMessagePage = async (MessageId) => {
        dispatch(setNavigation('#specificMessage'));
        dispatch(setSpecificMessageId(MessageId));
        dispatch(setPreviousNavState("#home"));
    };

    const [blockInteraction, setBlockInteraction] = useState(false);
    const delayInteraction = async (ms) => {
        setBlockInteraction(true); 
        await new Promise(  resolve => setTimeout(resolve, ms));
        setBlockInteraction(false); 
    };

    const reportPost = async (PostId) => {
        if (!blockInteraction){
            delayInteraction(800);
            const rprtPost = await ReportPost(PostId);
            if (rprtPost == 201){
                notify("Post Report Submitted !");
            }
        }
    };

    const bookmarkPost = async (PostId) => {
        if (!blockInteraction){
            delayInteraction(800);
            const rprtPost = await BookmarkPost(PostId, cookies.get('userId'));
            if (rprtPost.status == 201){
                notify("Post removed from your bookmarks");
                dispatch(setBookmarkedPosts(rprtPost.data));
                return
            }else if(rprtPost.status == 200){
                notify("Post added to your bookmarks");
                dispatch(setBookmarkedPosts(rprtPost.data));
                return
            }
        }
    };



    return (
        <>
        {messageDeleted ? (<>

        </>) : (<>
            <Fade>
            <MessageRelation IntercationLists={[(Object.values(MessageObj)[0])?.post?.usersLiked, (Object.values(MessageObj)[0])?.post?.userRetweets]} Post_User_Id={(Object.values(MessageObj)[0])?.user?.id}  />
            <div className='message_card_container'>
                <div className="msg_left_cntnr">
                    <img loading="lazy" src={profileCard.imageUrl}/>
                </div>
                <div className="msg_right_cntnr">
                    <div className="msg_names">
                        
                    <Link to={`/forum/profile/@${profileCard.pseudo}`} >
                        <div className="msgn_names_lft" onClick={() =>   SwitchToProfilePage(profileCard.id) }     >
                            <h1>
                                {profileCard.prenom}   {profileCard.nom}
                        
                                 {profileCard.mediumBadge && <> <FontAwesomeIcon style={{color: "gold"}} icon={faBolt}  /> </>} 
                                 {profileCard.nitroBadge && <> <FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}  /> </>} 
                                 {profileCard.verified == true && <> <FontAwesomeIcon style={{color: "#1da1f2"}}  icon={faCircleCheck}  />  </>}

                                <span> @{profileCard.pseudo}</span>
                            </h1>
                            <span>{date}</span>
                        </div>
                    </Link>
                        <div className="msgn_names_rght">
                            <span className='reportBtn' onClick={(() => (reportPost(messageInfo._id)))}><FontAwesomeIcon icon={faFontAwesome}/> </span>
                        </div>
    
                    </div>
    
    
                    <div className="msg_content">
    
                        <div className="msg_desc_div">
                            <h1>
                                <span className='desc_txt' onClick={() => (SwitchToMessagePage(messageInfo._id))} > {messageInfo.description}  </span>
                                {messageInfo?.mentions?.length > 0 && <>
                                    {messageInfo?.mentions?.map((mention) => (<>
                                        <span className="mtion" onClick={() => SwitchToProfilePage(mention.id ? mention.id : mention._id) }>@{mention.pseudo}</span>
                                    </>))}
                                </>}
                                {messageInfo.imageUrl?.length < 2 && <>
                                    <span className='taggg'>
                                        {messageInfo?.hashtags?.map((hashtag, index) => (<>
                                        <strong key={index} onClick={() => {dispatch(setFilterTrends(hashtag.toString()));dispatch(setNavigation('#home'));}}> {hashtag.toString() + " "}</strong> 
                                        </>))}                                    
                                    </span> 
                                </>} 
                            </h1>
                

                            {messageInfo?.hashtags && messageInfo.imageUrl.length > 2  && <>
                                <h2>
                                    {messageInfo?.hashtags?.map((hashtag, index) => (<>
                                        <span onClick={() => {dispatch(setFilterTrends(hashtag.toString()));dispatch(setNavigation('#home'));}}>
                                            {hashtag.toString() + " "} 
                                        </span>  
                                    </>))}    
                                </h2>
                            </>}
                            
                            {(messageInfo?.imageUrl?.length > 2 ) && <>
                                <img  loading="lazy" src={messageInfo.imageUrl}></img>
                             </>}
                           
                        </div>
    
                        {topComm && <>
                            <div className="topComment_sect" onClick={() => (SwitchToMessagePage(messageInfo._id))} >
                                    <div className="topComment_usr">
                                            <img src={topComm.imageUrl} alt="" />
                                            <h1>{topComm.prenom} {topComm.nom} <span>@{topComm.pseudo}  </span> <small> • {messageInfo?.comments && <>{dateParser(new Date(messageInfo?.comments[messageInfo?.comments?.length - 1]?.timestamp), 3)}</> }</small></h1>
                                    </div>

                                    <div className="topCommentData">
                                            <h2>{messageInfo?.comments && <>{messageInfo?.comments[messageInfo?.comments?.length - 1]?.text}</> }</h2>
                                    </div>
                            </div>
                        </>}

                        <div className="msg_interactions_count" >
                            <div className="inter_count">
                              <span className='rd'><FontAwesomeIcon icon={faHeart} className="ntrcnt" /> </span>  
                              <p>{likeInt}</p>
                              <span className='bl'><FontAwesomeIcon icon={faRetweet} className="ntrcnt"/> </span>  
                              <p>{retweetInt} </p>
                            </div>
                            <div className="cmnts_count" onClick={() => {LoadComments()}}>
                                <h1>{commentsLength} Comments</h1>
                            </div>
                        </div>
    
                    </div>
    


          


                    <div className="msg_interact_btns">
    
    
                        <div className={!messageLiked ? 'interactBtn l' : 'interactBtn l likedMsg animate__animated animate__jello'} onClick={() => MessageInteraction(likeInt, setLikeInt, LikeMessage, messageLiked ,setMessageLiked)}>
                            <h5>
                                <span>  <FontAwesomeIcon icon={faHeart} /> </span>
                            </h5>
                            {messageLiked === false &&  <q>LIKE </q>} 
                        </div>

                        <div className={!messageRetweeted ? 'interactBtn r' : 'interactBtn r retweted animate__animated animate__rubberBand'} onClick={() => MessageInteraction(retweetInt, setRetweetInt, RetweetMessage, messageRetweeted ,setMessageRetweeted)}>
                          <h5>
                                <span > <FontAwesomeIcon icon={faRetweet} /> </span>  
                          </h5>
                          {messageRetweeted === false && <q>RETWEET </q>} 
                        </div>

                        <div className='interactBtn c'  onClick={() => (SwitchToMessagePage(messageInfo._id))}> 
                            <h5><span > <FontAwesomeIcon icon={faCommentDots} /> </span></h5>
                            <q>COMMENT </q>
                         </div>
                        
                        {userOwnMsg ? (<>
                            <div className='interactBtn dlt' onClick={() => handleDeletion()}> <h5><FontAwesomeIcon icon={faTrashCan} />  </h5> </div>
                        </>) : (<>
                            <div className={bookPosts.includes(messageInfo._id) ? 'interactBtn bkmrk bkmrked' : 'interactBtn bkmrk'} onClick={() => (bookmarkPost(messageInfo._id))}> <h5 ><FontAwesomeIcon icon={faBookmark} /> </h5></div>
                        </>)}
    
                    </div>
                </div>

         
            </div>

                            {showComments ? (<>
                                <Fade>
                                <div className="comments_card_container">
                                    {((Object.values(commentsData)[0] ).post.comments).map((eachComment, key) => (<>
                                        {key <= 6 && <>
                                            <div className="mappedComment_card" >

                                                <div className='top_comment'>
                                                    <div>
                                                        <img src={eachComment.user.imageUrl}></img>
                                                        <div className="texts">
                                                            <h1 >{eachComment.user.nom} {eachComment.user.prenom} <strong>@{eachComment.user.pseudo}</strong></h1>
                                                            <p> {dateParser(new Date(eachComment.comment.timestamp), 3)}</p>
                                                        </div>      
                                                    </div>

                                                    <div >    
                                                        {(eachComment.comment.commenterId === cookies.get("userId")) ? (<>
                                                            <button onClick={() => (deleteCommentRedux(key, eachComment.comment._id))}><FontAwesomeIcon icon={faTrashCan} /></button>
                                                        </>) :(<>
                                                            <span>{eachComment.comment.likes.length}</span>          
                                                            <button onClick={() => (likeCommentRedux(eachComment.comment._id, key))} className={ (eachComment.comment.likes.indexOf(cookies.get("userId"))) == -1 ? "like_button" : 'like_button activated'} >
                                                                    <FontAwesomeIcon icon={faHeart}  /> 
                                                            </button>
                                                        </>)}
                                                    </div>
                                                </div>

                                                <div className="bot_comment">
                                                    <h2>  {eachComment.comment.text}</h2>
                                                </div>

                                            </div>
                                        </>}
                                    </>))}
                                    <>
                                        { (Object.values(commentsData)[0] ).post.comments.length > 6 && <>
                                            <h1 className='moreResponses' onClick={() => (SwitchToMessagePage(messageInfo._id))} >Show {(Object.values(commentsData)[0] ).post.comments.length - 6} More Response <span><FontAwesomeIcon icon={faAngleDown}/></span></h1>
                                        </>} 
                                    </>
                                </div>
                                </Fade>

                            </>) :(<>
                           
                            </>)}
          
            </Fade>
        </>)}
        </>
    )
}



export default Message
