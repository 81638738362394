import {useEffect, useState} from 'react'
import '../styles/pages/userprofile.scss'
import {GetSpecificUserRest} from '../services/UserServices';
import { useSelector, useDispatch } from "react-redux";
import {FollowUser, maskNotif} from '../services/UserServices';
import {DeleteMessage,UserPostsGql, LikeMessage, RetweetMessage, UserAlternativePostsGql} from '../services/PostServices';
import {setProfilePost, setAltPosts, setProfileMode, setFollowsBool, setProfileId, setEditModal, setModalData} from '../feature/profileSlice';
import Fade from 'react-reveal/Fade';
import { faPaperPlane,faCheck, faUserPlus, faPenToSquare,faLocationDot, faLink,faBell,faXmark,faTrashCan,faMedal,
    faCakeCandles, faHeart, faRetweet , faComment, faCircleCheck, faMapPin, faLaptop, faBan, faBolt, faFireFlameCurved} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {setNavigation, setSpecificMessageId, setPreviousNavState, updateMaskedNotifs} from "../feature/navigationSlice";
import cookies from 'js-cookie';
import {dateParser} from '../services/utils';
import {InitiateDm, specificDM} from '../services/MessageServices';
import { Link } from 'react-router-dom';
import UserFollows from "../components/UserFollows";
import MessageRelation from '../components/messageRelation';
import {notify} from '../pages/Nitrochat';
import {updateMainMessage} from '../feature/messageSlice';
import { faSpeakerDeck } from '@fortawesome/free-brands-svg-icons'
import {nFormatter} from "../services/utils";

function ProfilePost(PostData){
    const [postInfo, setPostInfo] = useState({});
    const [userInfo, setUserInfo] = useState({});
    const date = dateParser(postInfo.createdAt, 2);
    const dispatch = useDispatch();
    const [likeRtInfo, setLikeRtInfo] = useState({
        likeBooL : false,
        likeInt : 0,
        rtBool: false,
        rtInt: 0,
    });
    const [lockInts, setLockInts] = useState({
        likeShow:  false,
        rtShow : false
    })
    const LikeOrRetweet = async (isRetweet) => {
        if (isRetweet){
            const r = await RetweetMessage(postInfo._id, cookies.get('userId'));
            if (r === 200){
                setLikeRtInfo({likeBooL: likeRtInfo.likeBooL, likeInt: likeRtInfo.likeInt, rtBool: true, rtInt: likeRtInfo.rtInt + 1})
            }else{
                setLikeRtInfo({likeBooL: likeRtInfo.likeBooL, likeInt: likeRtInfo.likeInt, rtBool: false, rtInt: likeRtInfo.rtInt - 1})
            }
            setLockInts({ likeShow: lockInts.likeShow,rtShow: true})
        }else{
            const r = await LikeMessage(postInfo._id, cookies.get('userId'));
            if (r === 200){
                setLikeRtInfo({likeBooL: true, likeInt: likeRtInfo.likeInt + 1, rtBool: likeRtInfo.rtBool, rtInt: likeRtInfo.rtInt})
            }else{
                setLikeRtInfo({likeBooL: false, likeInt: likeRtInfo.likeInt - 1, rtBool: likeRtInfo.rtBool, rtInt: likeRtInfo.rtInt})
            }
            setLockInts({likeShow: true,rtShow: lockInts.rtShow})
        };
    }
  
    useEffect(() => {
        if(PostData.PostData.post !== null){
            setUserInfo(PostData.PostData.user);
            setPostInfo(PostData.PostData.post);
            initValues();
        }
    }, [PostData]);

    const initValues = () => {
        var likebool = false;
        var rtbool = false;
        if (PostData.PostData.post.usersLiked.indexOf(cookies.get("userId")) !== -1){
            likebool = true;
        }
        if (PostData.PostData.post.userRetweets.indexOf(cookies.get("userId")) !== -1){
            rtbool = true;
        }
        setLikeRtInfo({
            likeBooL: likebool,
            likeInt: 0,
            rtBool : rtbool,
            rtInt  : 0,
        })
    }

    const SwitchToProfilePage = async (ProfileId) => {
        //dispatch(setNavigation('#userProfile'));
        dispatch(setProfileId(ProfileId));
        dispatch(setPreviousNavState("#userProfile"));
    };

    const SwitchToMessagePage = async (MessageId) => {
        dispatch(setNavigation('#specificMessage'));
        dispatch(setSpecificMessageId(MessageId));
        dispatch(setPreviousNavState("#userProfile"));
    };

    const [deleted, setDeleted] = useState(false);
    const handleDeletion = async () => {
        const i = await DeleteMessage(postInfo?._id);
        setDeleted(true);
    }

    return(
        <>
        {deleted === false && <>
            <MessageRelation Post_User_Id={postInfo.userId}  IntercationLists={[PostData.PostData.post.usersLiked,PostData.PostData.post?.userRetweets]}/>
            <Fade>
            <div className="profile_page_messages">
                <div className="message_page_mid">
                    <div className="left">
                        {userInfo?.imageUrl?.length > 1 && <>
                            <img className='pfp' src={userInfo.imageUrl} alt="" />
                        </>}
                    </div>
                    <div className="right">
                        <Link to={`/forum/profile/@${userInfo.pseudo}`} >
                        <div className="top" onClick={() => (SwitchToProfilePage(userInfo.id))}>
                            <h1>{userInfo.prenom} {userInfo.nom} 
                             {userInfo.mediumBadge && <> <FontAwesomeIcon   style={{color :  "gold"}}  icon={faBolt} /> </>}
                             {userInfo.nitroBadge && <> <FontAwesomeIcon   style={{color :  "cyan"}}  icon={faSpeakerDeck} />  </>} 
                             {userInfo.verified && <> <FontAwesomeIcon style={{color: "#1da1f2"}} icon={faCircleCheck}/> </>} 

                             <span> {"@" + userInfo.pseudo}  {date}</span>
                            </h1>
                        </div>

                        </Link>

                        <div className="mid" onClick={() => (SwitchToMessagePage(postInfo._id))}>
                            <Link to={`/forum/status/${postInfo.id}`} >
                                <h1>{postInfo.description}  <span>{postInfo.hashtags}</span></h1>
                                {postInfo.imageUrl?.length > 2 && <>
                                    <img src={postInfo.imageUrl}/>
                                </>}
                            </Link>
                        </div>
                        
                        <div className="stats">

                            <h1 className={likeRtInfo?.likeBooL == true ? 'liked' : 'unliked'} onClick={() => (LikeOrRetweet(false))}><FontAwesomeIcon icon={faHeart} /> 
                            <span>
                                {lockInts.likeShow === true ? (<>
                                    {postInfo.likes + likeRtInfo?.likeInt}
                                </>): (<>
                                    {postInfo.likes}
                                </>)}
                            </span></h1>

                            <h1 className={likeRtInfo?.rtBool == true ? 'retweeted' : 'notretweeted'} onClick={() => (LikeOrRetweet(true))}><FontAwesomeIcon icon={faRetweet}/> 
                            <span>
                                {lockInts.rtShow === true ? (<>
                                    {postInfo.retweets + likeRtInfo?.rtInt}
                                </>)  : (<>
                                    {postInfo.retweets}
                                </>)} </span> </h1>

                            {postInfo.comments  && <>
                                <h1><FontAwesomeIcon icon={faComment}/> <span>{postInfo.comments.length} </span></h1>
                            </>}

                            {userInfo?.id === cookies.get('userId') && <>
                                <h1 onClick={() => (handleDeletion())}><FontAwesomeIcon icon={faTrashCan}/></h1>
                            </>}
                        </div>
            
                    </div>
                </div>
            
            </div>
            </Fade>
        </>}
    </>)
}


function ProfileThread(ThreadState) {
    const profilePosts = useSelector((state) => state.profile.profilePosts.userForumPosts);
    const altPosts = useSelector((state) => state.profile.alternativePosts.userAlternativePosts);
    const profileIdRedux = useSelector((state) => state.profile.profileId);

    const [noMedia,setNoMedia] = useState(true);
    useEffect(( )=> {
        {profilePosts.map((post) => {
            if (post.post.imageUrl.length > 2){
                setNoMedia(false);
                return
            }
       }).reverse()}
    }, [])
    switch(ThreadState.setThreadState){
        case 'Tweets':
            return (
                <>
                {profilePosts.length > 0 ? <>
                    {profilePosts?.map((eachPost, index) => (
                    <ProfilePost key={index} PostData={eachPost} />
                    )).reverse()}
                </>: <>
                    <Fade>
                        <h1 className='templte_text'>THIS USER HASN'T POSTED ANYTHING ON NITROCHAT</h1>
                        <img className='templte' src={require('../media/noMsgs.png')} alt="" />
                    </Fade>
                </>}
            </>
            )
        case 'Retweets':
            return (
                <>
                {altPosts.length > 0 ? <>
                    {altPosts.map(function(post){
                        if (post.post.userRetweets.includes(profileIdRedux)  && post.user.id !== profileIdRedux  ){
                            return(
                                <ProfilePost PostData={post} />
                            )
                        }
                    }).reverse()}
                </> : <>
                    <Fade>
                        <h1 className='templte_text'>THIS USER HASN'T RETWEETED ANYTHING</h1>
                        <img className='templte' src={require('../media/noRt.gif')} alt="" />
                    </Fade>
                </>}
                </>
            )
        case 'Media':
                return (
                <> 
                {noMedia ? <>
                    <Fade>
                        <h1 className='templte_text'>THIS USER HASN'T POSTED ANY MEDIA</h1>
                        <img className='templte' src={require('../media/noMedia.jpeg')} alt="" />
                    </Fade>
                </> : <>
                    {profilePosts.map(function(post){
                            if (post.post.imageUrl.length > 2){
                                return(
                                    <ProfilePost PostData={post} />
                                )
                            }
                    }).reverse()}
                </>}
                </>
                )
        case 'Likes':
                return (
                <>
                {altPosts.length > 0 ? <>
                    {altPosts.map(function(post){
                        if (post.post.usersLiked.includes(profileIdRedux) && post.user.id !== profileIdRedux ){
                            return(<>
                                <ProfilePost PostData={post} />
                            </>)
                        }
                    }).reverse()}
                </> : <>
                    <Fade>
                        <h1 className='templte_text'>THIS USER HASN'T LIKED ANY NITROCHAT</h1>
                        <img className='templte' src={require('../media/noLikes.webp')} alt="" />
                    </Fade>
                </>}
            </>)
    }
}

function UserProfile() {
    const profileIdRedux = useSelector((state) => state.profile.profileId);
    const followsBool = useSelector((state) => state.profile.showFollows);

    const [ProfileLoaded, setProfileLoaded] = useState(false);

    const ownFollows = useSelector((state) => state.profile.profileFollows);
    const ownFollowsValues = useSelector((state) => state.profile.relationsProfiles);
    const [relationsUsers, setRelationsUsers] = useState([]);

    const [messagesLoaded, setMessagesLoaded] = useState(false);
    const [ProfileInfo, setProfileInfo] = useState({}); 
    const dispatch = useDispatch();
    const [threadState, setThreadState] = useState(""); 
    const [userFollowed, setUserFollowed] = useState(false);
    const [ownUser, setOwnUser] = useState(false);

    const date = dateParser(ProfileInfo.createdAt, 2);

    const setProfileRelations = async (info) => {
        setRelationsUsers([]);
        // if(ownFollows?.length < 1 || !ownFollows || info?.followers_Ids?.length == 0){return 0;}
        // var p = [...info.followers_Ids];
        // p = info?.followers_Ids?.filter(id => ownFollows?.includes(id));
        // p = p.map(id => {
        //     const d = ownFollowsValues.find(usr => usr._id === id); return d ;
        // });
        // setRelationsUsers(p);
    }

    useEffect(() => {if(Object.keys(ProfileInfo)?.length > 1){
        setRelationsUsers([]);
        setProfileRelations(ProfileInfo);
    }}, [ProfileInfo]);

    const InitUserInformations = async () => {
        const UserInfo =  await GetSpecificUserRest(profileIdRedux);
        //setProfileRelations(UserInfo);
        const indexOfUser = UserInfo.followers_Ids?.indexOf(cookies.get('userId'));
        setUserFollowed(true);
        if (indexOfUser === -1){ setUserFollowed(false); }
        
        setProfileInfo(UserInfo);
        setProfileLoaded(true);///// +

        if (!blockUsrs.includes(ProfileInfo._id)){
            const Posts = await UserPostsGql(profileIdRedux);
            const AltPosts = await UserAlternativePostsGql(profileIdRedux);
            dispatch(setProfilePost(Posts));
            dispatch(setAltPosts(AltPosts));
        }

        setMessagesLoaded(true);///// +

    };
    const FollowButton = async () => {
      if (delayed === true){return 0;}
      await delayFunc(400);
      const r = await  FollowUser(profileIdRedux);
      setUserFollowed(false);
      if(r.status === 200){
        setUserFollowed(true);
      }
    };

    useEffect(() => {
        setProfileLoaded(false);setOwnUser(false);setMessagesLoaded(false);
        if (profileIdRedux === cookies.get("userId")){
            setOwnUser(true);
        }
        
        InitUserInformations();
        setThreadState("Tweets");
        dispatch(setFollowsBool(false));
        dispatch(setProfileMode(0));
    }, [profileIdRedux]);
    
    const userModification = useSelector((state) => state.profile.editQuery);
    const blockUsrs = useSelector((state) => state.navigation.blockedUsers);
    const notifBlockdUsers = useSelector((state) => state.navigation.maskedNotifsIds);

    useEffect(() => {
        if (profileIdRedux === cookies.get("userId")){
            InitUserInformations();
        }
    }, [userModification]);

    const directMessage = async (id) => {
       const r = await InitiateDm({
            params: cookies.get("userId"),
            userId : id
        })
        const e = await specificDM(r.data);
        dispatch(updateMainMessage(e));
        dispatch(setNavigation('#userMessages'));        
    }

    const modalFNC = () => {
        dispatch(setModalData(ProfileInfo));
        dispatch(setEditModal(true));
    }
    const [delayed, setDelayed] = useState(false);
    const delayFunc = async (ms) => {
        setDelayed(true);
        const i = await new Promise(resolve => setTimeout(resolve, ms));
        setDelayed(false);
    };
      
     const maskNotifFnc = async (id) => {
        if (delayed === true){return 0;}
        const r = await maskNotif(id);
        if (r.status === 201){notify('@' +ProfileInfo.pseudo + " Notifications Muted !");}else{notify('@' +ProfileInfo.pseudo + " Notifications Unmuted !");}
        dispatch(updateMaskedNotifs(r.data));
        await delayFunc(500);
    };
    const SwitchToProfilePage = async (ProfileId) => {
        //dispatch(setNavigation('#userProfile'));
        dispatch(setProfileId(ProfileId));
        dispatch(setPreviousNavState("#userProfile"));
    };
    return (
        <>
        {ProfileLoaded ? (<>
            {followsBool === false ? (<>
                <div className='profile_page_container '>
                    <div style={ {"background": ProfileInfo?.profileFade ? `linear-gradient(20deg,${ProfileInfo.fadeValues[0]}, ${ProfileInfo.fadeValues[1]})` : `none`}}  className= {ProfileInfo?.profileFade ? 'profile_page_top fadeTop' : "profile_page_top"}>

                        <img src={ProfileInfo.profileBanner} alt=""  className='profile_banner'/>

                        <div className='profile_page_subtop'>
                            <div className="subtop_left">
                                <img style={ {"border": ProfileInfo?.profileFade && `1px solid ${ProfileInfo.fadeValues[1]})`}}  src={ProfileInfo.imageUrl} alt=""  className='profile_picture'/>
                                <h1> {ProfileInfo.prenom} {ProfileInfo.nom}   
                                {ProfileInfo.mediumBadge && <> <FontAwesomeIcon   style={{color :  "gold"}}  icon={faBolt} /> </>}
                                {ProfileInfo.nitroBadge && <> <FontAwesomeIcon   style={{color :  "cyan"}}  icon={faSpeakerDeck} /> </>}
                                {ProfileInfo.verified&&<>  <FontAwesomeIcon style={{color: "#1da1f2"}}  icon={faCircleCheck}/> </>}
                                </h1>
                                <h2> @{ProfileInfo.pseudo} </h2>
                                <h2 className='bio'>{ProfileInfo.biography}</h2>
                                <p>Joined Nitrochat in {date} <FontAwesomeIcon icon={faCakeCandles}/></p>
                                {!blockUsrs.includes(ProfileInfo._id) && <>  
                                    <div className="follows">
                                        <h3 onClick={() => (dispatch(setFollowsBool(true)) )}> <span>{ProfileInfo.followers} </span> Followers </h3>
                                        <h3 onClick={() => (dispatch(setFollowsBool(true)) )}> <span className='right_span'>{ProfileInfo.followings}</span> Followings </h3>
                                    </div>
                                    <p className='city'>
                                        <FontAwesomeIcon icon={faLocationDot}/> {ProfileInfo.country + " "}  
                                        {ProfileInfo.city && <><FontAwesomeIcon icon={faMapPin} style={{marginLeft: "10px"}} /> {ProfileInfo.city} </>}
                                    </p>
                                    {ProfileInfo?.channelsCount > 0 && <>
                                        <h4 className='channelStats'>Owner of <span> {ProfileInfo?.channelsCount} <FontAwesomeIcon style={{color: "#1da1f2"}} icon={faLaptop}/>  Nitrochat Channels.</span> </h4>
                                    </>}
                                    
                                    {ProfileInfo?.profileLink?.length > 0 && <>
                                        <h6><FontAwesomeIcon style={{color: "#1da1f2"}} icon={faLink}/> <span> <a target='blank' href={ProfileInfo?.profileLink}>{ProfileInfo?.profileLink}</a></span> </h6>
                                    </>}

                                    {/* {(relationsUsers?.length > 0 &&  !ownUser) && <>
                                        <div className="relationsRowProfile">
                                            <img onClick={() => (SwitchToProfilePage(relationsUsers[0]._id))} src={relationsUsers[0].imageUrl}  alt="" />

                                            <h6> <strong> {relationsUsers[0].prenom}  {relationsUsers[0].nom}</strong>  {relationsUsers?.length > 2 ? <>and <strong> {relationsUsers?.length - 1}  </strong> more are following</> : <>is following</>}</h6>
                                            {relationsUsers?.length > 1 && <>
                                                    {relationsUsers?.length > 4 ? <>
                                                    {relationsUsers.splice(1, 3).map((Usr) => (<>
                                                        <img onClick={() => (SwitchToProfilePage(Usr._id))} className='rwimg' src={Usr.imageUrl} alt="" />
                                                    </>))}
                                                </> : <>
                                                    {relationsUsers.splice(1, relationsUsers?.length).map((Usr) => (<>
                                                        <img onClick={() => (SwitchToProfilePage(Usr._id))} className='rwimg' src={Usr.imageUrl} alt="" />
                                                    </>))}
                                    
                                                </>}                      
                                            </>}
               
                                   
                                        </div>
                                    </>} */}
                                </>}
                            </div>

                            <div className="subtop_right">
                                {ownUser ? (<>
                                    <h4 className='followed' onClick={() =>modalFNC() }> Edit Profile <FontAwesomeIcon icon={faPenToSquare}/></h4>
                                </>) : (<>
                                    {!blockUsrs.includes(ProfileInfo._id) ? <>  
                                        <div onClick={() => (maskNotifFnc(ProfileInfo._id))} className={notifBlockdUsers?.includes(ProfileInfo._id) ? 'bell maskedBell' : 'bell'}>
                                            <h4><FontAwesomeIcon icon={faBell}/></h4>
                                            {notifBlockdUsers?.includes(ProfileInfo._id) &&<> <Fade><span><FontAwesomeIcon icon={faXmark}/></span> </Fade></>}
                                        </div>
                                        <Link to={`/forum/messages/`} >
                                            <h4  className='wr' onClick={() => (directMessage(ProfileInfo._id))}><FontAwesomeIcon icon={faPaperPlane}/> Message</h4>
                                        </Link>
                                        {userFollowed ? (<>
                                            <h4 className='followed'  onClick={() => (FollowButton())}  ><FontAwesomeIcon icon={faCheck}/> Followed </h4>
                                        </>) : (<>
                                            <h4 onClick={() => (FollowButton())}> Follow <FontAwesomeIcon icon={faUserPlus}/></h4>
                                        </>)}
                                    </> : <>
                                        <h4 style={{border:"2px solid #ff3e3e", color: '#ff3e3e'}}>Blocked <FontAwesomeIcon icon={faBan}/></h4>
                                    </>}
                                </>)}

                                {ProfileInfo?.nitro > 0 && <>
                                    <div className="nitro_fd">
                                            <h5>NITRO {nFormatter(ProfileInfo.nitro, 1)} <FontAwesomeIcon icon={faFireFlameCurved}/> </h5>
                                    </div>        
                                </>}
                            </div>

                            
                        </div>

                        <div className="buttons_flex">
                            {!blockUsrs.includes(ProfileInfo._id) && <>  
                                <div onClick={() => (setThreadState("Tweets")) } className= {threadState === "Tweets" ? "active left" : "left"}>  <span>Nitrochats</span> </div>
                                <div onClick={() => (setThreadState("Retweets"))}  className= {threadState === "Retweets" ? "active" : ""} ><span>Retweets</span></div>
                                <div onClick={() => (setThreadState("Media"))}  className= {threadState === "Media" ? "active" : ""}  ><span>Medias</span> </div>
                                <div onClick={() => (setThreadState("Likes"))}  className= {threadState === "Likes" ? "active right" : "right "}  ><span>Likes</span> </div>
                            </>}
                        </div>
                    </div>
                    
                    <div className="profile_page_bot">
                        {!blockUsrs.includes(ProfileInfo._id) && <>  
                            {messagesLoaded === true ? (<>
                                <Fade>
                                    <ProfileThread setThreadState={threadState}/>
                                </Fade>   
                            </>) : (<>
                                <div className="lds-dual-ring"></div>
                            </>)}
                        </>}
                    </div>
                </div>
            </>) : (<>
                <UserFollows User={profileIdRedux} userData= {ProfileInfo }/>
            </>)}

        </>) :(<>
            <div className="lds-dual-ring"></div>
        </>)}
      
        </>
    )
}

export default UserProfile
