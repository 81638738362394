import React, {useState, useEffect} from 'react'
import { NavLink } from "react-router-dom";
//import '../styles/pages/signup.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GoogleLogin } from 'react-google-login';
import { faGoogle } from '@fortawesome/free-brands-svg-icons'
import { faCode , faLaptopCode} from '@fortawesome/free-solid-svg-icons'
import Fade from 'react-reveal/Fade';
import Spline from '@splinetool/react-spline';
import {SignInAuth, VerifyEmailLink} from '../services/UserServices';
import {LogInGoogle} from '../services/OAuthServices';
import { useNavigate } from "react-router-dom";
import { gapi } from 'gapi-script';
import cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import {getAllCounts} from '../services/HomeServices';

export const notify = (isGoogle) => toast.success((isGoogle ? 'Successfully Signed Up' : 'E-mail Verification Link Sent to Activate your Account'), {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
});


function Login() {
    
    const [formEmail, setEmail] = useState('');
    const [formPassword, setPassword] = useState('');
    const navigate = useNavigate();

    const data = {
        email : formEmail,
        password : formPassword
    }

    const [emailResp, setEmailResp] = useState('');
    const [passwordResp, setPasswordResp] = useState('');

    const [logsErr, setLogsErr] = useState('');

    const G_API_KEY = process.env.REACT_APP_GOOGLE;

    const emailPop = (text) => toast.success(text, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    });

    useEffect(() => {
         cookies.remove("jwt");
         cookies.remove("userId");

         if(window.location.pathname.split('/').includes('verify')){
            let p = window.location.pathname.split('/');
            (async () => {
                const x = await VerifyEmailLink(p[p.length -2], p[p.length - 1]);
                if(x.status == 200){
                    emailPop('Email Account Authentification Confirmed !');
                }
            })();
         }
     }, []);
     
    useEffect(() => {
     
        (async () => {

            const initClient = () => {
                gapi.client.init({
                clientId: G_API_KEY,
                scope: ''
              });
           };
           gapi.load('client:auth2', initClient);
        })();
     

    }, []);

    const [ldata, setData] = useState({});
    const [lded, setLded] = useState(false);
    useEffect(() => {
        (async() => {
            const o = await getAllCounts();
            if(o.status == 200){
                setData(o.data);
            }  
            setLded(true);
        })();
    }, []); 

    const handleClick = async () => {
        if ( formPassword.length < 6 && emailResp.length < 1){
            setPasswordResp("Please enter at least 6 characters minimum.")
        }else{
            if (emailResp.length > 1){return 0;}
            setPasswordResp("")
            var errLog = await SignInAuth(data);
            if (errLog.email || errLog.password ||  errLog.verify){
                if(errLog.email){
                    setLogsErr(errLog.email);
                }else if (errLog.password){
                    setLogsErr(errLog.password);
                }else if (errLog.verify){
                    setLogsErr(errLog.verify);
                }
            }else{
                delayInteraction(1000);
                navigate("/forum");
            } 
        }
    }
    const delayInteraction = async (ms) => {
        await new Promise(  resolve => setTimeout(resolve, ms));
    };

    const handleEmail = event => {
        if (/\S+@\S+\.\S+/.test(event.target.value) === true){
            setEmailResp(' ');
            setEmail(event.target.value);
        }else{
            setEmailResp('Please insert correct E-mail adress');
        }
    }
    
    const onSuccess = async (res) => {
         const logRes = await LogInGoogle({authToken: res.tokenId});
        if (logRes.status === 200){  
            setEmailResp('');
            await Promise.all(cookies.set('jwt', logRes.data.token, {expires: 3})); //// 72H JWT TOKEN
            await Promise.all(cookies.set('userId',  logRes.data.userId, {expires: 3})); ////72H STORED USER ID
            await delayInteraction(100);
            navigate("/forum");
        }else  if (logRes.status === 202){
            setEmailResp('Register with your Google Account first');
        }
    }

    const onFailure = (err) => {console.log('failed:', err);};

    return (
        <>
        <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                draggable
                theme="colored"
                toastStyle={{ 
                    backgroundColor: "#1da1f2",
                    borderRadius: "15px",
                 }} 
        />
        
    <div className="body_alt">
        <div className='parent'>

        <div className="left">

            <div className="titles">
                <Fade top>
                <h6><span><FontAwesomeIcon icon={faLaptopCode} /> </span> Nitrochat</h6>
                </Fade>
                <h1 className='insc_title'>Log in.</h1>
                <h2>Log in with your data that you entered during registration.</h2>

            </div>
            
            <div className="form">
            <Fade bottom>

       

                <div className="form_text">
                    <h3>
                        Your E-mail
                    </h3>
                    <input type="text" placeholder="Adresse Mail"  onChange={handleEmail}/>
                    <h2 className='errorHandler'>{emailResp}</h2>
                </div>

                <div className="form_text">
                    <h3>Password
                    </h3>
                    <input type="password" placeholder="Mot de passe"  onChange={e => setPassword(e.target.value)}/>
                    <h2 className='errorHandler'>{passwordResp}</h2>
                </div>

                <p>{logsErr}</p>

                <button onClick={handleClick}>Log In <FontAwesomeIcon icon={faCode} /></button>

                <h4>
                   Doesn't have an account ?           
                    <span> <NavLink exact to="/signup" className="linktopage" >Register </NavLink></span>
                <br/> 
                <span>  Forgot Password ?</span>
                </h4>

                <div className="google_btn">
                                <h1> OR</h1>
                                <GoogleLogin
                                    clientId={G_API_KEY}
                                    buttonText="Log In with Google"
                                    onSuccess={onSuccess}
                                    onFailure={onFailure}
                                    cookiePolicy={'single_host_origin'}
                                    render={renderProps => (
                                        <button onClick={renderProps.onClick} disabled={renderProps.disabled}>Log In With Google <FontAwesomeIcon icon={faGoogle} />
                                        </button>
                                      )}
                                />
                </div>

                <h5>{(new Date().getFullYear())} @ Nitrochat All Rights Reserved</h5>
                </Fade>

            </div>

        </div>


    <div className="right">
            {(lded) && <>
                <p style={{borderColor : Object.keys(ldata).length < 1 ? "orange" : "cyan"}}>Server Status {Object.keys(ldata).length < 1 ? <> <i style={{color: "orange"}} >•</i>   <span>Sleepy</span> </> : <> <i style={{color: "cyan"}}>•</i>  <span>Alive</span></> } </p>
            </> }
        <video preload loop playsInline autoFocus autoPlay muted src={(require('../media/loginNitro.mp4'))} alt="Home Template" />
    </div>


    </div>
                    
    </div>
    </>
    )
}

export default Login
