import React from 'react'
import '../styles/pages/pagenotfound.scss';
import { NavLink } from "react-router-dom";

function notFoundPage() {
    return (
        <div className="pagenotfound">
            <img className='vdBg' preload loop playsInline autoFocus autoPlay muted src={(require('../media/4044.gif'))} alt="Home Template" />
                <div className="container">
                        <h1>404 Not Found.</h1>
                        <h2>Oops</h2>
                        <h3>This page couldn't be found.
                            <br/> The link may be corrupted or the page could've been deleted.
                        </h3>
                        <NavLink exact to="/" style={{ textDecoration: 'none', color: "white" }}> <button>Back Home</button></NavLink>
                </div>

        </div>
    )
}

export default notFoundPage
