import { createSlice } from "@reduxjs/toolkit";

export const commentsSlice = createSlice({
    name: "comments",
    initialState: {
        comments: [],
        commentModal : false,
        commentModalContent: {},
        commenterPFP: "imageURL"
    },
    reducers: {
        addComment: (state, {payload}) => {
            state.comments.push(payload);
        },
        switchModalState: (state, {payload}) => {
            state.commentModal = payload;
        },
        setModalContent: (state, {payload}) => {
            state.commentModalContent = payload;
        },
        initUserPfp: (state, {payload}) =>{
            state.commenterPFP = payload;
        }
    }
})
export const {setComments, switchModalState, setModalContent, initUserPfp} = commentsSlice.actions;
export default commentsSlice.reducer;
