import {useEffect, useState, useRef, useCallback} from 'react'
import {PostsGql, FollowingsPosts, TrendsPostsGql} from '../services/PostServices';
import Message from '../components/Message';
import { setPostsData, pushTrendPosts } from '../feature/postsSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setFilterTrends } from '../feature/trendSlice';
import {useDispatch, useSelector} from "react-redux";
import { faArrowLeft, faMagnifyingGlass, faXmark, faCircleCheck, faBolt} from '@fortawesome/free-solid-svg-icons'
import '../styles/pages/nitrochatMessages.scss';
import Fade from 'react-reveal/Fade';
import cookies from 'js-cookie';
import SuggsWindow from '../components/SuggsWindow';
import {setProfileId} from '../feature/profileSlice';
import {setNavigation, showAll} from "../feature/navigationSlice";
import {FollowUser, getOwnFollowings, seachFor} from '../services/UserServices';
import {updateProfileFollows, setRelationsProfiles} from '../feature/profileSlice';
import { faSpeakerDeck } from '@fortawesome/free-brands-svg-icons'

function TrendMemberCard(member){
    const follows = useSelector((state) => state.profile.profileFollows);
    const dispatch = useDispatch();
    const [followed, setFollowed] = useState(false);
    const [delayed, setDelayed] = useState(false);

    const [loaded, setLoaded] = useState(true);

    const SwitchToMessagePage = async (ProfileId) => {
        dispatch(setNavigation('#userProfile'));
        dispatch(setProfileId(ProfileId));
    };

    useEffect(() => {
        if (follows?.length > 0){
            if (follows?.includes(member.member?.id) ){
                setFollowed(true);
            }
        }else{
            setLoaded(false);
            (async ( )=> {
                const updatedFollows = await getOwnFollowings({userId: cookies.get('userId'), jwt: cookies.get('jwt')});
                if (updatedFollows.data.ids.followings_Ids?.includes(member.member?.id) ){
                    setFollowed(true);
                }
                dispatch(updateProfileFollows(updatedFollows.data.ids.followings_Ids));
                dispatch(setRelationsProfiles(updatedFollows.data.relationsData));
               
            })();
            setLoaded(true);
        }
    }, []);

    const FollowButton = async () => {
        if (delayed === true){return 0;}
        setDelayed(true);
        const r = await  FollowUser(member.member.id);
        if(r.status === 200){setFollowed(true);}else{
            setFollowed(false);
        }
        setDelayed(false);
    };

    return(<>
        <div className="memberTrendCard animate__animated animate__bounceInUp">
            <div className="l"  onClick={() => (SwitchToMessagePage(member.member.id))}>
                    <img src={member.member.imageUrl} alt="" />
                <div className="texts">
                    <h1>{member.member.prenom} {member.member.nom}
                    {member.member.mediumBadge && <> <FontAwesomeIcon style={{color: "gold"}} icon={faBolt}  /> </>} 
                    {member.member.nitroBadge && <> <FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}  /> </>} 
                    {member.member.verified == true && <> <FontAwesomeIcon style={{color: "#1da1f2"}}  icon={faCircleCheck}  />  </>}
                    </h1>
                    <h2>@{member.member.pseudo}</h2>
                    <p>{member.member.biography}</p>
                </div>
            </div>

          {loaded && <> <button onClick={() => (FollowButton())} className={followed && 'followed'}> {followed ? <>Followed </>: <>Follow</>  }</button></> } 
        </div>
    </>)
}

function TrendMessages(showMembers){
    const postsValue = useSelector((state) => state.posts.posts);
    const trendValue = useSelector((state) => state.trends.filterTrend);

    const [membersData, setMembersData ]= useState([]);
    const [highlightedPosts, setHighlightedPosts ]= useState([]);
    const [loaded, setLoaded ]= useState(false);

    const dispatch = useDispatch();


    useEffect(() =>  {
        (async () => {
            let e = [...postsValue];
            if(trendValue.length > 0){ e = e.filter(p => p?.post?.hashtags.includes(trendValue));}
            if (showMembers.showMembers){
                var u = e.map(post => {return post.user});
                u = u.filter((c, index) => {return u.findIndex(e => e.id == c.id) === index;});
                setMembersData(u);
            }else{
                const d = await Promise.all(e?.sort(function(a, b) {
                    if ((a?.post?.likes + a?.post?.retweets ) > (b?.post?.likes + b?.post?.retweets))
                    {return 1;}
                    else{return - 1;}
                    return 0;
                }));
                setHighlightedPosts(d);
            }
            setLoaded(true);
        })();
    }, [showMembers.showMembers]);

    return(<>
        {loaded ? <>
            {showMembers.showMembers ? <>
                {trendValue.length < 1 && <> <h1 onClick={() => (dispatch(showAll(false)) )} className='rtrn animate__animated animate__fadeInUp'> <FontAwesomeIcon style={{color: "#1da1f2"}} icon={faArrowLeft}/> <span> Nitrochat Community </span>  Members</h1></>}
                {membersData?.map((member, index ) => (<>
                    <TrendMemberCard member={member}/>
                </>))}
            </>: <>
                {highlightedPosts?.map((post, index) => (<>
                        <Message key={index} Message_={post} />
                </>)).reverse()} 
            </>}
        </> : <>
            <div className="lds-dual-ring"></div>
        </>}
    </>)
}

function NitrochatMessages() {
    const [followingsPosts, setFollowingsPosts] = useState(false);

    const dispatch = useDispatch();
    const postsData = useSelector((state) => state.posts.posts);
    const navState = useSelector((state) => state.navigation.navigation);
    const showAllbool = useSelector((state) => state.navigation.showMmbrsThread);

    const trendFilter = useSelector((state) => state.trends.filterTrend);
    const [trendOption, setTrendOption] = useState('highlight');

    const [seachValue, setSearchValue] = useState("");
    const [fetchedSearch, setFetchedSearch] = useState({});
    const [isSearching, setIsSearching] = useState(false);

    const GQLPostFetch = async () => { 
        dispatch(setPostsData({}));
        var postsFetched = {};
        if (followingsPosts){
            postsFetched = await FollowingsPosts(cookies.get("userId"));
            dispatch(setPostsData(postsFetched.followingsPosts.reverse()));
            return 0;
        }else{
            postsFetched = await PostsGql();
            dispatch(setPostsData(postsFetched?.forumPosts?.reverse()));
            if (trendFilter?.length > 1){GQLTrendFetch();}
        }
    };

    const GQLTrendFetch = async () => { 
        if (trendFilter?.length > 1){
            setTrendsLoading(true);
       
            const i =  await TrendsPostsGql(trendFilter);
            const updateMap = i.specificTrendPost.map((post, index) => {
                if(postsData?.length == 0){return 0;}
                const indx = postsData?.findIndex(data => data?.post?._id === post?.post?._id);
                if(indx === -1){return post}else{return 0;}
            });
            dispatch(pushTrendPosts(updateMap));
            
            setTrendsLoading(false);
        }
    };

    useEffect(() => { dispatch(showAll(false));}, [navState, trendFilter, followingsPosts]);

    useEffect(() => {    GQLPostFetch(); }, [followingsPosts]);

    useEffect(() => { if(trendFilter?.length > 0 && showAllbool){setTrendOption("members")}   }, [showAllbool])
    const [trendLoading, setTrendsLoading] = useState(true);

    useEffect(() => {if (navState !== "#home"){dispatch(setFilterTrends(''))}}, [navState]);
    useEffect(() => {  
        document.addEventListener("click", handleOutsideClick);
        setTrendOption('highlight');
        if (trendFilter.length > 1){
            GQLTrendFetch();
        }
    }, [trendFilter]);
    
    useEffect(() => {
        setIsSearching(true);
        (async () => {
            if (seachValue.length > 1){
                const refreshedSearch = await seachFor(seachValue.toLowerCase().replace(/[^0-9a-z-]/g,""));
                setFetchedSearch(refreshedSearch);
                setIsSearching(false);
            }
        })();
    }, [seachValue]);

    const lastPostRef = useRef(null);
    let callback = (entries, observer) => {
        entries.forEach((entry) => {
            console.log(entry);
        })
    }
    useEffect(() => {
        let observer = new IntersectionObserver(callback);
        if(lastPostRef?.current){
            observer.observe(lastPostRef.current);
        }
        return () => {
            if(lastPostRef?.current){
                observer.unobserve(lastPostRef.current);
            }
        }
    }, [lastPostRef.current]);
    const handleOutsideClick = (event) => {
        const hasClass =  event.target.classList.contains('searchResponses');
        if (hasClass === false){
            setSearchValue("");
        }
    }
    const SwitchToProfilePage = async (ProfileId) => {
        dispatch(setNavigation('#userProfile'));
        dispatch(setProfileId(ProfileId));
        setSearchValue("");
    };
    return (<>
        <div className='nm_messages'>
            {trendFilter?.length > 0 && <>  
                    <div className="trendOptions_">
                        <h3 onClick={() => {setTrendOption("highlight");dispatch(showAll(false))  }} className={trendOption == "highlight" ? "selected_o animate__animated animate__bounceInLeft" : "  animate__animated animate__bounceInLeft"}>Highlights</h3>
                        <h3 onClick={() => {setTrendOption("recent"); dispatch(showAll(false)) }}  className={trendOption == "recent" ? "selected_o animate__animated animate__fadeInUp" : "  animate__animated animate__fadeInUp "}>Most Recent</h3>
                        <h3 onClick={() => (setTrendOption("members"))}  className={trendOption == "members" ? "selected_o animate__animated animate__fadeInUp" : "  animate__animated animate__fadeInUp"}>Members</h3>
                        <h3 onClick={() => {setTrendOption("photos"); dispatch(showAll(false)) }}  className={trendOption == "photos" ? "selected_o animate__animated animate__fadeInUp" : "  animate__animated animate__fadeInUp"}>Photos</h3>
                    </div>
                    <div className="searchRtrn">
                        <Fade bottom>
                            <div className="xtst">
                                <h1  onClick={() => (dispatch(setFilterTrends("")))} ><span ><FontAwesomeIcon icon={faArrowLeft}/></span> Trend </h1>  
                                <h2>{trendFilter}</h2>
                            </div>
                        </Fade>

                        <div className='srchTrend animate__animated animate__bounceInRight'>
                            <p><FontAwesomeIcon icon={faMagnifyingGlass}/></p>
                            <input type="text" maxLength={35} placeholder={trendFilter} onChange={e => setSearchValue(e.target.value)} />
                            <p onClick={() => (dispatch(setFilterTrends("")))}  style={{marginRight: "15px"}}><FontAwesomeIcon icon={faXmark}/></p>
                        </div>
                        
                    {seachValue.length > 0 && <>
                        <div className="searchResponses_stg">
                            <h1><FontAwesomeIcon icon={faMagnifyingGlass} />  Searching for <strong>{seachValue}</strong></h1>

                            {isSearching === true ? <>
                                <div className="lds-dual-ring"></div>
                            </>: <>
                                {(fetchedSearch?.searchProfiles?.length > 0 || fetchedSearch?.searchTrends?.length > 0) ? <>
                                    {fetchedSearch?.searchTrends.map((trend) => (
                                        <div className="trendSearched" onClick={() =>  {dispatch(setFilterTrends(trend));  dispatch(setNavigation('#home')); setSearchValue("")  }}>
                                            <Fade>
                                            <p>{trend}</p>
                                            </Fade>
                                        </div>
                                    ))} 

                                    {fetchedSearch?.searchProfiles.map((profile) => (
                                            <Fade bottom>
                                            <div className="profileSearched"  onClick={() => {SwitchToProfilePage(profile._id); setSearchValue("") }}>
                                                <div>
                                                    <img src={profile.imageUrl} alt="" />
                                                </div>
                                                <div>
                                                    <h2>{profile.prenom} {profile.nom} </h2>
                                                    <h2 className='gry'>@{profile.pseudo}</h2>
                                                </div>
                                            </div>
                                            </Fade>
                                    ))} 
                                    
                                    </> : (<>

                                    <h4>No results match <small>your search </small> criteria.</h4>
                                    <h5 onClick={() => (dispatch(setNavigation('#explore')))}>Explore on Nitrochat </h5>
                                </>)}
                          
                            </>}
       
                        </div>
                    </>}
                    </div>
            </>}

            {trendFilter?.length == 0 && <>
                    <div className='messagesThread'>
                        <h2 className={followingsPosts === false && "active"}  onClick={() => {setFollowingsPosts(false);}}>For you</h2>
                        <h2 className={followingsPosts === true && "active"}  onClick={() => {setFollowingsPosts(true);}}>Followings</h2>
                    </div>
            </>}
            { !(trendFilter?.length > 0 &&( trendOption == "members" || trendOption == "photos" )) && (<>
                <SuggsWindow isFollowings={followingsPosts}/>
            </>)}
            {postsData?.length > 0 ? (<>

                {trendFilter?.length > 0 ? (<>

                    {trendLoading ? <>
                        <div className="loadprnt">
                            <div className="lds-dual-ring"></div>
                        </div>
                    </> : <>
                        {(() => {switch(trendOption) {
                            case 'highlight':
                                return <>
                                    <TrendMessages showMembers={false} />
                                </>
                            case 'recent':
                                return <>
                                    {postsData?.map((eachPost, index) => (<>
                                        {eachPost.post.hashtags?.includes(trendFilter) && <>
                                            <Message key={index} Message_={eachPost} />
                                        </>}
                                    </>)).reverse()}
                                </>
                            case 'members':
                                return <>
                                    <TrendMessages showMembers={true} />
                                </>
                            case 'photos':
                                return <>
                                    {postsData?.map((eachPost, index) => (<>
                                        {(eachPost.post.hashtags?.includes(trendFilter) && (eachPost.post.imageUrl.length > 2)) && <>
                                            <Message key={index} Message_={eachPost} />
                                        </>}
                                    </>)).reverse()}
                                </>
                        }
                        })()}
      
                    </>}  


                </>) : (<>
                    {showAllbool && <>
                        <TrendMessages showMembers={true} />
                    </>}
                    
                    {postsData?.map((eachPost, index) => (<>

                        {index === 1 ? <>
                            <div ref={lastPostRef}>
                                <Message key={index} Message_={eachPost} />
                            </div>
                        </> : <>
                            <Message key={index} Message_={eachPost} />
                        </>}
                    </>)).reverse()}

                </>)}


            </>) : (<>
            <div className="loadprnt">
                <div className="lds-dual-ring"></div>
            </div>
            </>) }
            
        </div>
    </>)
}

export default NitrochatMessages
