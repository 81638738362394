import {useEffect, useState, useRef} from 'react';
import {useDispatch,useSelector} from "react-redux";
import {setNavigation, setSpecificMessageId} from "../feature/navigationSlice";
import {SpecificPostComments,  SpecificPost, CommentPost, RemoveComment} from '../services/PostServices';
import {GetSpecificUserRest, notifUserInfo, HeaderInfQuery, specificPostUser} from '../services/UserServices';
import { faArrowLeft, faClock, faHeart, faRetweet, faComment, faReply, faTrashCan, faArrowRight, faReplyAll, faLaptopCode} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {setProfileId} from '../feature/profileSlice';
import Fade from 'react-reveal/Fade';
import '../styles/pages/specificMessage.scss'
import {dateParser} from '../services/utils';
import cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { LikeMessage, RetweetMessage, LikeComment} from '../services/PostServices';
function UserProfile(ProfileData){
    const dispatch = useDispatch();
    const [profileData, setProfileData] = useState({});

    useEffect(() => {
        (async () => {
            const e = await specificPostUser(ProfileData.ProfileData);
            setProfileData(e.getUser);
        })()
    }, [ProfileData]);

    const SwitchToProfilePage = async (ProfileId) => {
        dispatch(setNavigation('#userProfile'));
        dispatch(setProfileId(ProfileId));
    };

    return(<>
    {(Object.keys(profileData).length > 1 && <>
        <Fade bottom>
            <Link to={`/forum/profile/@${profileData?.pseudo}`} >
                <div className="profileThreadCard"  onClick={() =>   SwitchToProfilePage(profileData?.id) } >
                    <div className='rw'>
                        <img src={profileData?.imageUrl} alt="" />
                        <div>
                            <h1>{profileData?.prenom} {profileData?.nom} </h1>
                            <h1 className='ps'>@{profileData?.pseudo}</h1>
                        </div>
                    </div>

                    <div className='rght'>
                        <h2>Followers {profileData?.followers} </h2>
                        <h2>{profileData?.postsCount} Nitrochats <FontAwesomeIcon icon={faLaptopCode}/></h2>
                    </div>

                </div> 
            </Link>
        </Fade>
    </>) }
    </>)
}


function SpecificMessage() {  
    const [displayMode, setDisplayMode] = useState("comments");

    const [messageData, setMessageData] = useState({});
    const retrnBtn = useRef(null);
    const [commentsLoaded, setCommentsLoaded] = useState(false);
    const [messageUser, setMessageUser] = useState({});
    const [backInfo, setBackInfo] = useState({});
    const [writterInfo, setWritterInfo] = useState({});

    const [answer, setAnwser] = useState({});

    const [commentsData, setCommentsData] = useState([]);
    const [replyValue, setReplyValue] = useState("");

    const dispatch = useDispatch();

    const  SpecificMessageId = useSelector((state) => state.navigation.specificMessageId);
    const  PreviousNav = useSelector((state) => state.navigation.previousNavigationState);
    const  ProfilePageId = useSelector((state) => state.profile.profileId);

    const reRender = async (reloadPost)=> {
        const U = await SpecificPostComments(SpecificMessageId);
        setCommentsData(U.specificPostComments);

        if (reloadPost){
            const R = await SpecificPost(SpecificMessageId);
            setMessageData(R);
            return
        }
    }

    const LoadMessageData = async () => {
        const R = await SpecificPost(SpecificMessageId);
        setMessageData(R);
        const S = await GetSpecificUserRest(R.userId)
        setMessageUser(S);
        reRender(false);
        setCommentsLoaded(true)
    }

    const GoBackInfo = async () => {
        const pseudos = await notifUserInfo(ProfilePageId);
        setBackInfo (pseudos.getUser);
    }

    useEffect(() => {
        (async() => {
            const T = await HeaderInfQuery();
            setWritterInfo(T.getUser);
        })();
        LoadMessageData();
        if (PreviousNav === "#userProfile"){
            GoBackInfo();
        }
    }, [])

    const commentPost = async () => {
        if (replyValue.length > 0){
            var replyToValue = "";
            if (answer.comment){
                replyToValue = answer.comment._id
            }
            const resp = await CommentPost( messageData._id, {
                commenterId: cookies.get("userId"),
                text: replyValue,
                replyTo: replyToValue
            });
            if(resp.status === 200){
                LoadMessageData();
            }
        }
    }

    const deleteComment = async (CommentId) => {
        await RemoveComment(CommentId, messageData._id);
        reRender(false);
        if (CommentId === answer.comment._id){
            setAnwser({});
        }
    }

    const messageInteraction = async (intercationType, cmntId) => {
        
        if (intercationType == 'rt'){
            const e = await RetweetMessage(messageData._id, cookies.get('userId') );
            reRender(true);
            return
        }else if (intercationType == 'lk'){
            const f = await LikeMessage(messageData._id,  cookies.get('userId') );
            reRender(true);
            return
        }
        const g = await LikeComment({
            userId : cookies.get('userId'),
            commentId : cmntId
        },messageData._id);
        LoadMessageData();
        return
    }

   const goback = () => {dispatch(setNavigation(PreviousNav)) };
   
    return (
        <div className='SpecificPage'>
            <div className="goBack" ref={retrnBtn}>
                <Fade bottom>
                    <h1 onClick={() => (goback())}> <span><FontAwesomeIcon icon={faArrowLeft}/></span>   Nitrochat</h1>
                    <h2>{PreviousNav === "#home" ? (<>
                            Home
                    </>) : (<>
                        <span> @{backInfo?.pseudo}</span> {backInfo?.nom} {backInfo?.prenom}
                    </>)}</h2>
                </Fade>
            </div>

            <Fade>
            {!commentsLoaded ? (<>
            </>) : (<> 
                <div className="specificMessage">
                    <div className="topContent">
                        <img src={messageUser?.imageUrl} alt="" />
                        <div className="topColumn">
                            <h3> {messageUser?.prenom} {messageUser?.nom}</h3>
                            <h3 className='topPseudo'>@{messageUser?.pseudo}</h3>
                        </div> 
                    </div>

                    <div className="specificContent">
                        <p>{messageData?.description} <span>{messageData?.hashtags}</span></p>
                        
                        {messageData?.imageUrl?.length > 2 && <>
                            <img src={messageData?.imageUrl} alt="" />
                        </> }

                        <h5><FontAwesomeIcon icon={faClock}/> {dateParser(new Date(messageData?.createdAt), 6)}</h5>
                    </div>
                </div>
                <>
                    {answer?.comment?.text?.length > 0 && (<>
                        <Fade> 
                        <div className="answrRow">
                            <div className='rtrn'>
                                <h4 onClick={() => (setAnwser({}))}><FontAwesomeIcon icon={faReplyAll}/></h4>
                            </div>
                            <div className="specificMessage answrCard">
                                    <div className="topContent">
                                        <img src={answer?.user?.imageUrl} alt="" />
                                        <div className="topColumn">
                                            <h3> {answer?.user?.prenom} {answer?.user?.nom}</h3>
                                            <h3 className='topPseudo'>@{answer?.user?.pseudo}</h3>
                                        </div> 
                                    </div>

                                    <div className="specificContent">
                                        <p>{answer?.comment?.text} </p>
                                        <h5><FontAwesomeIcon icon={faClock}/> {dateParser(new Date(answer?.comment?.timestamp), 6)}</h5>
                                    </div>
                            </div>
                        </div>
                        </Fade>
                    </>)}
                </>
            </>)}
            </Fade>

        {commentsLoaded && <>
            <div className="specificStats">
                    <h4><span  onClick={() => (messageInteraction("lk"))} className={messageData?.usersLiked?.includes(cookies.get("userId")) === true ? ("l_active") : ("l")}><FontAwesomeIcon icon={faHeart}/></span><i onClick={() => (setDisplayMode("likes"))}> <strong> {messageData?.likes} </strong>Likes </i>  </h4>
                    <h4> <span onClick={() => (messageInteraction("rt"))} className={messageData?.userRetweets?.includes(cookies.get("userId")) === true ? ("rt_active") : ("rt")}><FontAwesomeIcon icon={faRetweet}/></span> <i onClick={() => (setDisplayMode("retweets"))}>  <strong>{messageData?.retweets} </strong>   Retweets </i> </h4>
                    <h4> <span><FontAwesomeIcon icon={faComment}/></span> <i onClick={() => (setDisplayMode("comments"))}>  <strong>{messageData?.comments?.length} </strong> Comments </i></h4>
            </div>

            {displayMode === 'comments' && <>
                        <div className="specificResponse">
                                <div>
                                    <p> <strong> <FontAwesomeIcon icon={faArrowRight}/> {writterInfo?.nom} {writterInfo?.prenom} </strong>In response to
                                        <span> @{messageUser?.pseudo} {answer?.user?.pseudo?.length > 0 && <>
                                            <small>&</small> @{answer.user.pseudo}
                                        </>} </span>
                                    </p>
                                </div>
                                <div className='respInputs'>
                                    {writterInfo?.imageUrl.length > 1 && <>
                                        <img src={writterInfo?.imageUrl} alt="" />
                                    </>} 
                                    <input type="text" placeholder='Write your response' maxLength={125} onChange={e => setReplyValue(e.target.value)}/>
                                    <button onClick={() => (commentPost())}> <span>Reply</span>  <FontAwesomeIcon icon={faReply}/></button>
                                </div>
                                <div className='respLen'>
                                <small>{replyValue.length} / 125</small>
                                </div>
                </div>
            </>}
        </>}
        <>
        {( () => {
            switch(displayMode){
                case 'comments':
                    return(<>
                        <div className="commentsMap">
                        {commentsLoaded === false ? (<>
                                    <div className="lds-dual-ring"></div>
                                </>) : (<>
                                    {commentsData?.map((eachArray, index) => (
                                        <>
                                    {eachArray?.comments.map((eachComment, index) => (
                                    <Fade>
                                        <div className={index === 0 ? "commentCard": "commentCard subChild"}>

                                            <div>
                                            { index  < eachArray?.comments.length - 1 && <><div className={eachComment.comment.text.length > 70 ? 'line long' :'line'}></div></>}
                                                <img src={eachComment.user.imageUrl} alt="" />
                                            </div>

                                            <div className='commentTop'>
                                                <div className='profileClick'>
                                                    <h1> {eachComment.user.prenom} {eachComment.user.nom}
                                                        <span> @{eachComment.user.pseudo} </span>
                                                        {index !== 0 && <><small>{dateParser(new Date(eachComment.comment.timestamp), 5)}</small></>}
                                                    </h1>
                                                    <h2>In response to  

                                                        {eachComment?.comment?.replyTo.length > 0 && <>
                                                        <span>  @{eachArray?.comments[0].user.pseudo}</span>   and
                                                        </>}

                                                        <span> @{messageUser?.pseudo} </span>
                                                
                                                    </h2>
                                                </div>
                                                <div>
                                                    <h3>{eachComment.comment.text}</h3>
                                                </div>
                                                <div className='answr'>

                                                    {eachComment.comment.commenterId === cookies.get("userId") && <>
                                                    <h4 className='answr_btn dlt' onClick={() =>(deleteComment(eachComment.comment._id))}><FontAwesomeIcon icon={faTrashCan}/> Delete </h4>
                                                    </>}

                                                {index === 0 && <>
                                                    <h4> {dateParser(new Date(eachComment.comment.timestamp), 5)} </h4>
                                                    <h4 className='answr_btn' onClick={() => (setAnwser(eachComment))}>Answer  <FontAwesomeIcon icon={faReply}/></h4>
                                                </>}   
                                                </div>
                                            </div>

                                            <div className='commentRight'>
                                                <h4  onClick={() => (messageInteraction("",eachComment?.comment._id))} className={eachComment?.comment.likes.includes(cookies.get("userId")) === true && "liked" }>{eachComment?.comment.likes.length} <FontAwesomeIcon icon={faHeart}/>{eachComment?.likes?.length}</h4>
                                            </div>
                                        </div>
                                    </Fade>
                                    )) }
                                    </>
                                ))}
                            </>)}
                        </div>
                    </>)       
                case 'likes':
                    return(<>
                        {messageData?.usersLiked.length > 0 ? (<>
                            {messageData?.usersLiked.map((eachProfile) => (<>
                            < UserProfile ProfileData={eachProfile}/></>
                            ))}
                        </>) : (<>
                            <p className='noD'>Oops.. no one  <span> liked  </span> this post :/</p>
                        </>)}
                      
                    </>)
                case 'retweets':
                    return(<>
                    {messageData?.userRetweets.length > 0 ? (<>
                        {messageData?.userRetweets.map((eachProfile) => (
                        <>
                            < UserProfile ProfileData={eachProfile}/>
                        </>
                    ))}
                    </>) : (<>
                        <p className='noD'>Oops.. no one <span> retweeted  </span>this post :/</p>
                    </>
                    )}
               
                    </>)
            }
        })()}
      
        </>           
        </div>
    )
}

export default SpecificMessage
