import { GraphQLClient, gql, request } from "graphql-request";
import {setPostComments} from "../feature/postsSlice";
import {setPersonnalTrends} from "../feature/trendSlice";
import store from "../app/store";
import cookies from 'js-cookie';

const axios = require('axios');
//const graphQLendpoint = 'http://localhost:3000/api/graphql';
const graphQLendpoint = process.env.REACT_APP_API_GQL;
//const restApi = 'http://localhost:3000/api'
const restApi = process.env.REACT_APP_API_REST;


var config = { 
    headers: {
        authorization: `${cookies.get("jwt")}`,
        authentification: `${cookies.get("userId")}`
    }
}

// export async function FetchPosts(){ //////// SIGN UP API CALL
//     try{
//       const resp =  await axios.get(restApi + '/post/').then(resp => {
//                 return(resp.data);
//             }).catch(err => {
//                 return(err);
//             });
//         return(resp);
//     }catch(err){
//         return (err);
//     }
// }



export async function PostsGql() {
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: cookies.get("jwt"),
            authentification: cookies.get("userId")
        }
    });

    const query = gql`  
        {
            forumPosts{
                post{
                    _id
                    likes
                    retweets
                    description
                    createdAt
                    usersLiked
                    imageUrl
                    userRetweets
                    hashtags
                    comments{
                        postId
                        text
                        timestamp
                        replyTo
                    }
                    mentions{
                        id
                        pseudo
                    }
                }
        
                user{
                    id
                    nom
                    prenom
                    pseudo
                    biography
                    imageUrl
                    verified
                    nitroBadge
                    mediumBadge
                }

                t_C{
                    id
                    nom
                    prenom
                    pseudo
                    imageUrl
                    verified
                    nitroBadge
                    mediumBadge        
                }
        
            }
        }   
    `

    const data = await graphQLClient.request(query);

    return(data);
}


export async function TrendsPostsGql(tr) {
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })
    const query = gql`  
    query($trendId: ID!){
        specificTrendPost(trend: $trendId){
                post{
                    _id
                    likes
                    retweets
                    description
                    createdAt
                    usersLiked
                    imageUrl
                    userRetweets
                    hashtags
                    comments{
                        postId
                        text
                        timestamp
                        replyTo
                    }
                    mentions{
                        id
                        pseudo
                    }
                }
        
                user{
                    id
                    nom
                    prenom
                    pseudo
                    imageUrl
                    nitroBadge
                    mediumBadge
                    biography
                    verified
                }
                t_C{
                    id
                    nom
                    prenom
                    pseudo
                    imageUrl
                    verified
                    nitroBadge
                    mediumBadge        
                }
            }
        }   
    `
    const variables = {
        trendId: tr,
    }
    const data = await graphQLClient.request(query, variables);
    return(data);
}

export async function PostNewMessage(Message_Data){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi + '/post/', Message_Data, config).then(resp => {
                return(resp);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }
}


export async function DeleteMessage(Message_Id){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.delete(restApi + '/post/' + Message_Id, config ).then(resp => {
                  return(resp.data);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }
}

export async function LikeMessage(Message_Id, userLikingId){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi + '/post/' + Message_Id, {
            userId: userLikingId
        }, config  ).then(resp => {
                  return(resp.status);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }
}

export async function RetweetMessage(Message_Id, userRetweetingId){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi  + '/post/retweet/' + Message_Id, {
            userId: userRetweetingId
        }, config).then(resp => {
                  return(resp.status);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }   
}

export async function CommentPost(Message_Id, commentBody){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post( restApi+ '/post/comment/' + Message_Id, commentBody, config ).then(resp => {
                  return(resp);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }   
}

 
export async function LoadComment_(PostIndex, PostId){
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })
    
    const queryA = `  
    query($postId: ID!){
        getPostComments(id: $postId){
                comment{
                    _id
                    text
                    timestamp
                    likes
                    commenterId
                }
                user{
                    imageUrl
                    pseudo
                    nom
                    prenom
                }
            
        }
    }
    `
    const variables = {
        postId: PostId,
    }

    const data = await graphQLClient.request(
        queryA,
        variables
      );
    
    const reduxComments = data.getPostComments;
    if (PostIndex != - 1){
        store.dispatch(setPostComments([PostIndex, reduxComments]));
    }
    //dispatch(setPostComments([PostIndex,reduxComments]));
    return(data);
}



export async function RemoveComment(CommentId, PostId){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi + '/post/deleteComment/' + PostId, {commentId : CommentId}, config ).then(resp => {
            return(resp.status);
        }).catch(err => {
            return(err);
        });
    return(resp);
    }catch(err){
        return(err);
    }
}

export async function LikeComment(CommentInfo, PostId){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi + '/post/likeComment/' + PostId ,CommentInfo, config ).then(resp => {
            return(resp.status);
        }).catch(err => {
            return(err);
        });
    return(resp);
    }catch(err){
        return(err);
    }
}





export async function UserPostsGql(QueryUserId) {
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })

    const query = gql`  
    query($userId: ID!){
        userForumPosts(id: $userId){
                post{
                    _id
                    likes
                    retweets
                    description
                    createdAt
                    usersLiked
                    imageUrl
                    hashtags
                    userRetweets
                    comments{
                        postId
                        text
                        timestamp
                        replyTo
                    }
                    mentions{
                        id
                        pseudo
                    }
                }
                user{
                    id
                    nom
                    prenom
                    pseudo
                    imageUrl
                    verified
                    nitroBadge
                    mediumBadge
                }
    
        }   
    }
    `
    const variables = {
        userId: QueryUserId,
    }

    const data = await graphQLClient.request(
        query,
        variables
      );
    return(data);
}

export async function UserAlternativePostsGql(QueryUserId) {
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })
    const query = gql`  
    query($userId: ID!){
        userAlternativePosts(id: $userId){
                post{
                    _id
                    likes
                    retweets
                    description
                    createdAt
                    usersLiked
                    imageUrl
                    hashtags
                    userRetweets
                    comments{
                        postId
                    }
                    mentions{
                        id
                        pseudo
                    }
                }
                user{
                    id
                    nom
                    prenom
                    pseudo
                    verified
                    imageUrl
                    nitroBadge
                    mediumBadge
                }
    
        }   
    }
    `
    const variables = {
        userId: QueryUserId,
    }

    const data = await graphQLClient.request(
        query,
        variables
      );
    
    const filteredData = (data.userAlternativePosts).filter(data => data != null);
    data.userAlternativePosts = filteredData;
    return(data);
}




export async function TrendsGQL() {
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })

    const query = gql`  
    {
      forumTrends{
            trendName
            trendCount
        }  
    }
    `

    const data = await graphQLClient.request(query);
    PersonnalTrendsGQL();
    return(data);
}

export async function PersonnalTrendsGQL() {
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })
    const query = gql`  
    query($userId: ID!){
        personalTrends(id : $userId){
            trendName
            trendCount
            }
        }   
    `
    const variables = {
        userId: cookies.get('userId'),
    }

    const data = await graphQLClient.request(query, variables);
    store.dispatch(setPersonnalTrends(data.personalTrends));
    return(data);
}



export async function SpecificPost(Post_Id){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.get( restApi + '/post/' + Post_Id,config).then(resp => {
                  return(resp.data);
              }).catch(err => {
                  return(err);
              });
          return(resp);
      }catch(err){
          return (err);
      }   
}


 
export async function SpecificPostComments(PostId){
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })

    const queryA = `  
    query($postId: ID!){
        specificPostComments(id: $postId){
            comments{
                comment{
                    _id
                    text
                    timestamp
                    likes
                    replyTo
                    commenterId
                }
                user{
                    imageUrl
                    pseudo
                    nom
                    prenom
                    mediumBadge
                    nitroBadge
                    verified
                }
            }
        }
    }
    `
    const variables = {
        postId: PostId,
    }

    const data = await graphQLClient.request(
        queryA,
        variables
      );
    
    const reduxComments = data.getPostComments;
    return(data);
}



export async function FollowingsPosts(usrId) {
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })

    const query = gql`  
    query($userId: ID!){
            followingsPosts(id: $userId){
                post{
                    _id
                    likes
                    retweets
                    description
                    createdAt
                    usersLiked
                    imageUrl
                    userRetweets
                    hashtags
                    comments{
                        postId
                        text
                        timestamp
                        replyTo
                    }
                    mentions{
                        id
                        pseudo
                    }
                }
        
                user{
                    id
                    nom
                    prenom
                    pseudo
                    imageUrl
                    verified
                    biography
                    nitroBadge
                    mediumBadge
                }
                t_C{
                    id
                    nom
                    prenom
                    pseudo
                    imageUrl
                    verified
                    nitroBadge
                    mediumBadge        
                }
            }
        } 
    `
    const variables = {
        userId: usrId,
    }

    const data = await graphQLClient.request(
        query,
        variables
      );
    

    return(data);
}



export async function ReportPost(PostId){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi + '/post/report/' + PostId ,{}, config ).then(resp => {
            return(resp.status);
        }).catch(err => {
            return(err);
        });
    return(resp);
    }catch(err){
        return(err);
    }
}


export async function BookmarkPost(PostId, USERid){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.post(restApi + '/post/bookmark/' + PostId ,{
            userId : USERid
        }, config ).then(resp => {
            return(resp);
        }).catch(err => {
            return(err);
        });
    return(resp);
    }catch(err){
        return(err);
    }
}


export async function getBookmarkedPosts() {
    const graphQLClient = new GraphQLClient(graphQLendpoint, {
        headers: {
            authorization: `${cookies.get("jwt")}`,
            authentification: `${cookies.get("userId")}`
        }
      })
    const query = gql`  
    query($userId: ID!){
            getUserBookmarks (id: $userId){
                post{
                    _id
                    likes
                    retweets
                    description
                    createdAt
                    usersLiked
                    imageUrl
                    userRetweets
                    hashtags
                    comments{
                        postId
                    }
                    mentions{
                        id
                        pseudo
                    }
                }
        
                user{
                    id
                    nom
                    prenom
                    pseudo
                    imageUrl
                    verified
                    nitroBadge
                    mediumBadge
                }
        
            }
        }   
    `
    const variables = {
        userId: cookies.get("userId"),
    }

    const data = await graphQLClient.request(
        query,
        variables
      );
    
    return(data);
}



export async function SuggsWindowFetch(data){
    try{
        config = {  headers: {authorization: `${cookies.get("jwt")}`, authentification: `${cookies.get("userId")}`}};
        const resp =  await axios.get(restApi + '/post/suggsWindow/'+  data.isFollowings + '/' + data.trendId, config ).then(resp => {
            return(resp);
        }).catch(err => {
            return(err);
        });
    return(resp);
    }catch(err){
        return(err);
    }
}

