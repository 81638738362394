import {useEffect, useState} from 'react'
import '../styles/components/suggsWindow.scss';
import cookies from 'js-cookie';
import {useDispatch, useSelector} from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {SuggsWindowFetch} from '../services/PostServices';
import { faCircleCheck, faBolt, faLock } from '@fortawesome/free-solid-svg-icons'
import { faSpeakerDeck } from '@fortawesome/free-brands-svg-icons'
import {setProfileId} from '../feature/profileSlice';
import {setNavigation} from "../feature/navigationSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper';
import {showAll} from "../feature/navigationSlice";
import {FollowUser, getOwnFollowings} from '../services/UserServices';
import {updateProfileFollows, setRelationsProfiles} from '../feature/profileSlice';
import {joinChannel} from '../services/ChannelServices';

function MemberSuggsCard({isMember,user}){
    const follows = useSelector((state) => state.profile.profileFollows);
    const dispatch = useDispatch();
    const [followed, setFollowed] = useState(false);
    const [delayed, setDelayed] = useState(false);

    const [loaded, setLoaded] = useState(true);

    useEffect(() => {
        if (isMember){
            if(follows?.length > 0 ){
                if (follows?.includes(user?._id) ){
                    setFollowed(true);
                }
            }else{
                setLoaded(false);
                (async ( )=> {
                    const updatedFollows = await getOwnFollowings({userId: cookies.get('userId'), jwt: cookies.get('jwt')});
                    if (updatedFollows.data.ids.followings_Ids?.includes(user?._id) ){
                        setFollowed(true);
                    }
                    dispatch(updateProfileFollows(updatedFollows.data.ids.followings_Ids));
                    dispatch(setRelationsProfiles(updatedFollows.data.relationsData));
                   
                })();
                setLoaded(true);
            }
        }else{
            const indx = user?.usersIds?.findIndex(us => us?.userId === cookies.get("userId"));
            if(indx !== - 1){setFollowed(true);}
        }
    }, []);

    const FollowOrJoin = async () => {
        if (delayed === true){return 0;}
        setDelayed(true);
        const r = isMember ? await FollowUser(user._id) : await joinChannel(user._id);
        if(!isMember && r.status === undefined){setDelayed(false); return 0;}

        if(r.status === 200){setFollowed(true);}else{
            setFollowed(false);
        }
        setDelayed(false);
      };
    return(<>
        {user && <>
            {isMember ? <>

                <div className="userRow  animate__animated animate__fadeInDown"  >
                    <div className="top">
                        <div onClick={() => {dispatch(setNavigation('#userProfile'));dispatch(setProfileId(user._id));}}>
                            <img src={user.imageUrl} alt="" />
                            <div className="txts">
                                <h1>{user.prenom} {user.nom}           
                                    {user.mediumBadge && <> <FontAwesomeIcon style={{color: "gold"}} icon={faBolt}  /> </>} 
                                    {user.nitroBadge && <> <FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}  /> </>} 
                                    {user.verified == true && <> <FontAwesomeIcon style={{color: "#1da1f2"}}  icon={faCircleCheck}  />  </>}
                                </h1>
                                <h2>@{user.pseudo}</h2>
                                <p>{user.biography}</p>
                            </div>
                        </div>

                        <div>  
                            {loaded && <>
                                <button onClick={() => (FollowOrJoin())} className={followed && 'followed'}> {followed ? <>Followed </>: <>Follow</>  }</button>
                            </>}        
                        </div>
                    </div>
                </div>
            
            </> : <>

                <div className="userRow chnRow animate__animated animate__fadeInDown" >
                    <div className="top">
                        <div onClick={() => {dispatch(setNavigation('#liveChat'));}} >
                            <img className='chanPct' src={user.channelPicture} alt="" />
                            <div className="txts">
                                <h1>{user.channelName}</h1>
                                <h2 className='chNe'>{user.private ? <>Private</> : <>Public</>} Channel</h2>
                                <p className='chndc'>{user.channelDescription}</p>
                            </div>
                        </div>

                        <div>  
                            <button onClick={() => (FollowOrJoin())} className={followed && 'followed'}> {followed ? <>Joined </>: <> {user.private ? <>Private <FontAwesomeIcon icon={faLock}/> </> : <>Join</>}</>  }</button>
                        </div>
                    </div>
                </div>
            </>}
        </>}
    </>)
}


function SuggsWindow(isFollowings) {
    const trendFilter = useSelector((state) => state.trends.filterTrend);
    const follows = useSelector((state) => state.profile.profileFollows);
    const showAllbool = useSelector((state) => state.navigation.showMmbrsThread);

    const [loaded, setLoaded] = useState(false);
    const [failed, setFailed] = useState(false);
    const dispatch = useDispatch();

    const [userData, setUserData] = useState([]);
    const [channelsData, setChannelsData] = useState([]);

    useEffect(( )=> {
        setLoaded(false);
        (async () => {
            setUserData([]); setChannelsData([]);
            const d = await SuggsWindowFetch({
                isFollowings : isFollowings.isFollowings ? "1" : "0",
                trendId : trendFilter ? trendFilter.split('#')[1] : "0" 
            });
            if(d.status != 200){setFailed(true);setLoaded(true);return 0;}
            if(d.data?.channels){setChannelsData(d.data?.channels);
                const shfld = d.data.users.sort((a, b) => 0.5 - Math.random());
                setUserData(shfld);
            }else{
                const shuffled = d.data.sort((a, b) => 0.5 - Math.random());
                setUserData(shuffled);
            }
            setLoaded(true);
        })();
    }, [trendFilter, isFollowings.isFollowings]);

    return (<>
        {loaded ? <>
            {(!failed && userData?.length > 0 && !showAllbool) && <>
                <div className="suggs_window_p">
                    <Swiper spaceBetween={40}
                            slidesPerView={1}
                            direction={"vertical"}
                            pagination={{clickable: true,}}
                            autoplay={{delay: 4000, disableOnInteraction: false}}
                            modules={[Navigation, Pagination, Scrollbar,Autoplay]}
                    >
                        <SwiperSlide>
                            <h1 className="ttl animate__animated animate__bounceInLeft" > {trendFilter.length > 1 ?
                            <>Trend Community <span> {trendFilter} </span> </> :
                            <>{isFollowings.isFollowings ? <>Followings Thread</> : <> Community Members</>}</>}
                            </h1>

                            {userData?.slice(0,3).map(user => (<>
                                    {/* <div className="userRow  animate__animated animate__fadeInDown" onClick={() => {dispatch(setNavigation('#userProfile'));dispatch(setProfileId(user._id));}}>
                                        <div className="top">
                                            <div>
                                                <img src={user.imageUrl} alt="" />
                                                <div className="txts">
                                                    <h1>{user.prenom} {user.nom}           
                                                        {user.mediumBadge && <> <FontAwesomeIcon style={{color: "gold"}} icon={faBolt}  /> </>} 
                                                        {user.nitroBadge && <> <FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}  /> </>} 
                                                        {user.verified == true && <> <FontAwesomeIcon style={{color: "#1da1f2"}}  icon={faCircleCheck}  />  </>}
                                                    </h1>
                                                    <h2>@{user.pseudo}</h2>
                                                    <p>{user.biography}</p>
                                                </div>
                                            </div>

                                            <div>  
                                                {follows?.length > 0 ? <>
                                                    <button className={follows?.includes(user?._id) && 'followed'}> {follows?.includes(user?._id) ? <>Followed </>: <>Follow</>  }</button>
                                                </> : <>
                                                    <button>Follow</button>
                                                </>}                                        
                                            </div>
                                        </div>
                                    </div> */}
                                    <MemberSuggsCard isMember={true} user={user}/>
                            </>))}

                            <h5 className="shwm  animate__animated animate__bounce" onClick={() => (dispatch(showAll(true)) )}>Show All</h5>
                        </SwiperSlide>
                        
                        <SwiperSlide>
                            <h1 className="ttl animate__animated animate__bounceInLeft" > Community <span> Channels</span> </h1>

                                {channelsData?.slice(0,3).map(chan => (<>
                                        <MemberSuggsCard isMember={false} user={chan}/>
                                        {/* <div className="userRow chnRow animate__animated animate__fadeInDown" onClick={() => {dispatch(setNavigation('#liveChat'));}}>
                                            <div className="top">
                                                <div>
                                                    <img src={chan.channelPicture} alt="" />
                                                    <div className="txts">
                                                        <h1>{chan.channelName}</h1>
                                                        <h2 className='chNe'>{chan.private ? <>Private</> : <>Public</>} Channel</h2>
                                                        <p className='chndc'>{chan.channelDescription} {chan.channelDescription} {chan.channelDescription}s</p>
                                                    </div>
                                                </div>

                                                <div>  
                                                    {channelsData  && <>
                                                        <button>Join <FontAwesomeIcon icon={faRightToBracket}/> </button>
                                                    </>}                                        
                                                </div>
                                            </div>
                                        </div> */}
                                </>))}
                                <h5 className="shwm  animate__animated animate__bounce">Discover Channels</h5>
                        </SwiperSlide>
                    </Swiper>
                </div>  
            </>}
        </>: <>
            <div className="lds-dual-ring wdwRing"></div>
        </>}
    </>)
}

export default SuggsWindow
