import {useState, useEffect} from 'react'
import '../styles/components/messageRelation.scss';
import {relationUserInfo} from '../services/UserServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHeart, faRetweet} from '@fortawesome/free-solid-svg-icons'
import {useDispatch,useSelector} from "react-redux";
import {setNavigation} from "../feature/navigationSlice";
import {setProfileId, setRelationsProfiles} from '../feature/profileSlice';
import cookies from 'js-cookie';
import Fade from 'react-reveal/Fade';

function MessageRelation({IntercationLists, Post_User_Id}) {
    const followingsState = useSelector((state) => state.profile.profileFollows);
    const relationsProfiles = useSelector((state) => state.profile.relationsProfiles);

    const [likeRelation, incrLikeRelation] = useState(0);
    const [retweetRelation, incrRetweetRelation] = useState(0);

    const [likeRender, setLikeRender] = useState([]);
    const [retweetRender, setRetweetRender] = useState([]);

    const dispatch = useDispatch();

    const SwitchToProfilePage = async (ProfileId) => {
        dispatch(setNavigation('#userProfile'));
        dispatch(setProfileId(ProfileId));
    };

    useEffect(() => {
        (async () => {
            var L = []; var R = [];

            if(IntercationLists?.length < 1 || !Post_User_Id){return 0;}

            if(IntercationLists[0]?.length > 0 && followingsState?.length > 0){
                await Promise.all(IntercationLists[0].map(async id => {
                    if (followingsState?.includes(id) &&  (id !== cookies.get('userId')) && (Post_User_Id !== id) ){
                        let usr = relationsProfiles.find(prf => prf._id === id);
                        L.push({
                            name : usr.prenom +" "+ usr.nom ,
                            pfp : usr.imageUrl,
                            id : usr._id
                        })
                    }
                }));
            }
            if(IntercationLists[1]?.length > 0 && followingsState?.length > 0){
                await Promise.all(IntercationLists[1].map(async id => {
                    if (followingsState?.includes(id) && IntercationLists[0].includes(id) === false &&  (id !== cookies.get('userId'))  && (Post_User_Id !== id) ){ 
                        let usr = relationsProfiles.find(prf => prf._id === id);
                        R.push({
                            name : usr.prenom+ " "+ usr.nom ,
                            pfp : usr.imageUrl,
                            id : usr._id
                        })
                    }
                }));
            }
            L.sort(() => Math.random() - 0.5);
            R.sort(() => Math.random() - 0.5);     
            setLikeRender(L);
            setRetweetRender(R);
        })();
    }, [IntercationLists, Post_User_Id]);
    return (
    <>
        {( likeRender?.length > 0 )&& <>
            <div className='msgRelation'>

                {likeRender.length > 0 && <>
                    <div className='relCard animate__animated animate__fadeInUp'>
                        {likeRender.length === 1 ? <>
                            <img src={likeRender[0]?.pfp} alt="" />
                            <p> <small onClick={() =>   SwitchToProfilePage(likeRender[0]?.id) }> {likeRender[0]?.name}</small> <span>liked </span> this post</p>
                        </>: <>
                            <img src={likeRender[0]?.pfp} alt="" />
                            <p> <small  onClick={() =>   SwitchToProfilePage(likeRender[0]?.id) }>{likeRender[0]?.name} </small><span>liked </span>  and {likeRender.length - 1} other{likeRender.length -1 > 1 && <>s</>}</p>

                            {likeRender?.map((photo, index) => (<>
                                { (index > 0 && index < 7) && <>
                                        <img key={index} onClick={() =>   SwitchToProfilePage(likeRender[index]?.id) }  className='mapImg' src={photo?.pfp} alt="" />
                                    </>}
                            </>))}

                        </>}
                    </div>
                </>}

                {retweetRender.length > 0 && <>
                    <div className='relCard'>
                            {retweetRender.length === 1 ? <>
                                <img src={retweetRender[0]?.pfp} alt="" />
                                <p> <small  onClick={() =>   SwitchToProfilePage(retweetRender[0]?.id) }>{retweetRender[0]?.name} </small><span>retweeted <FontAwesomeIcon icon={faRetweet}  className="rt"/>  </span> </p> 
                            </>: <>
                                <img src={retweetRender[0]?.pfp} alt="" />
                                <p><small  onClick={() =>   SwitchToProfilePage(retweetRender[0]?.id) }> {retweetRender[0]?.name} </small> <span>retweeted <FontAwesomeIcon icon={faRetweet}  className="rt"/> </span>  & {retweetRender.length -1} other{retweetRender.length -1 > 1 && <>s</>}</p> 
                                {retweetRender?.map((photo, index) => (
                                <>
                                    { (index > 0 && index < 7) && <>
                                        <img onClick={() =>   SwitchToProfilePage(retweetRender[index]?.id) } className='mapImg' src={photo?.pfp} alt="" />
                                    </>}
                                </>
                            ))}
                            </>}     
                    </div>
                </>}

            </div>
        </>}
    </>
    )
}

export default MessageRelation
