import {useEffect, useState, useRef} from 'react'
import '../styles/components/channelModal.scss'
import {createNewChannel} from '../services/ChannelServices';
import {setChannelModal, updateChannels} from '../feature/channelSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLaptopCode, faLeftLong, faUpload, faCirclePlus} from '@fortawesome/free-solid-svg-icons'
import { useSelector, useDispatch} from "react-redux";
import Fade from 'react-reveal/Fade';
import cookies from 'js-cookie';

function ChannelModal({username, channelCount}) {
    const dispatch = useDispatch();
    const navigationState = useSelector((state) => state.navigation.navigation);
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState('');
    const inputFile = useRef(null);
    const [channelName, setChannelName] = useState("");

    useEffect(() => {
    }, [navigationState]);

    const handlePicture = (pic)=> {
        setFileName(pic.target.files[0].name);
        setFile(pic.target.files[0]);
    }

    const CreateNewServer = async () => {
        var APIFormMessage = null;
        if (file){
            APIFormMessage = new FormData();
            APIFormMessage.append('image', file, localStorage.getItem("userId") + "_channelPic_" + file.name);
            APIFormMessage.append('userId', cookies.get('userId').toString());
            APIFormMessage.append('channelName', channelName);
        }else{
            APIFormMessage = {
                userId:  cookies.get('userId').toString(),
                channelName: channelName,
            }
        }
  
        if (channelName.length >= 3){
            await createNewChannel(APIFormMessage).then(resp => {
                if (resp === 200){
                    setFile();
                    setFileName('');
                    dispatch(updateChannels());
                }
            });
        }
    }

    return (
        <Fade bottom>
        <div className='modal_bg'>
            <div className="modal_center">
                <div className="top">
                    <h1>Personnalize your channel</h1>
                    <h2>Give some personnality to your server by choosing his name and his picture. You will still be able to modify them later.</h2>
                    
                    <div className="uploadBtn" onClick={() => (inputFile.current.click())}>
                        <h4 className='icon'><FontAwesomeIcon icon={faUpload}/></h4>
                        <h4 className={fileName.length > 7 && "file"}>{fileName.length > 0 ? <>{fileName}</> : <>Upload</>}</h4>
                        <h5><FontAwesomeIcon icon={faCirclePlus}/> </h5>
                        <input type="file" name="messagePicture" accept="image/png, image/jpeg" title='' id='uploadimg' onChange={e => handlePicture(e)} ref={inputFile}/>
                    </div>

                    <p>Name of the server {channelName.length > 0 && <> {channelName.length} / 24</>}</p>
                    <input  type="text" defaultValue={"Channel of " + username.prenom + " "+ username.nom} maxLength={24} onChange= {e => {setChannelName(e.target.value)}}/>

                    <h3>By creating this server, you accept the <span>Nitrochat's Community terms of use.</span> </h3>
                </div>
      
                <div className="bottom">
                    <button className='return'  onClick={()=> (dispatch(setChannelModal(false)))} >  <FontAwesomeIcon icon={faLeftLong}/>  Return</button>
                    <button  className='create' onClick={() => (CreateNewServer())}>Create {channelCount} / 10 <FontAwesomeIcon icon={faLaptopCode}/>  </button>
                </div>
            </div>
        </div>
        </Fade>
    )
}

export default ChannelModal
