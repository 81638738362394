import {useEffect, useState, useRef, onKeyPress} from 'react'
import io from 'socket.io-client';
import '../styles/pages/messages.scss'
import { useSelector, useDispatch} from "react-redux";
import cookies from 'js-cookie';
import {setNavigation, updateBlockedUsers} from "../feature/navigationSlice";
import { setMessages, updateMainMessage, pushNewMessage, updateUserConnections, pushUserConnection, seeCurrentMessages, updateReports, updateLastMessage } from '../feature/messageSlice';
import { userDms, specificDM, sendDm, reportUser,blockUser, pinConversation} from '../services/MessageServices';
import Fade from 'react-reveal/Fade';
import {relationUserInfo, UserWritingMessages} from '../services/UserServices';
import {dateParser} from '../services/utils';
import {setProfileId} from '../feature/profileSlice';
import { faFontAwesome, faArrowUpRightFromSquare,faUser, faHandHoldingHeart,faBan,faPaperclip,faCheckDouble, faCircleCheck,faLinkSlash,faMedal,
    faMessage,faPaperPlane, faMagnifyingGlass, faEarthAmericas, faLaptopCode, faPenToSquare, faThumbTack, faCode, faSatelliteDish, faBolt} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import moment from 'moment'
import {notify} from '../pages/Nitrochat';
import { faSpeakerDeck } from '@fortawesome/free-brands-svg-icons'

const dmSocket = io(process.env.REACT_APP_API_SOCKET_DMS, {
    withCredentials: true,
    autoConnect: false,
    transports : ['websocket']
}); // the dm namespace

const UserCard = ({eachMsg}) => {
    const connectedUsers = useSelector((state) => state.messages.connectedUsers);
    const messagesState = useSelector((state) => state.messages.messages);
    const selectedMessage = useSelector((state) => state.messages.actualMessages);
    const [isSelected, setIsSelected] = useState(false);
    const [lastSwitch, setLastSwitch] = useState('');
    const [lastMsg, setLastMsg] = useState('');
    const dispatch = useDispatch();

    useEffect(() =>{
        if(selectedMessage.length > 0){setLastMsg(selectedMessage?.messages[selectedMessage?.messages?.length - 1]?.content);}
    }, [selectedMessage.messages]); 
     
    useEffect(() =>{
        setIsSelected(false);setLastSwitch(selectedMessage._id); 
        if (selectedMessage?._id === eachMsg.lastMessage[0]){
            setIsSelected(true);
        }
        if (lastSwitch === eachMsg.lastMessage[0]){
            dispatch(updateLastMessage([lastMsg, lastSwitch]));
        }
        
    }, [selectedMessage._id]);

    return (<>
        <div className="le">
            <div className="imglft">
                {eachMsg.lastMessage[2]?.unseenMessages > 0 ? <>
                    <img className={"connected_pfp nsn"} src={eachMsg.user.imageUrl} alt="" />
                    {connectedUsers?.includes(eachMsg.user._id)&& (<>
                        <div className="dot connected"></div>
                    </>)}
                </> : <>
                    <img className={connectedUsers?.includes(eachMsg.user._id) === true && "connected_pfp"} src={eachMsg.user.imageUrl} alt="" />
                    {connectedUsers?.includes(eachMsg.user._id)&& (<>
                        <div className="dot connected"></div>
                    </>)}
                </>}
          
            </div>
            <div className="texts">
                <h1> {eachMsg.user.prenom} {eachMsg.user.nom}
                 {eachMsg.user.mediumBadge && <> <FontAwesomeIcon style={{color: "gold"}} icon={faBolt}/> </>}
                 {eachMsg.user.nitroBadge && <> <FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}/> </>}
                 {eachMsg.user.verified && <> <FontAwesomeIcon style={{color: "#1da1f2"}} icon={faCircleCheck}/> </>}

                 </h1>
                <h2 className={eachMsg.lastMessage[2]?.unseenMessages > 0 && "unsnTxt"}>
                    {eachMsg.lastMessage[1]?.content ? <>
                        {isSelected ? <>{selectedMessage.messages[selectedMessage.messages.length - 1]?.content}</> : <>{eachMsg.lastMessage[1]?.content}</>}
                    </>:<>
                        <span>< FontAwesomeIcon icon={faCode}/></span>
                    </>}
                </h2>
            </div>
        </div>
        
        <div className="right_infos">
            <h2 className='date' >{eachMsg.lastMessage[1]?.timestamp  && <>{dateParser(new Date(eachMsg.lastMessage[1]?.timestamp ), 9)}</>}</h2>
            {(eachMsg.lastMessage[2]?.seen === false && !isSelected) ? 
                <h1>{eachMsg.lastMessage[2]?.unseenMessages }</h1> :
                <>{ (selectedMessage?._id === eachMsg.lastMessage[0]) ? <h4>< FontAwesomeIcon icon={faCheckDouble}/></h4> : <h3>.</h3>} 
            </>}
        </div>
     </>);
};
  
function UserMessages() {
    const [sendMessage, setSendMessage] = useState('');
    const [searchBar, setSearchBar] = useState('');
    const dispatch = useDispatch();
    const messagesState = useSelector((state) => state.messages.messages);
    const navigationState = useSelector((state) => state.navigation);
    const selectedMessage = useSelector((state) => state.messages.actualMessages);
    const connectedUsers = useSelector((state) => state.messages.connectedUsers);

    const blockUsrs = useSelector((state) => state.navigation.blockedUsers);

    const [userIndx, setUserIndx] = useState(0);
    const bottomRef = useRef(null);

    const [userData, setUserData] = useState({});
    const [loaded, setLoaded] = useState(false);

    const [unseenCount, setUnseenCount] = useState(0);
    useEffect(() => {
        // 👇️ scroll to bottom every time messages change
        bottomRef.current?.scrollIntoView({behavior: 'smooth'});
      }, [selectedMessage?.messages]);

    const fetchMsg = async () => {
        const resp = await UserWritingMessages()
        setUserData(resp.getUser);
        const e = await userDms(cookies.get("userId"));
        dispatch(setMessages(e));
        const a = e.findIndex(message => message.lastMessage[0] === selectedMessage._id); setUserIndx(a);
        setLoaded(true);
        let cnt = 0;
        for (let i = 0; i < e.length; i++){
            if (e[i].lastMessage.length > 1){
                //console.log(e[i].lastMessage[2].seen);
                if (!e[i].lastMessage[2].seen){
                    cnt ++;
                }
            }
        }
        setUnseenCount(cnt);
    }
    
    useEffect(() => {
        fetchMsg();

        dmSocket.connect();
        dmSocket.off("privateMessage").on("privateMessage", ({content, from}) => {
            if (from !== cookies.get("userId")){
                dispatch(pushNewMessage({
                    userId: from,
                    content : content,
                    timestamp: Date.now()
                }));
                dispatch(seeCurrentMessages());
            }
        });

    }, []);



    const ownFollowings = useSelector((state) => state.profile.profileFollows);
    const [relationsArr, setRelationsArr] = useState("");
    const [logDatesMessages, setLogDatesMessages] = useState([]);

    useEffect( () => {
        (async () => {
            if (messagesState[userIndx]?.user && ownFollowings.length > 0){
                var pushar = [];
                await Promise.all(messagesState[userIndx]?.user?.followers_Ids.map(async (userId, index) => {
                    if (ownFollowings.includes(userId)){
                        const o = await relationUserInfo(userId);
                        pushar.push(o.getUser);
                    }
                }));
                setRelationsArr(pushar);
            }
        })();
    }, [userIndx]);

    useEffect(() => {
        var dayChange = null;
        const newLogs = [];
        selectedMessage?.messages?.map((eachMsg, index) => {
            const diff = moment().diff(moment(eachMsg.timestamp), 'days');
            if (dayChange !== diff || diff === null){/// Day Switch
                dayChange = diff;
                var date = moment(eachMsg.timestamp).format('LL');
                if (diff === 0){date = "Today"}
                newLogs.push(date);
            }else{
                newLogs.push('');
            }
        })
        setLogDatesMessages(newLogs);
    }, [userIndx, selectedMessage]);

    const switchMessage = async (dmId, usrInx) => {
        setEditThreads(false);
        if(selectedMessage._id !== dmId){
            const r = await specificDM(dmId);
            setUserIndx(usrInx);
            dispatch(updateMainMessage(r));
        }
    }
    const [blockInteraction, setBlockInteraction] = useState(false);

    const delayInteraction = async (ms) => {
        setBlockInteraction(true); 
        await new Promise(  resolve => setTimeout(resolve, ms));
        setBlockInteraction(false); 
    };
      
    const reportUserFunc = async (userId) => {
        if (userId && !blockInteraction){
            delayInteraction(1000);
            const r = await reportUser(userId);
            dispatch(updateReports({"index": userIndx, "newReports" : r.data}))
            if (r.status === 202){ notify("Report Cancelled");
            }else{  notify("User Reported !"); }
        }
    }
    const blockUserFunc = async (userId) => {
        if (userId && !blockInteraction){
            delayInteraction(1000);
            const r = await blockUser(userId);
            dispatch(updateBlockedUsers(r.data));
            if (r.status === 201){notify("User Blocked !" + " @"+ messagesState[userIndx]?.user.pseudo);
            }else{  notify("User Unblocked" + " @"+ messagesState[userIndx]?.user.pseudo);}
        }
    }
    const pinConv = async (convId) => {
        if(convId){
            const o = await pinConversation(convId);
            const updatedData = {... userData};
            if (o.status === 201){
                updatedData.pinnedConversations = o.data
                setUserData(updatedData); setEditThreads(false);
            }
        }
    }
    
    const sendMsg = async (dmId, msgContnt) => {
        if (selectedMessage?._id?.length === 0){return 0;}
        if (msgContnt.length > 0 && !blockUsrs?.includes(messagesState[userIndx]?.user._id) ){
            dmSocket.emit("privateMessage", {
                content: msgContnt,
                toChannel: messagesState[userIndx].user._id,
                from: cookies.get("userId")
            });
            setSendMessage("");
            dispatch(pushNewMessage({ userId: cookies.get("userId"), content : msgContnt,  timestamp: Date.now() }));
            document.getElementById('send').value = '';
            await sendDm(dmId, {
                senderId: cookies.get("userId"),
                content: msgContnt,
            });
        }
    } 

    const SwitchToMessagePage = async (ProfileId) => {
        dispatch(setNavigation('#userProfile'));
        dispatch(setProfileId(ProfileId));
    };

    const [nmbr ,setNmbr] = useState(1);
    useEffect(() => {
        var newNmbr = 0;
        messagesState?.map((eachMsg) => {
            if ( eachMsg?.user.nom.toLowerCase().includes(searchBar.toLowerCase()) || eachMsg?.user.prenom.toLowerCase().includes(searchBar.toLowerCase())  ||  eachMsg?.user.pseudo.toLowerCase().includes(searchBar.toLowerCase())){
                newNmbr += 1;
            }
        });
        setNmbr(newNmbr)
        if (messagesState.length === 0){setNmbr(1)}
    }, [searchBar]);

    const [editThreads, setEditThreads] = useState(false);

    return (
        <div className='dms_div'>
            <div className="left_msg_main">
                {Object.keys(userData).length > 0 && <>
                    <Fade top >
                        <div className="left_top" onClick={( )=> (SwitchToMessagePage(cookies.get("userId")))}>
                                <img src={userData.imageUrl}  alt="Profile Picture" />
                                <div>
                                    <h1>{userData.prenom} {userData.nom} 
                                    {userData.mediumBadge && <> <FontAwesomeIcon style={{color: "gold"}} icon={faBolt}/> </>}
                                    {userData.nitroBadge && <> <FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}/> </>}
                                    {userData.verified && <> <FontAwesomeIcon style={{color: "#1da1f2"}} icon={faCircleCheck}/> </>}
                                    </h1>
                                    <h2>My Account</h2>
                                </div>
                        </div>
                    </Fade>
                </>}
                {!loaded ? <>
                    <div className="lds-dual-ring"></div>
                </> : <>  
                    <div className="title">
                        <h1>Messages {unseenCount > 0 && <><span>{unseenCount}</span></>}</h1>
                        <p className={editThreads && 'editing'} onClick={() => (setEditThreads(!editThreads))}>< FontAwesomeIcon icon={faPenToSquare}/></p>
                    </div>
                    <div className={searchBar?.length > 0 ? "srch notempty" : "srch"}>
                        <p>< FontAwesomeIcon icon={faMagnifyingGlass}/></p>
                        <input maxLength={30} type="text" id="search_bar"placeholder="Search in your dms..." onChange={e => setSearchBar(e.target.value)}/>
                    </div>

                    <div className='msgthread' id='msgthread'>
                        <h2 className='subtit'>< FontAwesomeIcon icon={faSatelliteDish} style={{marginRight: "5px", fontSize: '14px'}}/>  ONLINE</h2>
                        <div className="onlinerow">
                            <div className='usr connectedUsr'>
                                <img className="connected_pfp" src={userData.imageUrl}  alt="Profile Picture" />
                                <h1 className='connectedText'> {userData.prenom} {userData.verified&& <> <FontAwesomeIcon style={{color: "#1da1f2"}} icon={faCircleCheck}/></>} <br/> {userData.nom}</h1>
                                <div className="dot connected"/>
                            </div>
                            {messagesState?.map((eachMsg, index) => ( <>
                                {connectedUsers?.includes(eachMsg.user._id) && <>
                                    <div className={connectedUsers?.includes(eachMsg.user._id) ? 'usr connectedUsr' : 'usr'}>
                                        <img className={connectedUsers?.includes(eachMsg.user._id) === true && "connected_pfp"} src={eachMsg.user.imageUrl} alt="Profile Picture" />
                                        {connectedUsers?.includes(eachMsg.user._id)? (<> <div className="dot connected"/> </>): (<><div className="dot"/></>)}
                                        <h1 className={connectedUsers?.includes(eachMsg.user._id) === true && "connectedText"} > 
                                            {eachMsg.user.prenom} 
                                            {eachMsg.user.verified && <> <FontAwesomeIcon style={{color: "#1da1f2"}} icon={faCircleCheck}/> </>}
                                            <br/> {eachMsg.user.nom}
                                        </h1>
                                    </div>
                                </>}
                            </>))}
                            {messagesState?.map((eachMsg, index) => ( <>
                                {!connectedUsers?.includes(eachMsg.user._id) && <>
                                    <div className={connectedUsers?.includes(eachMsg.user._id) ? 'usr connectedUsr' : 'usr'}>
                                        <img className={connectedUsers?.includes(eachMsg.user._id) === true && "connected_pfp"} src={eachMsg.user.imageUrl} alt="Profile Picture" />
                                        <h1 className={connectedUsers?.includes(eachMsg.user._id) === true && "connectedText"} > 
                                            {eachMsg.user.prenom} 
                                            {eachMsg.user.verified && <> <FontAwesomeIcon style={{color: "#1da1f2"}} icon={faCircleCheck}/> </>}
                                            <br/> {eachMsg.user.nom}
                                        </h1>
                                    </div>
                                </>}
                            </>))}
                        </div>
                    
                        {userData?.pinnedConversations?.length > 0 && <>
                            <h2 className='subtit pin_'>< FontAwesomeIcon icon={faThumbTack} style={{marginRight: "5px", fontSize: '13px'}} /> PINNED</h2>
                            <div className='pindd' id='pinned'>
                              {messagesState?.map((eachMsg, index) => ( <>
                                {userData?.pinnedConversations?.includes(eachMsg?.lastMessage[1]?._id) && <>
                                  {eachMsg?.user.nom.toLowerCase().includes(searchBar.toLowerCase()) ||  eachMsg?.user.prenom.toLowerCase().includes(searchBar.toLowerCase())  || eachMsg?.user.pseudo.toLowerCase().includes(searchBar.toLowerCase()) ? 
                                        (<> 
                                        <Fade>
                                            <div className={selectedMessage._id === eachMsg.lastMessage[0] ?  "msger_card selected_msg" : "msger_card"}  onClick={() => {switchMessage(eachMsg.lastMessage[0],index)}}>
                                                <UserCard eachMsg={eachMsg}/>
                                            </div>
                                        </Fade>
                                            {editThreads && <>
                                                <Fade> <div className="underRow "> <span onClick={() => (pinConv(eachMsg.lastMessage[1]._id))} className='pn detc'>
                                                    <FontAwesomeIcon icon={faLinkSlash}/> Detach</span>
                                                </div></Fade> 
                                            </>}
                                        </>) : (<></>)}
                                    </>}</>
                                ))}
                            </div>
                        </>}

                        <h2 className='subtit def_'>< FontAwesomeIcon icon={faMessage} style={{marginRight: "5px", fontSize: '12px'}}/> ALL MESSAGES</h2>
                        <div className='pindd'  id='all'>
                            {messagesState?.map((eachMsg, index) => (<>
                             {!userData?.pinnedConversations?.includes(eachMsg?.lastMessage[1]?._id) && <>
                                {eachMsg?.user.nom.toLowerCase().includes(searchBar.toLowerCase()) ||  eachMsg?.user.prenom.toLowerCase().includes(searchBar.toLowerCase())  || eachMsg?.user.pseudo.toLowerCase().includes(searchBar.toLowerCase()) ? 
                                        (<> 
                                        <Fade>
                                            <div className={selectedMessage._id === eachMsg.lastMessage[0] ?  "msger_card selected_msg" : "msger_card"}  onClick={() => {switchMessage(eachMsg.lastMessage[0],index)}}>
                                                <UserCard eachMsg={eachMsg}/>
                                            </div>
                                        </Fade>
                                            {(editThreads && eachMsg.lastMessage[1])  && <>
                                              <Fade> <div className="underRow"> <span onClick={() => (pinConv(eachMsg.lastMessage[1]._id))} className='pn'>
                                                <FontAwesomeIcon icon={faThumbTack}/> Pin Conversation</span>
                                                </div></Fade> 
                                            </>}
                                        </>) : (<> </>)}
                                </>}</>
                            ))}
                        </div>
                        {nmbr === 0 && <>
                            <div className="noresult">
                                <h1>NO RESULTS FOR "{searchBar}"</h1>
                                <p>No results were found for the term you've choosen</p>
                                <Link to={`/forum/explore`} >
                                    <h5 onClick={() => (dispatch(setNavigation('#explore'))) }>Discover new users <FontAwesomeIcon className='discvr' icon={faEarthAmericas}/></h5>
                                </Link>
                            </div>
                        </>} 
                    </div>
                </>}
            </div>
            

            <div className="right_msg_main">
                <>
                {!loaded ? <>
                    <div className="lds-dual-ring"></div>
                </> : <>
                    {messagesState.length > 0 ? <>
                        <div className="top">
                            <div className="infodiv">
                                    { (messagesState.length > 0 && selectedMessage._id) && <>
                                        <div className="info_column rww">
                                                <img src={messagesState[userIndx]?.user.imageUrl} alt="" />
                                            <div>
                                                <h2>{messagesState[userIndx]?.user.prenom} {messagesState[userIndx]?.user.nom}
                                                {messagesState[userIndx]?.user.mediumBadge &&  <> <FontAwesomeIcon style={{color: "gold"}} icon={faBolt}  /> </>}
                                                {messagesState[userIndx]?.user.nitroBadge &&  <> <FontAwesomeIcon style={{color: "cyan"}} icon={faSpeakerDeck}  /> </>}
                                                 {messagesState[userIndx]?.user.verified && <> <FontAwesomeIcon style={{color: "#1da1f2"}} icon={faCircleCheck}/> </>}
                                                </h2>
                                                <h3>@{messagesState[userIndx]?.user.pseudo}</h3>
                                            </div>
                                        </div>
                                        <div className="right_btns">
                                            <p  onClick={() =>SwitchToMessagePage(messagesState[userIndx]?.user._id) }><FontAwesomeIcon icon={faArrowUpRightFromSquare}/></p>
                                            <p className={messagesState[userIndx]?.user.reports.includes(cookies.get('userId')) && "rprted" } onClick={() => reportUserFunc(messagesState[userIndx]?.user._id) }><FontAwesomeIcon icon={faFontAwesome}/></p>
                                            <p className={blockUsrs?.includes(messagesState[userIndx]?.user._id) && "blocked" }  onClick={() => blockUserFunc(messagesState[userIndx]?.user._id) }><FontAwesomeIcon icon={faBan}/></p>
                                        </div>    
                                    </>}
                            </div>

                            <div className='msgs'>
                                {selectedMessage._id && <>
                                    <div className="cards_row">
                                        <div className="card">
                                            <h2>Follows</h2>
                                            <div>
                                                <h5><span>{messagesState[userIndx]?.user.followers}</span> Followers < FontAwesomeIcon icon={faUser}/> </h5> 
                                                <h5><span>{messagesState[userIndx]?.user.followings}</span> Followings < FontAwesomeIcon icon={faHandHoldingHeart}/>  </h5>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <h2>Nitrochat Activity</h2>
                                            <div>
                                                <h4><span> {messagesState[userIndx]?.user.postsCount} </span> Nitrochats <FontAwesomeIcon icon={faLaptopCode}/> </h4>
                                                <h4>Member Since <span> {dateParser(new Date(messagesState[userIndx]?.user.createdAt), 4)} </span></h4>
                                            </div> 
                                        </div>
                                        
                                    </div>
                                </>}

                                {relationsArr.length > 2 && <>
                                    <Fade>
                                    <div className="relation">
                                        <div> {relationsArr?.map((data, index) => (<>
                                            {index < 6 && <>
                                                <img className='relPic' src={data.imageUrl} alt="" />
                                            </>} </>))}
                                        </div>
                                        <h2> <span>{relationsArr[0].prenom} {relationsArr[0].nom} </span> and  <span>{relationsArr.length - 1}</span> more are following</h2>
                                    </div>
                                    </Fade>
                                </>}
                                {selectedMessage._id && <>
                                    <h6>Your Private Conversation with <span> {messagesState[userIndx]?.user.prenom} {messagesState[userIndx]?.user.nom} </span> <br/> Starts Here !</h6>
                                </>}
                                <>
                                {selectedMessage?.messages?.map((eachMsg, index) => (
                                        <>
                                            {logDatesMessages[index]?.length > 0 && <><h6 className={logDatesMessages[index] === "Today" ? 'dateInf tday' : 'dateInf'}>{logDatesMessages[index]}</h6></>}

                                            <div className={eachMsg.userId == cookies.get("userId") ? "right_msg" : "left_msg"}>
                                                {eachMsg.userId !== cookies.get("userId") && <><img src={messagesState[userIndx]?.user.imageUrl} alt="" /></>}
                                                <h1>{eachMsg.content}</h1>
                                                {eachMsg.userId === cookies.get("userId") && <><img src={userData.imageUrl}  alt="Profile Picture" /></>}
                                            </div>

                                            <div className={eachMsg.userId == cookies.get("userId") ? "right_date" : "left_date"}>
                                                {(selectedMessage?.messages[index + 1]?.userId !== eachMsg?.userId) && (<>
                                                    <p>{dateParser(new Date(eachMsg.timestamp), 5)}</p>
                                                </>)}
                                                
                                                {(logDatesMessages[index + 1]?.length > 0) && ((selectedMessage?.messages[index + 1]?.userId !== eachMsg?.userId) === false) && <>
                                                    <p>{dateParser(new Date(eachMsg.timestamp), 5)}</p>
                                                </>}
                                            </div>
                                        </>
                                ))}
                                </>
                                {blockUsrs?.includes(messagesState[userIndx]?.user._id) && 
                                <> <Fade bottom>
                                    <h1 className='blcked'>YOU HAVE BLOCKED THIS USER</h1> 
                                </Fade></>}
                                <div ref={bottomRef}/>
                            </div>

                            <div className={blockUsrs?.includes(messagesState[userIndx]?.user._id) ? "sendMessage forbdn" : "sendMessage"}>
                                <div className={sendMessage?.length > 0 ? 'sendBar notEmpty' : 'sendBar'}>
                                {blockUsrs?.includes(messagesState[userIndx]?.user._id) ? <>
                                    <input maxLength={100} value={messagesState[userIndx]?.user.prenom + " " + messagesState[userIndx]?.user.nom +" " + "is Blocked." }/>
                                </> : <>
                                    <input id='send' maxLength={100} onKeyDown={event => {if (event.key === 'Enter') {sendMsg(selectedMessage._id, sendMessage);}}}  onChange={(e) => (setSendMessage(e.target.value))} placeholder="Start new message..."/>
                                </>}
                                    <button className='clp'>  <FontAwesomeIcon icon={faPaperclip}/></button>
                                    <button className={sendMessage?.length > 0 && 'sendMs'}   onClick={() => (sendMsg(selectedMessage._id, sendMessage) ) }>  <FontAwesomeIcon icon={faPaperPlane}/></button>
                                </div>
                            </div>

                        </div>
                
                    </> : <>
                        <div className="noBookmarks">
                            <img src={require('../media/messages.png')} alt="" />
                            <h2>You don't have any conversation started yet</h2>
                            <h3>Maybe try go find some other users on Nitrochat !</h3>
                            <Link to={`/forum/explore`} >
                                <h4 onClick={() => (dispatch(setNavigation('#explore'))) }>Discover Users </h4>
                            </Link>
                        </div>

                    </>}
                </>}
            </>
            </div> 

        </div>
    )
}

export default UserMessages
