import { createSlice, current } from "@reduxjs/toolkit";
import { useSelector} from "react-redux";
import store from "../app/store";
import cookies from 'js-cookie';

export const messageSlice = createSlice({
    name: "messages",
    initialState: {
        messages: [],
        actualMessages : [],
        connectedUsers : []
    },
    reducers: {
        setMessages: (state, {payload}) => {
            state.messages = payload;
        },
        pushNewMessage: (state, {payload}) => {
            state.actualMessages.messages.push(payload);
        },
        updateMainMessage: (state, {payload}) => {
            const el = (current(state.messages).find(el => el?.lastMessage[0] === payload._id));
            const indx = (current(state.messages).findIndex(el => el?.lastMessage[0] === payload._id));
            if( el && el.lastMessage.length > 1){
                state.messages[indx].lastMessage[2].seen  = true;
            }
            state.actualMessages = payload;
        },
        updateUserConnections: (state, {payload}) => {
            state.connectedUsers = payload;
        },
        pushUserConnection: (state, {payload}) => {
            state.connectedUsers.push(payload);
        },
        seeCurrentMessages: (state, {payload}) => {
            const indx = (current(state.actualMessages).seenState.findIndex(seen => seen.userId === cookies.get("userId")));
            state.actualMessages.seenState[indx].seen = true;
            state.actualMessages.seenState[indx].unseenMessages = 0;
        },
        updateReports: (state, {payload}) => {
            state.messages[payload.index].user.reports = payload.newReports
        },
        updateLastMessage: (state, {payload}) => {
            const e  = (state.messages.findIndex(msg => msg.lastMessage[0] === payload[1]));
            if (state.messages[e].lastMessage[1]){
                state.messages[e].lastMessage[1].content = payload[0];
            }
        }
    }
})
export const {setMessages, pushNewMessage, updateMainMessage,updateUserConnections, pushUserConnection, seeCurrentMessages, updateReports, updateLastMessage } = messageSlice.actions;
export default messageSlice.reducer;
