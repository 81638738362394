import {configureStore} from '@reduxjs/toolkit';

import postsReducer from "../feature/postsSlice";
import commentsReducer from "../feature/commentSlice";
import navigationReducer from "../feature/navigationSlice";
import profileReducer from "../feature/profileSlice";
import trendSlice from "../feature/trendSlice";
import messageReducer from "../feature/messageSlice";
import channelReducer from "../feature/channelSlice";

const store = configureStore({
    reducer: {
        posts : postsReducer,
        comments: commentsReducer,
        navigation: navigationReducer,
        profile:profileReducer,
        trends: trendSlice,
        messages: messageReducer,
        channel: channelReducer
    },
    devTools : false
});

export default store;