import { createSlice, current } from "@reduxjs/toolkit";
import store from "../app/store";

export const channelSlice = createSlice({
    name: "channels",
    initialState: {
        actualChannelId: "",
        actualChannelMessages: [],
        channelModal: false,
        channelCreationJoin : 0,
        channelRole : {},
        closeFriendsWindow : 0
    },
    reducers: {
        setActualChannelId: (state, {payload}) => {
            state.actualChannelId = payload;
        },
        setChannelMessages: (state, {payload}) => {
            state.actualChannelMessages = payload;
        },
        pushChannelMessage: (state, {payload}) => {
            state.actualChannelMessages.push(payload);
        },
        setChannelModal: (state, {payload}) => {
            state.channelModal = payload;
        },
        updateChannels: (state, {payload}) => {
            state.channelCreationJoin ++;
        },
        setChannelRole: (state, {payload}) => {
            state.channelRole = payload;
        },
        closeFrndWindow: (state, {payload}) => {
            state.closeFriendsWindow ++;
        },
    }

})
export const {setChannelMessages, pushChannelMessage,setActualChannelId, setChannelModal, updateChannels, setChannelRole, closeFrndWindow} = channelSlice.actions;
export default channelSlice.reducer;
